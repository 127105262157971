.benefits-slider {
	width: 100%;
	max-width: 100%;
	overflow: visible;
}

.benefits-slider-buttons {
	.slider-btn {
		margin-top: ac(48px, 24px);

		&.swiper-button-lock {
			display: none;
		}
	}
}
