.benefits-section {
	padding-top: 28px;
	padding-bottom: ac(42px, 30px);

	&__wrap {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
	}

	&__title {
		margin-bottom: ac(48px, 24px);
		text-align: center;
	}
}
