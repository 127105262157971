.opportunities-sections {
    margin-top: ac(-92px, -50px);
    padding-top: ac(258px, 98px);
    /*background: var(--gradient-main);*/
    overflow: visible;
    position: relative;
    z-index: 8;
    min-height: ac(788px, 482px);

    @mixin min-media 1600 {
        padding-top: 270px;
    }

    &:before {
        top: 0;
        left: 0;
        right: 0;
        content: "";
        position: absolute;
        background-image: url("../images/decors/wave-white.svg");
        background-size: 100% 100%;
        height: 24.44vw;
        transform: scale(1.02);

        @mixin min-media 1600 {
            background-image: url("../images/decors/wave-white-full-hd.svg");
        }
    }

    &:after {
        content: "";
        top: 0;
        left: 0;
        right: 0;
        bottom: 200px;
        background: var(--gradient-main);
        position: absolute;
        z-index: -1;
    }

    &__top {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding-bottom: ac(20px, 22px);
        position: relative;
        z-index: 1;

        .title {
            max-width: ac(380px, 300px);
        }

        @mixin mob-xl {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .buttons {
                margin-top: 22px;
                margin-left: auto;
            }
        }
    }

    &__slider {
        position: relative;
        z-index: 2;
        /*margin-bottom: ac(-170px, -200px);*/
    }

    &.join-the-team-page {
        padding-top: ac(330px, 190px);

        &:before {
            background-image: url("../images/decors/wave-gradient.svg");
            background-size: 100% 100%;
            height: 24.44vw;

            @mixin min-media 1600 {
                background-image: url("../images/decors/wave-white-full-hd-gradient.svg");
            }
        }

        .opportunities-sections__top {
            max-width: 1034px;
            margin-left: auto;
            margin-right: auto;
            transform: translateX(-10px);
            align-items: center;
            margin-bottom: ac(38px, 18px);

            @mixin desk-sm {
                max-width: 100%;
                transform: none;
            }
        }

        .jobs-slider .slider-btn {
            margin-top: ac(60px, 28px);
        }
    }

    &.candidate-page {
        padding-top: ac(305px, 190px);
        padding-bottom: ac(1196px, 700px);
        margin-top: 0;
        clip-path: inset(-20px 0 ac(600px, 400px) 0);

        @mixin min-media 1480 {
            padding-top: 330px;
        }

        @mixin min-media 1680 {
            padding-top: 360px;
        }

        @mixin min-media 2200 {
            padding-top: 420px;
        }

        .slider-cont {
            position: relative;
            z-index: 5;
        }

        .big-cont {
            position: relative;
            z-index: 5;
        }

        &:before {
            top: -2px;
            left: 0;
            right: 0;
            content: "";
            position: absolute;
            transform: scale(1.02);
            z-index: 3;

            @mixin min-media 1279 {
                background-image: url("../images/decors/wave-white-jobs-candidate.svg");
                background-size: 100% 100%;
                height: 25.56vw;
            }
        }

        &:after {
            position: absolute;
            content: "";
            z-index: 2;
            inset: 0;
            background: linear-gradient(
                92.46deg,
                rgba(121, 204, 240, 0.7) 2.43%,
                rgba(77, 178, 161, 0.7) 98.48%
            );
            mix-blend-mode: multiply;
        }

        .opportunities-sections__top {
            max-width: 1034px;
            margin-left: auto;
            margin-right: auto;
            transform: translateX(ac(-14px, 0px));
            align-items: center;
            margin-bottom: ac(38px, 18px);
            padding-right: ac(0px, 200px);

            @mixin desk-sm {
                max-width: 100%;
                transform: none;
            }

            @mixin tab {
                padding-right: 0;
            }

            @mixin mob-xl {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
            }

            .buttons {
                transform: translateY(ac(-2px, 0px));
            }
        }

        .title h2 {
            font-weight: 500;
            font-size: ac(40px, 28px);
            line-height: ac(56px, 40px);
        }

        .jobs-slider {
            transform: translateX(ac(-38px, 0px));
            max-width: ac(846px, 640px);

            .slider-buttons {
                transform: translateX(ac(110px, 0px));
            }
        }

        .jobs-slider .slider-btn {
            margin-top: ac(60px, 28px);
        }

        .job-card__title {
            h3 {
                line-height: ac(46px, 36px);
            }

            &:not(:last-child) {
                margin-bottom: ac(20px, 16px);
            }
        }

        .job-card__content {
            padding-left: ac(48px, 44px);
        }

        .job-card__parameters {
            gap: ac(12px, 10px);

            &:not(:last-child) {
                margin-bottom: 12px;
            }
            li {
                margin-right: 0 !important;
            }
        }

        .opportunities-sections__image {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            top: ac(566px, 200px);
            opacity: 0.3;
            box-shadow: 0 -10px 10px 0 rgba(0, 207, 255, 0.1);
            mask-image: linear-gradient(
                to top,
                transparent,
                black 0%,
                black 50%,
                transparent
            );

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                filter: grayscale(100%) brightness(110%);
            }

            &:before {
                content: "";
                position: absolute;
                inset: 0;
                background: linear-gradient(
                    92.46deg,
                    rgba(121, 204, 240, 0.7) 2.43%,
                    rgba(77, 178, 161, 0.7) 98.48%
                );
                z-index: 1;
                mix-blend-mode: color;
            }
        }

        + .cv-news-container {
            margin-top: ac(-1090px, -700px);
        }
    }

    &.job-details-page {
        margin-top: ac(-210px, -60px);
        padding-top: ac(345px, 190px);

        @mixin media 901 {
            margin-top: -170px;
        }

        @mixin media 651 {
            margin-top: -60px;
            padding-top: 130px;
        }

        &::before {
            background-image: url("../images/decors/job-details-vacancies-decor.svg");
        }

        &::after {
            bottom: 0;
        }

        .opportunities-sections__top .title {
            max-width: 480px;
        }

        .opportunities-sections__top {
            align-items: center;

            max-width: 1172px;

            margin-left: auto;
            margin-bottom: 60px;
            padding-bottom: 0;
            padding-right: ac(143px, 0px);
        }

        .opportunities-sections__image {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            opacity: 0.3;
            box-shadow: 0 -10px 10px 0 rgba(0, 207, 255, 0.1);
            mask-image: linear-gradient(
                to top,
                transparent,
                black 0%,
                black 50%,
                transparent
            );

            height: 17.84vw;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                filter: grayscale(100%) brightness(110%);
            }

            &::before {
                content: "";
                position: absolute;
                inset: 0;
                background: linear-gradient(
                    92.46deg,
                    rgba(121, 204, 240, 0.7) 2.43%,
                    rgba(77, 178, 161, 0.7) 98.48%
                );
                z-index: 1;
                mix-blend-mode: color;
            }
        }

        .opportunities-sections__slider {
            overflow: visible;

            padding-bottom: 0;

            .jobs-slider-buttons {
                transform: translate(ac(84px, 0px), 50%);
                margin-top: 32px;
            }

            .jobs-slider .slider-btn {
                margin-top: 0;
                background: var(--dark);
            }
        }
    }
}
