@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
:root {
	--black: #161626;
	--dark: #3c3c3b;
	--blue: #79ccf0;
	--dark-blue: #004191;
	--midnight-blue: #061828;
	--green: #4db2a1;
	--bg-second: #343434;
	--main: #3cc5fb;
	--white: #ffffff;
	--snow: #fafafa;
	--grey: color-mod(var(--white) a(60%));
	--dark-grey: #a3b2b2;
	--asphalt: #828281;
	--primary: #ffde33;
	--secondary: #088ded;
	--transparent: color-mod(var(--white) a(0%));

	--font-main: "Rubik", sans-serif;

	--gradient-main: linear-gradient(135deg, var(--green) 0%, var(--blue) 100%);
	--gradient-blue-green: linear-gradient(
		95deg,
		var(--blue) 0%,
		var(--green) 100%
	);

	--gradient-internal-main: linear-gradient(
		307.29deg,
		#79ccf0 19.92%,
		#4db2a1 105.96%
	);
	--gradient-video-btn: linear-gradient(92.46deg, #79ccf0 2.43%, #4db2a1 98.48%),
		linear-gradient(0deg, #ffffff, #ffffff);

	--plyr-color-main: var(--green);
	--plyr-control-spacing: ac(16px, 14px);

	/* --mobile-menu-start-point and @custom-media --mobile-menu-start-point must be the same */
	--mobile-menu-start-point: 12000;
}

/* --mobile-menu-start-point and @custom-media --mobile-menu-start-point must be the same */
@custom-media --mobile-menu-start-point (max-width: 12000px);

/* --mobile-menu-end-point must be greater than 1 of the  --mobile-menu-start-point and @custom-media --mobile-menu-start-point */
@custom-media --mobile-menu-end-point (min-width: 12001px);
