.latest-news-result {
	overflow: visible;
	padding-bottom: ac(94px, 30px);

	&__filter {
		display: flex;
		justify-content: space-between;
		gap: 20px;

		margin-bottom: ac(48px, 24px);
		padding: 7px 0;

		@mixin media 651 {
			flex-direction: column;
		}
	}

	&__categories {
		overflow: visible;
		clip-path: inset(-2px -20px -2px -400px);
		position: relative;

		.swiper-slide {
			width: fit-content;
		}

		&:before {
			content: "";
			position: absolute;
			z-index: 2;
			top: -2px;
			bottom: -2px;
			right: -22px;
			width: 22px;
			background: linear-gradient(
				90deg,
				var(--transparent) 0%,
				color-mod(var(--white) a(10%)) 10%,
				color-mod(var(--white) a(90%)) 60%,
				var(--white) 100%
			);
			pointer-events: none;
		}
	}

	&__category-item {
		display: block;

		border-radius: 15px;
		overflow: hidden;

		cursor: pointer;
	}

	&__checkbox {
		display: none;
	}

	&__category-text {
		position: relative;

		padding: ac(16px, 14px) ac(24px, 16px);

		color: var(--dark);

		font-size: 18px;
		font-weight: 500;
		line-height: 1;

		transition: setTransition(color);

		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			z-index: -1;

			opacity: 0;

			transition: setTransition(opacity);

			background: var(--gradient-internal-main);
			border-radius: 15px;
			overflow: hidden;
			clip-path: inset(0 0 0 0 round 15px);
		}

		&:hover {
			&:before {
				opacity: 0.5;
			}
		}
	}

	&__checkbox:checked + &__category-text {
		color: var(--white);

		&:before {
			opacity: 1;
		}
	}

	&__sorting {
		display: flex;
		flex-shrink: 0;

		font-size: 16px;
		align-items: center;
		margin-left: auto;

		@mixin media 651 {
			margin-left: auto;
		}
	}

	&__sorting-sortby-text {
		font-weight: 300;
	}

	&__card-list {
		display: grid;
		grid-template-columns: repeat(3, minmax(0, 1fr));
		gap: 40px 20px;

		margin-bottom: 46px;

		@mixin media 901 {
			grid-template-columns: repeat(2, minmax(0, 1fr));
		}

		@mixin media 651 {
			grid-template-columns: minmax(0, 1fr);
		}

		.delimeter {
			grid-column: 1/-1;
			height: 1px;
			width: 83%;
			background: var(--dark-grey);
			margin: auto;

			@mixin media 651 {
				display: none;
			}
		}
	}

	.pagination__item::after {
		background: var(--white);
	}

	&__pagination {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__pagination-page-list {
		display: flex;
	}

	&__pagination-page,
	&__pagination-btn {
		display: flex;
		justify-content: center;
		align-items: center;

		width: 48px;
		height: 48px;
		transition: all 0.25s ease;

		&:hover {
			color: var(--main);
		}
	}

	&__pagination-btn {
		font-size: 20px;
	}

	&__pagination-page {
		position: relative;

		font-size: 16px;
		font-weight: 500;
		color: var(--dark);

		&:before {
			display: none;

			content: "";
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: -1;

			border-radius: 999px;

			background: linear-gradient(316.58deg, #5acdf3 12.17%, #073267 81.12%);
			transition: setTransition(opacity);
		}

		&:after {
			display: none;

			content: "";
			position: absolute;
			top: 2px;
			left: 2px;
			right: 2px;
			bottom: 2px;
			z-index: -1;

			border-radius: 999px;

			background: var(--white);
		}

		&.active-page {
			&:before,
			&:after {
				display: block;
			}
		}
	}
}
