.news-slider {
	width: 100%;
	max-width: 100%;
	overflow: visible;
}

.news-slider-buttons {
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	z-index: 4;
	transform: translateY(ac(28px, 23px));

	.slider-btn {
		margin-top: ac(24px, 14px);
	}
}
