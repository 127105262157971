.team-card {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    padding-left: ac(48px, 20px);
    padding-right: ac(48px, 20px);
    padding-top: ac(40px, 20px);
    padding-bottom: ac(44px, 20px);
    background: var(--white);
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.14);
    border-radius: 20px;
    transition: background-color 0.3s ease;
    height: auto;

    &__top {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: ac(24px, 18px);

        @mixin mob-sm {
            flex-direction: column;

            .team-card__name {
                text-align: center;
            }

            .team-card__photo {
                margin-right: 0;
                width: 100px;
                margin-bottom: 10px;
            }

            .team-card__position {
                text-align: center;
            }

            .team-card__top-content {
                align-items: center;
            }
        }
    }

    &__photo {
        width: ac(150px, 80px);
        margin-right: ac(32px, 16px);
        box-shadow: 0px 0px 16px rgba(121, 204, 240, 0.5);
        padding: 4px;
        border-radius: 50%;
        position: relative;
        z-index: 1;

        &:before {
            content: "";
            position: absolute;
            z-index: -1;
            inset: 0;
            background: linear-gradient(
                308.94deg,
                #79ccf0 14.47%,
                #4db2a1 98.3%
            );
            border-radius: 50%;
            pointer-events: none;
        }

        .image {
            width: 100%;
            @mixin aspect-ratio 1, 1;
            clip-path: inset(0 0 0 0 round 50%);
        }
    }

    &__top-content {
        flex: 1;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
    }

    &__name {
        font-size: ac(28px, 20px);
        font-weight: 400;
        line-height: ac(44px, 32px);
        transition: color 0.3s ease;

        &:not(:last-child) {
            margin-bottom: 4px;
        }
    }

    &__position {
        font-size: ac(16px, 14px);
        font-weight: 500;
        line-height: ac(26px, 22px);
        background: linear-gradient(316.58deg, #5acdf3 12.17%, #073267 81.12%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        transition: all 0.3s ease;

        &:not(:last-child) {
            margin-bottom: ac(17px, 12px);

            + .team-card__position {
                margin-top: ac(-17px, -8px);
            }
        }
    }

    &__text {
        width: 100%;
        @mixin max-line-length 6;

        &:not(:last-child) {
            margin-bottom: ac(16px, 14px);
        }

        & > * {
            transition: color 0.3s ease;
        }

        + .btn {
            margin-top: auto;
        }
    }

    &:hover {
        @mixin min-media 1025 {
            background: var(--dark);

            h1,
            h2,
            h3,
            h4,
            h5,
            h6,
            p,
            li,
            a {
                color: var(--white);
            }

            .team-card__position {
                background: var(--blue);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
            }

            .socials__link {
                i {
                    background: var(--white);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-clip: text;
                    text-fill-color: transparent;
                }

                &:before {
                    background: linear-gradient(
                        308.94deg,
                        #79ccf0 14.47%,
                        #4db2a1 98.3%
                    );
                }
                &:after {
                    background: var(--dark);
                }

                &:hover {
                    i {
                        opacity: 1;
                        background: var(--blue);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        background-clip: text;
                        text-fill-color: transparent;
                    }
                }
            }

            .btn {
                border-color: var(--white);
                color: var(--white);

                &.dark-btn .btn__text:before {
                    background: var(--white);
                }

                &:hover {
                    opacity: 0.7;
                }
            }
        }
    }
}
