.breadcrumbs {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: flex-start;

	li {
		display: flex;
		align-items: center;

		a,
		span {
			font-family: var(--font-main);
			font-size: ac(16px, 14px);
			line-height: 175%;
			color: var(--white);
			font-weight: 500;
		}

		i {
			font-size: ac(16px, 14px);
			color: var(--grey);
			line-height: 1;
			margin: 0 ac(10px, 6px);
			margin-top: 2px;
		}

		span {
			cursor: default;
		}

		a {
			color: var(--grey);
			@mixin transition-all;
			position: relative;

			&::before {
				content: "";
				position: absolute;
				bottom: 0;
				right: 0;
				width: 0;
				height: 2px;
				background: var(--grey);
				@mixin transition-all;
			}

			&:hover {
				color: var(--blue);

				&::before {
					width: 100%;
					left: 0;
					background: var(--blue);
				}
			}
		}
	}
}

.pagination {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: ac(48px, 32px);

	&__list {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	i {
		font-size: ac(20px, 18px);
	}

	&__item {
		font-family: var(--font-main);
		font-size: ac(16px, 12px);
		font-weight: 500;
		line-height: 140%;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		width: ac(48px, 38px);
		height: ac(48px, 38px);
		transition: all 0.25s ease;
		color: var(--midnight-blue);
		position: relative;
		z-index: 1;
		cursor: pointer;

		&:before {
			content: "";
			position: absolute;
			z-index: -2;
			inset: 0;
			background: linear-gradient(316.58deg, #5acdf3 12.17%, #073267 81.12%);
			border-radius: 50%;
			transition: opacity 0.25s ease;
			opacity: 0;
		}

		&:after {
			content: "";
			position: absolute;
			background: var(--snow);
			inset: 2px;
			z-index: -1;
			border-radius: 50%;
			transition: opacity 0.25s ease;
		}

		a,
		span {
			font: inherit;
		}

		&.active {
			border-color: var(--blue);
			cursor: not-allowed !important;

			&:before,
			&:after {
				opacity: 1;
			}
		}

		&:not(.active) {
			&:hover {
				box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
				.pagination__num {
					color: var(--dark-blue);
				}
			}
		}

		&.with-points {
			pointer-events: none;

			&:before,
			&:after {
				display: none;
			}
		}
	}

	&__item-btn {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&__btn {
		display: flex;
		justify-content: center;
		align-items: center;
		width: ac(48px, 38px);
		height: ac(48px, 38px);
		transition: all 0.25s ease;
		cursor: pointer;

		&:hover {
			color: var(--blue);
		}
	}
}
