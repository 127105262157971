.our-team-popup {
    padding: 0;
    max-width: 900px;
    width: 100%;
    max-height: 92vh;
    overflow: hidden;
    background: var(--dark);
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.14);
    border-radius: 20px;

    .popup__title {
        display: none;
        /*font-family: var(--font-main);
		font-size: ac(24px, 20px);
		font-weight: 500;
		line-height: 183.33%;
		color: var(--black);
		padding-right: 84px;
		padding-bottom: ac(24px, 8px);
		position: absolute;
		top: 0;
		left: 46.35%;
		height: ac(92px, 60px);
		width: calc(54.65% - 30px);
		background: var(--white);
		text-transform: none;
		@mixin max-line-length-one;
		z-index: 2;
		display: flex;
		align-items: center;
		padding-top: 16px;

		@mixin media 1400 {
			left: 45.35%;
		}

		@mixin tab-sm {
			left: 30px;
			color: var(--white);
			pointer-events: none;
			width: calc(100% - 60px);
		}*/
    }

    .popup__btn-close {
        top: ac(28px, 8px);
        right: ac(30px, 20px);
        z-index: 10;
        width: ac(22px, 18px);
        height: ac(22px, 18px);
        border-radius: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.25s ease;

        &:before {
            font-family: "icomoon" !important;
            speak: never;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            content: "\e904";
            font-size: ac(22px, 18px);
            color: var(--white);
            transition: all 0.25s ease;
        }

        .cross-clip {
            display: none;
        }

        &:hover {
            &:before {
                color: var(--blue);
            }
        }

        @mixin mob-xl {
            top: 22px;
            right: 22px;
            &:before {
                color: var(--dark);
            }

            &:hover {
                &:before {
                    color: var(--dark-blue);
                }
            }
        }
    }

    .popup__container {
        position: relative;
    }

    .popup__content {
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        position: relative;
        /*&:before {
			position: absolute;
			content: "";
			bottom: 0;
			height: ac(56px, 40px);
			left: 45.35%;
			width: calc(54.65% - 20px);
			background: var(--white);
			z-index: 10;

			@mixin tab-sm {
				left: 30px;
				width: calc(100% - 60px);
			}
		}*/

        @mixin mob-xl {
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
        }
    }

    .our-team-popup {
        &__card {
            width: 38.67%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: ac(48px, 32px) ac(32px, 20px) ac(48px, 32px) ac(30px, 20px);
            background: var(--white);
            height: auto;
            clip-path: url("#team-popup-card-clip-path");
            transform: translateZ(0);

            /*@mixin tab-sm {
				display: none;
			}*/

            @mixin mob-xl {
                padding-bottom: 38px;
                width: 100%;
                clip-path: url("#team-popup-card-clip-path-mob");
            }
        }

        &__card-photo {
            width: ac(180px, 90px);
            height: ac(180px, 90px);
            box-shadow: 0px 0px 16px rgba(121, 204, 240, 0.5);
            padding: 4px;
            border-radius: 50%;
            position: relative;
            z-index: 1;
            margin-bottom: ac(16px, 12px);

            &:before {
                content: "";
                position: absolute;
                z-index: -1;
                inset: 0;
                background: linear-gradient(
                    308.94deg,
                    #79ccf0 14.47%,
                    #4db2a1 98.3%
                );
                border-radius: 50%;
            }

            img {
                width: 100%;
                height: 100%;
                clip-path: inset(0 0 0 0 round 50%);
                z-index: 1;
                object-fit: cover;
            }
        }

        &__card-name {
            font-size: ac(32px, 20px);
            font-weight: 400;
            line-height: ac(48px, 32px);
            transition: color 0.3s ease;

            &:not(:last-child) {
                margin-bottom: 4px;
            }
        }

        &__card-position {
            font-size: ac(18px, 14px);
            font-weight: 500;
            line-height: ac(28px, 22px);
            background: linear-gradient(
                316.58deg,
                #5acdf3 12.17%,
                #073267 81.12%
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
            transition: all 0.3s ease;
            text-align: center;

            &:not(:last-child) {
                margin-bottom: ac(17px, 12px);

                + .our-team-popup__card-position {
                    margin-top: ac(-17px, -8px);
                }
            }
        }

        &__card-socials {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;

            .socials__link {
                margin: 0 !important;
            }

            /*&.with-mob {
				justify-content: flex-start;
				margin-bottom: 20px;

				.social-btn {
					&:first-child {
						margin-left: 0;
					}
				}
			}*/
        }

        &__content-container {
            width: 61.33%;
            background: var(--dark);
            padding-left: ac(32px, 20px);
            padding-top: ac(50px, 56px);
            padding-bottom: ac(48px, 28px);
            padding-right: ac(32px, 20px);
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;

            @mixin mob-xl {
                padding-top: 24px;
                padding-bottom: 32px;
                width: 100%;
            }
        }

        &__content {
            width: 100%;
            padding: 0;
            /*scrollbar-width: thin;
			scrollbar-color: var(--blue) var(--light-grey);*/
            /*max-height: 43.33vh;*/
            max-height: 43.33vh;
            max-height: calc(var(--vh, 1vh) * 43.33);
            /*overflow-y: auto;*/
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            position: relative;
            padding-right: ac(0px, 4px);
            margin-top: auto;

            @mixin min-media 1450 {
                max-height: 398px;
            }

            .simplebar-track.simplebar-vertical {
                width: ac(16px, 12px);
                background: #575756;
                border-radius: 72px;
                transform: translateX(ac(0px, 14px)) translateY(ac(15px, 0px));
                .simplebar-scrollbar {
                    background: var(--dark-grey);
                    border-radius: 80px;
                    left: 2px;
                    right: 2px;
                    /*box-shadow: inset 0 4px 0 0 var(--grey), inset 0 -4px 0 0 var(--grey);*/

                    &:before {
                        content: none;
                    }
                }
            }

            .simplebar-content {
                min-height: 50px;
            }

            @mixin tab-sm {
                width: 100%;
                max-height: 88vh;
            }

            @mixin mob-xl {
                max-height: 35vh;
                max-height: calc(var(--vh, 1vh) * 35);
            }

            /*&::-webkit-scrollbar {
				width: 20px;
			}

			&::-webkit-scrollbar-track {
				background: var(--grey);
				border-radius: 0;
			}

			&::-webkit-scrollbar-thumb {
				background: var(--blue);
				border-radius: 0;
				box-shadow: inset 4px 0 0 0 var(--grey), inset -4px 0 0 0 var(--grey);
			}*/
        }

        &__item {
            max-width: 465px;
            &:not(:last-child) {
                margin-bottom: ac(26px, 20px);
            }

            &.with-mob {
                display: none;
            }

            @mixin tab-sm {
                max-width: none;

                &.with-mob {
                    display: block;
                }
            }

            h2.our-team-popup__title {
                font-size: ac(24px, 20px);
                font-weight: 500;
                line-height: ac(38px, 26px);

                &:not(:last-child) {
                    margin-bottom: ac(12px, 10px);
                }
            }

            h3.our-team-popup__title {
                font-size: ac(22px, 18px);
                font-weight: 500;
                line-height: ac(36px, 24px);

                &:not(:last-child) {
                    margin-bottom: ac(14px, 10px);
                }
            }

            h4.our-team-popup__title {
                font-size: ac(20px, 16px);
                font-weight: 500;
                line-height: ac(32px, 22px);

                &:not(:last-child) {
                    margin-bottom: ac(12px, 10px);
                }
            }
        }

        &__sectors {
            h3.our-team-popup__title {
                font-size: ac(22px, 18px);
                font-weight: 500;
                line-height: ac(36px, 24px);

                &:not(:last-child) {
                    margin-bottom: ac(12px, 10px);
                }
            }

            &:not(:last-child) {
                margin-bottom: ac(24px, 20px);
            }

            ol {
                padding-top: ac(4px, 4px);
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                flex-wrap: wrap;
                gap: ac(12px, 8px);

                li {
                    color: var(--blue);
                    font-weight: 500;
                    font-size: ac(16px, 14px);
                    line-height: ac(24px, 22px);
                    padding-left: 12px;
                    padding-right: 12px;
                    padding-top: 2px;
                    padding-bottom: 2px;
                    border: 2px solid #575756;
                    border-radius: 1000px;
                }
            }
        }

        &__buttons {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-wrap: wrap;
            grid-column-gap: ac(32px, 16px);
            grid-row-gap: ac(16px, 10px);
            margin-top: auto;
        }

        &__title-mob {
            font-family: var(--font-main);
            font-size: ac(24px, 20px);
            font-weight: 500;
            line-height: 183.33%;
            color: var(--black);
        }
    }

    @mixin tab {
        width: 95%;
    }

    @mixin tab-sm {
    }

    @mixin mob-xl {
        max-height: auto;
    }
}

/*#popup.active {
	.our-team-popup__card {
		animation-duration: 0.5s;
		animation-name: transformCard;

		@mixin tab-sm {
			animation: none;
		}
	}
}

@keyframes transformCard {
	0% {
		transform: translateX(100%);
		opacity: 0.5;
	}

	100% {
		transform: translateX(0);
		opacity: 1;
	}
}*/
