/*
Styles for popup background and basic styles for fonts
*/
.termsfeed-com---palette-dark.termsfeed-com---nb {
    background: var(--gradient-main) !important;
    color: var(--white);
    font-family: var(--font-main);
    border-radius: 20px 0 0 0;

    .cc-nb-okagree,
    .cc-nb-reject,
    .cc-nb-changep {
        margin-right: 13px !important;
        margin-bottom: 10px !important;
        margin-top: 5px !important;
        text-transform: capitalize;
    }

    .cc-nb-title,
    .cc-nb-text {
        color: var(--white);
    }

    #cc-nb-text {
        font-weight: 400;
    }
    #cc-nb-title {
        color: var(--white);
        font-family: var(--font-main);
    }
}

.termsfeed-com---nb {
    /*
    Styles for buttons
    */
    .cc-nb-okagree,
    .cc-nb-reject,
    .cc-nb-changep,
    .cc-cp-foot-button .cc-cp-foot-save {
        background: transparent;
        padding: 2px 2px 2px 2px;
        position: relative;
        overflow: visible;
        border: none;
        border-radius: 0;
        text-align: center;
        width: fit-content;
        z-index: 2;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: var(--white);
        transition: opacity 0.25s ease;
        font-family: var(--font-main);
        font-size: ac(22px, 18px);
        line-height: 181.82%;
        font-weight: 500;

        &:focus {
            box-shadow: none !important;
            border: none !important;
        }

        &:after {
            font-family: "icomoon" !important;
            speak: never;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            position: relative;
            content: "\e900";
            color: var(--white);
            /*top: 50%;
            transform: translateY(-50%) translateX(-50%) scale(0.8);
            r: 0;*/
            display: flex;
            margin-left: ac(11px, 8px);
            font-size: ac(11px, 10px);
            transition: transform 0.25s ease;
        }

        &:before {
            position: absolute;
            content: "";
            bottom: ac(-2px, 0px);
            left: 0;
            width: calc(100% - ac(14px, 12px) - ac(30px, 28px));
            background: var(--white);
            height: 2px;
            transition: width 0.3s ease, transform 0.3s ease;
        }

        &:hover {
            opacity: 0.7;

            &:after {
                transform: translateX(6px);
            }

            &:before {
                width: 100%;
            }
        }
    }

    .cc-nb-buttons-container {
        display: flex !important;
        flex-wrap: wrap;
        grid-column-gap: 18px;
        grid-row-gap: 0;
    }
}

/*
Styles for popup with settings
*/
.termsfeed-com---palette-dark {
    .cc-pc-container {
        font-family: var(--font-main) !important;
        clip-path: inset(0 0 0 0 round ac(20px, 10px));
    }

    .cc-pc-head,
    .cc-cp-body-content,
    .cc-cp-foot {
        background: var(--dark) !important;
        border-top-color: var(--green) !important;
        border-bottom-color: var(--green) !important;
    }

    .cc-cp-body-tabs {
        font-family: var(--font-main) !important;
    }

    .cc-cp-body-tabs {
        background-color: var(--snow);
    }

    .cc-cp-body-tabs-item {
        background: var(--snow);
    }

    .cc-cp-body-tabs-item-link {
        color: var(--dark);

        &:focus {
            box-shadow: none;
        }
    }

    .cc-cp-body-tabs-item[active="true"] {
        background: var(--gradient-main) !important;

        .cc-cp-body-tabs-item-link {
            color: var(--dark);
        }
    }

    .cc-pc-head-lang {
        select {
            color: var(--dark);
            padding: 5px;
            background: var(--snow);
            cursor: pointer;

            &:focus {
                box-shadow: none;
            }

            option {
                font-size: ac(18px, 16px);
                font-weight: 400;
                line-height: ac(32px, 28px);
                font-style: normal;
                font-family: var(--font-main);
                cursor: pointer;
            }
        }
    }

    .cc-pc-head-close {
        color: var(--green);
        transition: all 0.3s ease;

        &:hover {
            color: var(--white);
            transform: scale(1.1);
        }

        &:focus {
            border: 0;
        }
    }
}

/*
Styles for the lower part of the popup with settings
*/
.termsfeed-com---pc-dialog {
    .cc-cp-body-tabs {
        font-family: var(--font-main) !important;
    }

    .cc-cp-foot {
        padding: 10px 0;
        flex-wrap: wrap;
    }

    .cc-cp-foot-byline {
        a {
            color: var(--green);
            text-decoration: underline;
            transition: all 0.3s ease;

            &:hover {
                color: var(--blue);
            }
        }
    }

    .cc-cp-foot-button .cc-cp-foot-save {
        background: transparent;
        padding: 5px 2px 5px 2px;
        position: relative;
        overflow: visible;
        border: none;
        border-radius: 0;
        text-align: center;
        width: fit-content;
        z-index: 2;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: var(--white);
        transition: opacity 0.25s ease;
        font-family: var(--font-main);
        font-size: ac(22px, 18px);
        line-height: 181.82%;
        font-weight: 500;
        margin-left: 10px;

        &:focus {
            box-shadow: none !important;
            border: none !important;
        }

        &:after {
            font-family: "icomoon" !important;
            speak: never;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            position: relative;
            content: "\e900";
            color: var(--white);
            /*top: 50%;
            transform: translateY(-50%) translateX(-50%) scale(0.8);
            r: 0;*/
            display: flex;
            margin-left: ac(11px, 8px);
            font-size: ac(11px, 10px);
            transition: transform 0.25s ease;
        }

        &:before {
            position: absolute;
            content: "";
            bottom: 2px;
            left: 0;
            width: calc(100% - ac(14px, 12px) - ac(30px, 28px));
            background: var(--white);
            height: 2px;
            transition: width 0.3s ease, transform 0.3s ease;
        }

        &:hover {
            opacity: 0.7;

            &:after {
                transform: translateX(6px);
            }

            &:before {
                width: 100%;
            }
        }

        @mixin mob-lg {
            margin-bottom: 10px;
        }
    }
}

/*
Styles for mobile adaptation
*/
@mixin mob-lg {
    .termsfeed-com---nb-simple {
        max-width: 90%;
    }

    .termsfeed-com---nb .cc-nb-okagree,
    .termsfeed-com---nb .cc-nb-reject,
    .termsfeed-com---nb .cc-nb-changep {
        margin-top: 0 !important;
        max-width: 100%;
    }

    .termsfeed-com---nb .cc-nb-main-container {
        padding: 2rem;
    }
}

@mixin mob {
    .termsfeed-com---nb .cc-nb-okagree,
    .termsfeed-com---nb .cc-nb-reject,
    .termsfeed-com---nb .cc-nb-changep {
        min-width: auto;
        text-align: center !important;
        justify-content: center;
        font-size: 16px;
    }
}

@mixin media 380 {
    .termsfeed-com---nb-simple {
        max-width: 100%;
    }
}
