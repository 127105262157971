.benefits-card {
	padding: ac(40px, 20px) ac(32px, 18px) ac(48px, 20px) ac(40px, 20px);
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	height: auto;
	background: var(--white);
	box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.14);
	border-radius: 20px;

	&__top {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		margin-bottom: 16px;
	}

	&__icon {
		@mixin aspect-ratio 1, 1;
		width: ac(75px, 60px);
	}

	&__title {
		flex: 1;
		margin-left: ac(16px, 8px);
		font-size: ac(22px, 18px);
		font-weight: 500;
		line-height: ac(36px, 24px);
	}

	&__text {
		width: 100%;
		max-height: calc(ac(28px, 20px) * 8.01);

		.simplebar-track.simplebar-vertical {
			width: 2px;
			background: transparent;
			border-radius: 2px;
			transform: translateX(6px);

			.simplebar-scrollbar {
				background: var(--blue);
				border-radius: 2px;

				&:before {
					content: none;
				}
			}
		}
	}
}
