h1,
h2,
h3,
h4,
h5,
h6,
p,
li,
a,
body {
    color: var(--dark);
    font-family: var(--font-main);
}

.dark-bg {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    li,
    a {
        color: var(--white);
    }
}

h1 {
    font-size: ac(90px, 42px);
    font-weight: 500;
    line-height: ac(107px, 56px);
}

h2 {
    font-weight: 500;
    font-size: ac(56px, 32px);
    line-height: ac(72px, 44px);

    &.small-section-title {
        font-weight: 500;
        font-size: ac(40px, 28px);
        line-height: ac(56px, 40px);
    }
}

h3 {
    font-size: ac(32px, 24px);
    font-weight: 500;
    line-height: ac(40px, 36px);
}

h4 {
    font-size: ac(20px, 16px);
    font-weight: 500;
    line-height: ac(32px, 24px);
}

h5 {
    font-size: ac(28px, 20px);
    font-weight: 500;
}

h6 {
    font-size: ac(24px, 18px);
}

p {
    font-size: ac(18px, 16px);
    font-weight: 300;
    line-height: ac(32px, 24px);

    a {
        font: inherit;
    }
}

li {
    font-size: ac(18px, 16px);
    font-weight: 300;
    line-height: ac(32px, 24px);

    a {
        font: inherit;
    }
}

label {
    font-size: ac(22px, 18px);
    line-height: ac(34px, 28px);
    font-weight: 400;
}

a {
    /*font-size: ac(16px, 14px);
	font-weight: 500;
	color: var(--black);*/
    display: inline-block;
}

.title {
    h1 {
        font-size: ac(90px, 42px);
        font-weight: 500;
        line-height: ac(107px, 56px);
    }

    h2 {
        font-weight: 500;
        font-size: ac(56px, 32px);
        line-height: ac(72px, 44px);
    }
}

.small-title {
    h2 {
        font-weight: 500;
        font-size: ac(40px, 28px);
        line-height: ac(50px, 40px);
    }
}

.small-subtitle {
    h2 {
        font-weight: 500;
        font-size: ac(40px, 24px);
        line-height: ac(50px, 40px);
    }
}

.subtitle {
    h2 {
        font-size: ac(28px, 20px);
        font-weight: 400;
        line-height: ac(44px, 28px);
    }

    p {
        font-size: ac(22px, 18px);
        line-height: ac(34px, 28px);
        font-weight: 400;
    }
}

.content-element {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    li {
        a {
            text-decoration: underline;
            transition: all 0.25s ease;

            &:hover {
                color: var(--blue);
            }
        }

        img {
            width: 100% !important;
            height: auto !important;
            object-fit: contain;
        }
    }

    h3 {
        font-size: ac(22px, 18px);
        font-weight: 500;
        line-height: ac(36px, 24px);

        &:not(:last-child) {
            margin-bottom: ac(16px, 14px);
        }
    }

    p {
        &:not(:last-child) {
            margin-bottom: ac(16px, 14px);
        }
    }

    &:not(.checked-list-type) {
        ul {
            list-style: none;
            li {
                position: relative;
                padding-left: ac(24px, 16px);

                &:before {
                    content: "";
                    position: absolute;
                    left: ac(6px, 0px);
                    top: calc(ac(32px, 28px) / 2);
                    transform: translateY(-55%);
                    border-radius: 50%;
                    width: 6px;
                    height: 6px;
                    background: var(--dark);
                }

                /*&:not(:last-child) {
                  margin-bottom: 5px;
                }*/
            }
        }
    }

    ol {
        list-style-type: none;
        counter-reset: num;

        ul {
            counter-reset: num;
        }

        li {
            padding-left: ac(24px, 16px);
            position: relative;

            &:before {
                position: absolute;
                color: var(--black);
                top: 0;
                left: ac(6px, 0px);
                font: inherit;
                content: counter(num) ".";
                counter-increment: num;
                transform: translateX(-4px);
                /*letter-spacing: -0.5px;*/
            }

            /*&:not(:last-child) {
              margin-bottom: 5px;
            }*/
        }

        ol {
            counter-reset: num2;

            li {
                padding-left: ac(36px, 22px);

                &:before {
                    transform: translateX(ac(-4px, -7px));
                }
            }

            ol {
                counter-reset: none;
            }

            ul {
                counter-reset: num2;
            }
        }

        ol li:before {
            content: counter(num) "." counter(num2);
            counter-increment: num2;
        }
    }

    &.small-content-element {
        p {
            font-size: ac(16px, 14px);
            font-weight: 300;
            line-height: ac(28px, 20px);
        }

        li {
            font-size: ac(16px, 14px);
            font-weight: 300;
            line-height: ac(28px, 20px);
        }
    }

    &.centered-type {
        text-align: center;

        & > * {
            text-align: center;
        }

        ul,
        ol {
            li {
                text-align: center;
            }
        }
    }

    &.article {
        p,
        ul,
        ol,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            &:not(:last-child) {
                margin-bottom: ac(21px, 12px);
            }
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            &:not(:first-child) {
                margin-top: ac(40px, 20px);
            }
        }

        h2 {
            font-size: ac(40px, 30px);
            font-weight: 500;
            line-height: 1.4;
        }

        h3 {
            font-size: ac(22px, 18px);
            font-weight: 500;
            line-height: 1.64;
        }

        p {
            font-size: ac(16px, 14px);
            line-height: 1.75;

            strong {
                font: inherit;
                font-weight: 500;
            }
        }

        li {
            strong {
                font: inherit;
                font-weight: 500;
            }
        }

        &:not(.checked-list-type) {
            li {
                font-size: ac(16px, 14px);
                line-height: 1.75;
            }
        }

        strong {
            color: var(--dark-blue);
            font-size: ac(20px, 18px);
            font-weight: 500;
            line-height: 1.6;
        }

        hr {
            background: var(--dark-grey);

            &:not(:first-child) {
                margin-top: ac(40px, 20px);
            }

            &:not(:last-child) {
                margin-bottom: ac(40px, 20px);
            }
        }

        &.checked-list-type {
            ul {
                li {
                    position: relative;

                    font-size: 16px;
                    padding-left: 40px;

                    font-weight: 500;

                    &::before {
                        content: "\e908";
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);

                        width: 32px;
                        height: 32px;

                        font-family: "icomoon" !important;
                        font-size: 16px;
                        text-align: center;
                    }
                }
            }

            ol {
                li {
                    font-size: 16px;
                    font-weight: 500;
                }
            }
        }
    }

    a {
        word-wrap: break-word;
        overflow-wrap: break-word;
        max-width: 100%;
    }
}

.blockquote-content {
    p,
    li {
        font-weight: 500;
        font-size: ac(22px, 16px);
        line-height: ac(36px, 24px);

        a {
            text-decoration: underline;
        }
    }

    p,
    li {
        a {
            font-weight: 600;
        }
    }
}
