.first-section {
    padding-top: ac(300px, 128px);
    padding-bottom: ac(198px, 134px);
    background: var(--dark);
    position: relative;
    overflow: visible;
    z-index: 10;

    .decor {
        @mixin aspect-ratio 1440, 1143;
        overflow: visible;
        position: absolute;
        left: 50%;
        top: ac(-330px, -200px);
        transform: translateX(-53%) rotate(7deg);
        width: 134%;

        img {
            object-fit: fill;

            &.with-mob {
                display: none;
            }
        }

        @mixin min-media 1550 {
            transform: translateX(-56%) rotate(7deg);
        }

        @mixin min-media 1620 {
            top: -460px;
            transform: translateX(-56%) rotate(10deg);
            max-width: 2280px;
        }

        @mixin tab-sm {
            top: -471px;
            width: 800px;
            @mixin aspect-ratio 800, 871;
            position: absolute;
            transform: none;
            left: auto;
            right: -365px;

            img {
                &.with-desk {
                    display: none;
                }

                &.with-mob {
                    display: block;
                }
            }
        }
    }

    &__wrap {
        width: 100%;
        position: relative;
        z-index: 2;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }

    &__title-content {
        max-width: ac(888px, 400px);
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        .title {
            padding-bottom: ac(30px, 34px);
            text-align: center;

            @mixin mob-sm {
                h1 {
                    font-size: 36px;
                }
            }
        }

        &:not(:last-child) {
            padding-bottom: ac(160px, 80px);
        }

        .buttons {
            @mixin tab-md {
                .btn {
                    margin: 0 !important;
                }
                gap: 18px;
                flex-wrap: wrap;
            }
        }

        @mixin mob-lg {
            margin-left: 0;
        }
    }

    &__subtitle-content {
        max-width: ac(697px, 380px);
        width: 100%;
        margin-left: auto;
        margin-right: ac(-90px, 0px);

        &:not(:last-child) {
            padding-bottom: ac(20px, 18px);
        }

        @mixin mob-lg {
            margin-left: 0;
            margin-right: auto;
        }
    }

    &__text-content {
        max-width: ac(697px, 380px);
        width: 100%;
        margin-left: auto;
        margin-right: ac(-90px, 0px);

        .content-element {
            padding-bottom: ac(10px, 14px);
        }

        &:not(:last-child) {
            padding-bottom: ac(198px, 55px);
        }

        @mixin mob-lg {
            margin-left: 0;
            margin-right: auto;
        }

        .buttons {
            flex-wrap: wrap;
            grid-row-gap: 18px;
        }
    }

    &__bottom-content {
        max-width: ac(486px, 380px);
        width: 100%;
        margin-left: 0;
        margin-right: auto;

        .title {
            padding-bottom: ac(18px, 16px);
        }

        .content-element {
            @mixin mob-lg {
                max-width: 312px;
            }
        }
    }

    .swoosh-video {
        width: ac(980px, 472px);
        height: ac(1065px, 514px);
        position: absolute;
        bottom: ac(-300px, -220px);
        right: 0;
        transform: translateX(56.5%);
        pointer-events: none;

        video {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }

        video {
            transition: opacity 0.1s ease;
            &.lazyload {
                opacity: 0;
            }

            &.lazyloaded {
                opacity: 1;
            }
        }

        .plyr {
            position: absolute;
            width: ac(980px, 472px);
            max-width: ac(980px, 472px);
            height: ac(1065px, 514px);
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);

            video {
                transform: scale(2);
            }

            /*.plyr__video-wrapper {
                transform: scale(2.25) translateY(18%);
            }*/
        }

        @mixin tab {
            transform: translateX(62.5%);
        }

        @mixin tab-md {
            transform: translateX(48.5%);
        }

        @mixin mob-lg {
            transform: translateX(54%) translateY(5%);
        }

        @mixin media 374 {
            transform: translateX(60%) translateY(5%);
        }

        @mixin mob-sm {
            transform: translateX(62%) translateY(8%);
        }
    }

    @mixin mob-sm {
        padding-bottom: 150px;
    }

    &.clients-page {
        padding-bottom: ac(395px, 180px);
        .first-section__title-content:not(:last-child) {
            padding-bottom: ac(165px, 80px);
        }

        .first-section__subtitle-content {
            max-width: ac(580px, 380px);
            margin-right: ac(-95px, 0px);

            @mixin mob {
                max-width: 100%;
            }
        }

        + .text-photo-section.clients-hiring-solutions-type {
            margin-top: ac(-360px, -140px);
        }
    }
}

.first-internal-section {
    padding-top: ac(176px, 128px);
    padding-bottom: ac(280px, 100px);
    background: var(--dark);
    position: relative;
    overflow: hidden;
    z-index: 10;
    min-height: ac(1316px, 900px);
    clip-path: url("#first-clip-path-about-us");
    -webkit-backface-visibility: hidden;

    @mixin mob-xl {
        min-height: auto;
        clip-path: url("#first-clip-path-mob-to-right");
    }

    &.company-information-page {
        clip-path: url("#first-clip-path-company-information");
        /*min-height: ac(1391px, 900px);*/

        @mixin mob-xl {
            clip-path: url("#first-clip-path-mob-to-right");
        }
    }

    &.join-teh-team-page {
        clip-path: url("#first-clip-path-join-the-team");
        min-height: ac(1316px, 900px);

        @mixin tab-sm {
            clip-path: url("#first-clip-path-mob-to-left");
            min-height: auto;
        }
    }

    &.our-sectors-page,
    &.open-news-first {
        clip-path: url("#first-clip-path-our-sectors");
        min-height: ac(985px, 800px);

        @mixin mob-xl {
            clip-path: url("#first-clip-path-mob-our-sectors");
            min-height: auto;
        }

        .first-internal-section__image {
            &:after {
                background: linear-gradient(
                    92.46deg,
                    rgba(121, 204, 240, 0.7) 2.43%,
                    rgba(77, 178, 161, 0.7) 98.48%
                );
                mix-blend-mode: normal;
            }
        }
    }

    &.candidate-page {
        min-height: ac(1402px, 900px);

        @mixin min-media 2300 {
            min-height: 1600px;

            .first-internal-section__image {
                img {
                    object-position: 50% 50%;
                }
            }
        }

        @mixin mob-xl {
            min-height: auto;
        }

        .first-internal-section__content {
            padding-top: ac(120px, 0px);
        }

        .first-internal-section__image {
            top: ac(163px, 180px);
            &:after {
                background: linear-gradient(
                    92.46deg,
                    rgba(121, 204, 240, 0.7) 2.43%,
                    rgba(77, 178, 161, 0.7) 98.48%
                );
                mix-blend-mode: normal;
            }

            img {
                filter: grayscale(100%);
                /*object-position: 38% 50%;*/

                @mixin min-media 1890 {
                    object-position: top;
                }
            }
        }
    }

    &.job-details-first {
        min-height: ac(1288px, 900px);

        @mixin mob-xl {
            min-height: auto;
        }

        .first-internal-section__title-content {
            margin-bottom: 58px;
        }

        .first-internal-section__content {
            max-width: 630px;
        }

        .first-internal-section__image {
            &:after {
                background: linear-gradient(
                    92.46deg,
                    rgba(121, 204, 240, 0.7) 2.43%,
                    rgba(77, 178, 161, 0.7) 98.48%
                );
                mix-blend-mode: normal;
            }

            img {
                filter: grayscale(100%);
                object-position: 38% 50%;
            }
        }
    }

    &.meet-the-team-page {
        clip-path: url("#first-clip-path-meet-the-team");
        min-height: ac(957px, 800px);

        @mixin tab-sm {
            padding-bottom: 220px;
            min-height: auto;
        }

        @mixin mob-xl {
            clip-path: url("#first-clip-path-mob-to-right");
        }

        @mixin mob-lg {
            padding-bottom: 200px;
        }

        @mixin mob {
            padding-bottom: 180px;
        }
    }

    &.live-roles-page {
        clip-path: url("#first-clip-path-live-roles");
        overflow: hidden;

        min-height: ac(886px, 800px);

        @mixin tab-sm {
            padding-bottom: 200px;
            min-height: auto;
        }

        @mixin mob-xl {
            clip-path: url("#first-clip-path-mob-to-right");
        }

        &:before {
            position: absolute;
            content: "";
            top: 11px;
            left: ac(-416px, -300px);
            bottom: ac(-438px, -300px);
            right: ac(-197px, -100px);
            clip-path: url("#first-swoosh-clip-path");
            z-index: 3;
            background: linear-gradient(
                316.58deg,
                #5acdf3 12.17%,
                #073267 81.12%
            );

            @mixin min-media 1860 {
                right: -230px;
            }

            @mixin mob-xl {
                top: 40px;
                bottom: -400px;
                left: -400px;
            }
        }

        .first-internal-section__wrap {
            z-index: 6;
        }

        .first-internal-section__image {
            &:after {
                background: linear-gradient(
                        92.46deg,
                        rgba(121, 204, 240, 0.7) 2.43%,
                        rgba(77, 178, 161, 0.7) 98.48%
                    ),
                    linear-gradient(
                        0deg,
                        rgba(60, 60, 59, 0.5),
                        rgba(60, 60, 59, 0.5)
                    );
                mix-blend-mode: normal;
            }

            img {
                filter: grayscale(100%);
                object-position: 50% 50%;
                transform: translateX(29%) translateY(-13%) scale(0.9);
            }
        }
    }

    &.work-for-page {
        /*clip-path: url("#first-clip-path-live-roles");*/
        overflow: visible;
        clip-path: none;

        min-height: ac(886px, 800px);

        @mixin tab-sm {
            padding-bottom: 200px;
            min-height: auto;
        }

        @mixin mob-xl {
            /*clip-path: url("#first-clip-path-mob-to-right");*/
        }

        &:before {
            position: absolute;
            content: "";
            top: 11px;
            left: ac(-416px, -300px);
            bottom: ac(-300px, -200px);
            right: ac(-197px, -100px);
            clip-path: url("#first-swoosh-clip-path");
            z-index: 3;
            background: linear-gradient(
                316.58deg,
                #5acdf3 12.17%,
                #073267 81.12%
            );

            @mixin min-media 1860 {
                right: -230px;
            }

            @mixin mob-xl {
                bottom: -100px;
                left: -450px;
                top: 40px;
                transform: rotate(-15deg) scale(1.2) translateY(10%);
            }

            @media (max-width: 490px) {
                bottom: -100px;
                left: -450px;
                top: 40px;
                transform: rotate(-18deg) scale(1.4) translateY(10%);
            }
        }

        .first-internal-section__wrap {
            z-index: 6;
        }

        .first-internal-section__image {
            &:after {
                background: linear-gradient(
                        92.46deg,
                        rgba(121, 204, 240, 0.7) 2.43%,
                        rgba(77, 178, 161, 0.7) 98.48%
                    ),
                    linear-gradient(
                        0deg,
                        rgba(60, 60, 59, 0.5),
                        rgba(60, 60, 59, 0.5)
                    );
                mix-blend-mode: normal;
            }

            img {
                filter: grayscale(100%);
                object-position: 50% 50%;
                transform: translateX(29%) translateY(-13%) scale(0.9);
            }
        }

        .first-internal-section__list {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            grid-row-gap: ac(24px, 18px);

            ul {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                grid-row-gap: ac(24px, 18px);
            }

            li {
                position: relative;
                padding-left: ac(55px, 38px);
                font-size: ac(22px, 18px);
                font-weight: 500;
                line-height: ac(36px, 24px);
                color: var(--white);

                &:before {
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                    --size: ac(40px, 28px);
                    width: var(--size);
                    height: var(--size);
                    position: absolute;
                    mask-image: url("../../public/images/list-mask.svg");
                    mask-size: 100% 100%;
                    mask-repeat: no-repeat;
                    content: "";
                    background: var(--white);
                }
            }
        }

        .first-internal-section__bottom-decor {
            @mixin aspect-ratio 1440.5, 1035.96;
            position: absolute;
            bottom: 48%;
            transform: translateY(100%);
            left: -1px;
            width: calc(100% + 2px);
            z-index: 2;

            @media (max-width: 640px) {
                bottom: 20%;
            }
        }
    }

    &.job-details-first {
        clip-path: url("#first-clip-path-job-detail");

        @mixin mob-xl {
            clip-path: url("#first-clip-path-mob-to-right");
        }
    }

    &.clients-page {
        clip-path: inset(0 0 -1000px 0);
        overflow: visible;
        padding-bottom: ac(200px, 100px);

        min-height: ac(1117px, 800px);

        @mixin tab-sm {
            padding-bottom: 150px;
            min-height: auto;
        }

        .first-internal-section__image {
            top: 11px;
            left: ac(-416px, -200px);
            bottom: ac(-160px, -40px);
            right: ac(-197px, -100px);
            clip-path: url("#first-swoosh-clip-path");
            z-index: 1;
            background: var(--dark);

            @mixin min-media 1860 {
                right: -230px;
            }

            img {
                opacity: 0.35;
                filter: grayscale(100%);
            }

            &:after {
                background: linear-gradient(
                    92.46deg,
                    rgba(121, 204, 240, 0.6) 2.43%,
                    rgba(77, 178, 161, 0.6) 98.48%
                );
            }
        }
    }

    &.hiring-solutions-page {
        clip-path: none;
        overflow: hidden;
        padding-bottom: ac(240px, 120px);
        min-height: auto;

        .first-internal-section__content {
            margin-top: ac(-6px, 0px);
        }

        .first-internal-section__text {
            transform: translateX(ac(18px, 0px));
        }

        + .statistics-section {
            margin-top: ac(-146px, -100px);
            z-index: 20;
            overflow: hidden;
        }
    }

    &.news-page {
        clip-path: url("#first-clip-path-our-sectors");
        min-height: ac(985px, 800px);

        @mixin mob-xl {
            clip-path: url("#first-clip-path-mob-our-sectors");
            min-height: auto;
        }
    }

    &.contact-us-page {
        clip-path: none;
        min-height: auto;
        padding-bottom: ac(160px, 100px);
        overflow: visible;

        .first-internal-section__title-content:not(:last-child) {
            margin-bottom: ac(48px, 32px);
        }

        .first-internal-section__links {
            margin-left: auto;
            margin-right: ac(82px, 60px);

            @mixin mob {
                margin-left: 0;
                margin-right: auto;
            }
        }

        @mixin min-media 1460 {
            .first-internal-section__decor {
                top: -50vw;
                transform: translateX(-53%) rotate(28deg);
            }
        }

        @mixin min-media 1660 {
            padding-bottom: 220px;
        }

        @mixin min-media 1780 {
            padding-bottom: 240px;

            .first-internal-section__title-content:not(:last-child) {
                margin-bottom: 80px;
            }
        }

        @mixin min-media 1880 {
            .first-internal-section__decor {
                top: -52vw;
                transform: translateX(-50%) rotate(32deg);
            }
        }
    }

    &__top-decor {
        /*clip-path: url("#first-clip-path-top");*/
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 54.51vw;
        width: 100%;
        z-index: 2;
        /*background: var(--dark);*/

        background-image: url("../images/decors/first-top-decor.svg");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        transform: scale(1.01);

        @mixin mob-xl {
            transform: scale(2.2);
        }
    }

    &__decor {
        @mixin aspect-ratio 1684, 1830;
        overflow: visible;
        position: absolute;
        left: 50%;
        top: ac(-662px, -200px);
        transform: translateX(-53%) rotate(26deg);
        width: 116.94%;
        z-index: 3;
        min-width: 800px;
        pointer-events: none;

        @mixin min-media 1500 {
            top: -45.97vw;
        }

        @mixin mob-lg {
            transform: translateX(-55%) rotate(26deg);
        }

        @mixin mob {
            transform: translateX(-56%) rotate(26deg);
        }

        @mixin mob-sm {
            transform: translateX(-57%) rotate(26deg);
        }

        img {
            object-fit: fill;
        }
    }

    &__decor-second {
        width: 100%;
        @mixin aspect-ratio 1440, 1006;
        position: absolute;
        right: 0;
        top: ac(369px, 300px);

        min-width: 460px;

        @mixin media 452 {
            right: -8px;
        }
    }

    &__image {
        position: absolute;
        top: 94px;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;

        &:after {
            position: absolute;
            inset: 0;
            content: "";
            background: linear-gradient(
                0deg,
                rgba(60, 60, 59, 0.5),
                rgba(60, 60, 59, 0.5)
            );
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        video {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__wrap {
        width: 100%;
        position: relative;
        z-index: 4;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        .buttons {
            grid-row-gap: 10px;
        }
    }

    &__title-content {
        padding-top: ac(104px, 60px);
        padding-left: ac(108px, 0px);
        max-width: ac(888px, 400px);
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        .title {
            padding-bottom: ac(48px, 34px);
        }

        &:not(:last-child) {
            margin-bottom: ac(165px, 80px);
        }

        .buttons {
            flex-wrap: wrap;
            @mixin mob-lg {
                max-width: 400px;
            }
        }

        @mixin mob-lg {
            &:not(:last-child) {
                margin-bottom: 88px;
            }
        }

        @mixin mob {
            &:not(:last-child) {
                margin-bottom: 72px;
            }
        }

        @mixin media 375 {
            &:not(:last-child) {
                margin-bottom: 60px;
            }
        }

        @mixin min-media 2200 {
            &:not(:last-child) {
                margin-bottom: 200px;
            }
        }
    }

    &__content {
        max-width: ac(630px, 380px);
        width: 100%;
        margin-left: auto;

        &:not(:last-child) {
            padding-bottom: ac(38px, 40px);
        }

        @mixin mob-lg {
            margin-left: 0;

            br {
                display: none;
            }
        }

        @mixin min-media 2200 {
            transform: translateX(3vw);
        }

        @mixin min-media 2450 {
            transform: translateX(5vw);
        }
    }

    &__text {
        margin-top: ac(8px, 0px);
        margin-left: auto;
        margin-right: auto;
        max-width: ac(449px, 300px);

        @mixin mob {
            margin-left: 0;
        }
    }

    &__links {
        margin-top: 8px;
        margin-left: auto;
        margin-right: ac(300px, 80px);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        @mixin mob {
            margin-top: -3px;
        }

        @mixin mob-sm {
            margin-left: 20px;
        }
    }

    &__link {
        font-size: ac(28px, 18px);
        line-height: ac(44px, 28px);
        transition: all 0.25s ease;

        &:hover {
            color: var(--green);
        }

        &:not(:last-child) {
            margin-bottom: ac(6px, 2px);
        }

        @mixin media 374 {
            font-size: 16px;
        }
    }

    &__socials {
        margin-top: ac(36px, 12px);
    }
}

.our-sectors-first-container {
    position: relative;

    &__content {
        position: absolute;
        bottom: -20px;
        right: 0;
        padding-left: ac(108px, 60px);
        padding-top: ac(80px, 40px);
        padding-bottom: ac(80px, 40px);
        padding-right: ac(80px, 40px);
        background: linear-gradient(92.46deg, #79ccf0 2.43%, #4db2a1 98.48%);
        border-radius: 140px 0 0 140px;
        z-index: 10;

        &:before {
            position: absolute;
            inset: 4px;
            right: 0;
            background: var(--dark);
            content: "";
            border-radius: 138px 0 0 138px;
            z-index: 1;
        }

        .subtitle {
            position: relative;
            z-index: 2;
            max-width: ac(630px, 400px);
        }

        @mixin mob-xl {
            border-radius: 60px 0 0 60px;
            margin-top: -56px;
            position: relative;
            bottom: auto;
            right: auto;
            width: 100%;
            margin-bottom: 24px;
            padding-left: 32px;
            padding-right: 32px;

            &:before {
                border-radius: 58px 0 0 58px;
            }
        }
    }
}

.latest-news-first-section {
    &.first-internal-section {
        clip-path: unset;
        min-height: ac(988px, 900px);
        padding-bottom: 450px;

        & .first-internal-section__decor-second {
            img {
                top: 86px;
            }
        }
    }
}

.open-news-first {
    position: relative;

    &.first-internal-section {
        padding-bottom: 225px;

        .first-internal-section__title-content {
            max-width: 1064px;
            margin-bottom: 25px;
            padding-top: 48px;
        }

        .title h1 {
            font-size: ac(56px, 28px);
            font-weight: 500;
            line-height: 1.43;
        }
    }

    &__short-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 40px;

        width: 100%;
        max-width: 1064px;

        margin: 0 auto;
        padding-left: ac(40px, 20px);
        padding-bottom: 30px;

        @mixin media 769 {
            flex-direction: column;
            align-items: start;
        }
    }

    &__short-info-left {
        max-width: 504px;

        h3 {
            margin-bottom: 19px !important;

            font-size: 22px;
            line-height: 1.64;
        }

        p,
        li,
        ol {
            font-size: 16px;
            font-weight: 500;
            line-height: 1.75;
        }

        ul {
            li {
                position: relative;

                padding-left: 40px;

                &:not(:last-child) {
                    margin-bottom: ac(16px, 10px);
                }

                &::before {
                    content: "\e908";
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);

                    width: 32px;
                    height: 32px;

                    font-family: "icomoon" !important;
                    font-size: 16px;
                    text-align: center;

                    @mixin mob-xl {
                        background-color: var(--white) !important;
                    }
                }
            }
        }

        @mixin media 1240 {
            margin-left: 40px;
        }

        @mixin mob-xl {
            margin-left: 0;
        }
    }

    &__short-info-right {
        position: relative;
        top: 7px;

        flex-shrink: 0;
        color: var(--white);

        @mixin media 769 {
            position: static;
            margin-left: auto;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: flex-start;
        }
    }

    &__short-info-date-title-wrap {
        display: flex;
        align-items: center;
        gap: 10px;

        margin-bottom: 12px;

        @mixin media 769 {
            margin-bottom: 0;
        }
    }

    &__short-info-date-icon {
        font-size: ac(26px, 20px);
    }

    &__short-info-date-title {
        font-size: ac(16px, 14px);
        font-weight: 500;
        line-height: 1;
    }

    &__short-info-date {
        font-size: ac(22px, 18px);
    }
}

.job-details-first {
    &__short-info-card {
        background: var(--white);

        max-width: ac(470px, 380px);

        padding: ac(63px, 25px) ac(52px, 20px);
        border-radius: 20px;

        @mixin media 1025 {
            margin-left: auto;
        }

        @mixin media 651 {
            max-width: 300px;
        }

        @mixin media 415 {
            max-width: unset;
        }
    }

    &__short-info-item {
        &:not(:last-child) {
            border-bottom: 1px solid var(--dark-grey);
            padding-bottom: ac(12px, 8px);
            margin-bottom: ac(15px, 8px);
        }

        font-size: ac(20px, 16px);
        font-weight: 500;
        line-height: 1.35;
    }
}
