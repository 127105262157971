.footer {
	padding-top: ac(44px, 38px);
	background: var(--dark);
	padding-bottom: 32px;

	&__top {
		width: 100%;
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		padding-bottom: ac(62px, 32px);
		border-bottom: 1px solid color-mod(var(--white) a(15%));

		@mixin tab-sm {
			flex-wrap: wrap;
		}
	}

	&__logo {
		margin-top: ac(4px, 0px);
		width: ac(135px, 103px);

		.logo {
			width: 100%;
			@mixin aspect-ratio 103, 106;
			transition: transform .3s ease;

			img {
				object-fit: contain;
				object-position: left;
			}

			&:hover {
				transform: scale(1.03);
			}
		}

		@mixin tab {
			width: 103px;
		}

		@mixin media 374 {
			width: 90px;
		}

		@mixin mob-sm {
			width: 80px;
		}

		@mixin media 350 {
			width: 64px;
		}
	}

	&__links {
		margin-top: ac(-4px, 0px);
		margin-left: ac(40px, 28px);
		margin-right: ac(155px, 20px);
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;

		@mixin mob {
			margin-top: -3px;
		}

		@mixin mob-sm {
			margin-left: 20px;
		}
	}

	&__link {
		font-size: ac(28px, 18px);
		line-height: ac(44px, 18px);
		transition: all 0.25s ease;

		&:hover {
			color: var(--green);
		}

		&:not(:last-child) {
			margin-bottom: ac(8px, 18px);
		}

		@mixin media 374 {
			font-size: 16px;
		}
	}

	&__socials {
		margin-top: ac(24px, 0px);
	}

	&__locations {
		margin-top: ac(-4px, 0px);
		transform: translateX(ac(-4px, 0px));
		margin-left: auto;
		flex: 1;
		max-width: ac(324px, 220px);
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;

		@mixin tab-sm {
			margin-top: 16px;
			max-width: 100%;
			width: 100%;
			margin-left: 0;
			flex: none;
		}
	}

	&__location {
		font-size: ac(18px, 16px);
		font-weight: 300;
		line-height: ac(32px, 26px);
		transition: all 0.25s ease;
		&:hover {
			color: var(--green);
		}

		@mixin mob-lg {
			font-weight: 400;
		}
	}

	&__bottom {
		padding-top: 28px;
		padding-bottom: ac(30px, 22px);
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;

		@mixin tab-md {
			flex-direction: column;
			justify-content: flex-start;
		}
	}

	&__bottom-content {
		text-align: center;
		margin-right: ac(2px, 0px);
		@mixin tab-md {
			&:not(:last-child) {
				margin-bottom: 20px;
			}
		}

		@mixin mob-lg {
			&.content-element {
				p {
					font-size: 14px;
				}
			}
		}
	}

	&__developer {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
