.solutions-section {
    padding-top: ac(86px, 60px);
    padding-bottom: ac(78px, 40px);
    overflow: hidden;

    .big-cont {
        position: relative;
    }

    &__title {
        margin-bottom: ac(48px, 32px);
    }
}

.solutions-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .solutions-content {
        width: 57.66%;
        position: relative;

        @mixin desk-sm {
            width: 56%;
        }
    }

    .solutions-tab {
        width: 38.59%;

        @mixin desk-sm {
            width: 42%;
        }

        @mixin mob-xl {
            width: 100%;
        }
    }
}

.solutions-content {
    position: relative;
    &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        transform: translateX(-104%);
        transition: transform 0.4s ease, opacity 0.4s ease;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        pointer-events: none;

        &.active {
            transform: translateX(0);
            position: relative;
            pointer-events: auto;
            opacity: 1;
        }
    }

    &__image {
        @mixin aspect-ratio 1, 1;
        width: ac(120px, 72px);
    }

    &__item-content {
        width: 100%;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
        border-radius: 20px;
        padding: ac(46px, 20px) ac(44px, 22px) ac(60px, 22px) ac(56px, 22px);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        position: relative;
        z-index: 2;
        background: var(--white);
    }

    &__item-content-top {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-right: ac(16px, 0px);
        margin-bottom: ac(36px, 24px);
    }

    &__title {
        padding-left: ac(20px, 16px);
        flex: 1;
        font-size: ac(32px, 24px);
        font-weight: 400;
        line-height: ac(48px, 30px);
        max-width: calc(100% - 42px);
    }

    &__btn {
        position: relative;
        width: ac(28px, 24px);
        min-width: ac(28px, 24px);
        height: ac(28px, 24px);
        color: var(--dark);
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.3s ease;
        cursor: pointer;

        i {
            position: absolute;
            font-size: ac(28px, 24px);
            transition: all 0.3s ease;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);

            &.icon-minus {
                opacity: 0;
            }
            &.icon-plus {
                opacity: 1;
            }
        }

        &:hover {
            color: var(--dark-blue);
        }

        &.active {
            opacity: 0;
            pointer-events: none;
            /*i {
                &.icon-minus {
                    opacity: 1;
                }
                &.icon-plus {
                    opacity: 0;
                }
            }*/
        }
    }

    &__text {
        width: 100%;
        /*max-height: calc(ac(28px, 24px) * 12);*/

        &.small-content-element {
            p,
            li {
                line-height: ac(28px, 24px);

                &:not(:last-child) {
                    margin-bottom: ac(12px, 24px);
                }
            }
        }

        /*.simplebar-track.simplebar-vertical {
            width: 4px;
            background: transparent;
            border-radius: 2px;
            transform: translateX(6px);

            .simplebar-scrollbar {
                background: var(--snow);
                border-radius: 2px;

                &:before {
                    content: none;
                }
            }
        }*/
    }

    .btn {
        margin-top: ac(32px, 24px);
    }

    @mixin mob-xl {
        display: none;
    }
}

.solutions-tab {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    &__item {
        width: 100%;
        overflow: hidden;
        transition: max-height 0.3s ease 0.35s, margin-bottom 0.3s ease 0.35s,
            border-radius 0.2s ease 0.3s, background-color 0.25s ease,
            opacity 0.4s ease;
        max-height: 200px;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
        border-radius: 1000px 20px 20px 1000px;

        &:not(:last-child) {
            margin-bottom: ac(20px, 18px);
        }

        &.active {
            transition: max-height 0.3s ease 0.35s,
                margin-bottom 0.3s ease 0.35s, border-radius 0.02s ease,
                opacity 0.4s ease;
            @mixin min-media 652 {
                max-height: 0;
                opacity: 0;

                &:not(:last-child) {
                    margin-bottom: 0;
                }

                .solutions-tab__item-content {
                    transform: translateX(100%);
                }
            }

            @mixin mob-xl {
                border-radius: 36px 20px 20px 20px;
                .solutions-tab__text-content {
                    opacity: 1;
                    max-height: 500px;
                    margin-top: 20px;
                    margin-bottom: 20px;
                }
            }
        }

        @mixin mob-xl {
            max-height: none;
            overflow: visible;
        }

        &:not(.active) {
            cursor: pointer;
            &:hover {
                background-color: var(--snow);
            }
        }
    }

    &__item-content {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        transition: transform 0.3s ease, opacity 0.3s ease;
        transform: translateX(0);
        opacity: 1;
    }

    &__image {
        @mixin aspect-ratio 1, 1;
        width: ac(120px, 72px);
    }

    &__title-btn-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-left: 0;
        padding-right: ac(32px, 16px);
        padding-top: 0;
        padding-bottom: 0;
    }

    &__title {
        padding-left: ac(20px, 12px);
        flex: 1;
        font-size: ac(22px, 16px);
        font-weight: 500;
        line-height: ac(36px, 22px);

        min-height: calc(ac(36px, 22px) * 2);
        display: flex;
        align-items: center;
        justify-content: flex-start;

        @mixin tab-md {
            @mixin min-media 652 {
                font-size: 15px;
            }
        }
    }

    &__btn {
        width: ac(28px, 24px);
        min-width: ac(28px, 24px);
        height: ac(28px, 24px);
        color: var(--dark);
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.3s ease;
        cursor: pointer;
        position: relative;

        i {
            position: absolute;
            font-size: ac(28px, 24px);
            transition: all 0.3s ease;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);

            &.icon-minus {
                opacity: 0;
            }
            &.icon-plus {
                opacity: 1;
            }
        }

        &:hover {
            color: var(--dark-blue);
        }

        &.active {
            color: var(--dark);
            i {
                &.icon-minus {
                    opacity: 1;
                }
                &.icon-plus {
                    opacity: 0;
                }
            }
        }
    }

    &__text-content {
        display: none;

        @mixin mob-xl {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            width: 100%;
            max-height: 0;
            padding-left: 20px;
            padding-right: 20px;
            transition: max-height 0.4s ease, margin 0.4s ease,
                opacity 0.3s ease;
            opacity: 0;
            margin-top: 0;

            .btn {
                margin-top: 20px;
            }
        }
    }

    &__text {
        width: 100%;
        /*max-height: calc(ac(28px, 22px) * 10);

        .simplebar-track.simplebar-vertical {
            width: 2px;
            background: transparent;
            border-radius: 2px;
            transform: translateX(5px);

            .simplebar-scrollbar {
                background: var(--snow);
                border-radius: 2px;

                &:before {
                    content: none;
                }
            }
        }*/

        &.smallest-type {
            p,
            li {
                &:not(:last-child) {
                    line-height: ac(28px, 22px);
                }
            }
        }
    }
}
