.statistics-section {
	margin-top: ac(95px, 10px);
	clip-path: url("#clients-statistics-clip-path");
	min-height: ac(1341px, 800px);
	padding-top: ac(108px, 90px);
	padding-bottom: ac(700px, 420px);

	&:before {
		inset: 0;
		content: "";
		position: absolute;
		z-index: 2;
		background: linear-gradient(92.46deg, #79ccf0 2.43%, #4db2a1 98.48%);
		mix-blend-mode: hard-light;
	}

	&:after {
		inset: 0;
		content: "";
		position: absolute;
		z-index: 3;
		background: linear-gradient(92.46deg, #79ccf0 2.43%, #4db2a1 98.48%);
		opacity: 0.1;
	}

	@mixin tab-sm {
		clip-path: url("#statistics-clip-path-mob");
	}

	&__image {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 252px;
		top: 273px;
		mask-image: linear-gradient(
			to top,
			transparent,
			black 5%,
			black 50%,
			transparent
		);

		img {
			width: 100%;
			height: 100%;
			filter: grayscale(100%) saturate(132%);
			object-position: top;
			object-fit: cover;
		}

		&:before {
			inset: 0;
			content: "";
			position: absolute;
			z-index: 2;
			background: linear-gradient(
				92.46deg,
				rgba(121, 204, 240, 0.7) 2.43%,
				rgba(77, 178, 161, 0.7) 98.48%
			);
		}
	}

	&__wrap {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
		width: 100%;
		position: relative;
		z-index: 5;
	}

	&__top {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: ac(74px, 34px);
	}

	&__logo {
		margin-right: ac(16px, 12px);
		width: ac(80px, 64px);
		@mixin aspect-ratio 1, 1;
		overflow: visible;
		img {
			object-fit: contain;
		}
	}

	&__title {
		color: var(--white);

		@mixin mob {
			font-size: 28px;
		}

		@mixin mob-sm {
			font-size: 24px;
		}
	}

	&__container {
		width: 100%;
		display: flex;
		align-items: stretch;
		justify-content: center;
		flex-wrap: wrap;
		grid-column-gap: ac(20px, 12px);
		grid-row-gap: ac(14px, 12px);
	}

	&__item {
		width: calc(24.6% - ac(10px, 6px));
		display: flex;
		align-items: center;
		justify-content: flex-start;
		flex-direction: column;
		padding-top: ac(36px, 28px);
		padding-bottom: ac(44px, 28px);
		position: relative;
		z-index: 2;
		border-radius: ac(190px, 80px);
		padding-left: 10px;
		padding-right: 10px;

		&:before {
			content: "";
			inset: 0;
			position: absolute;
			background: linear-gradient(308.94deg, #79ccf0 14.47%, #4db2a1 98.3%);
			border-radius: ac(190px, 80px);
			z-index: -2;
		}

		&:after {
			content: "";
			inset: 2px;
			position: absolute;
			background: var(--white);
			border-radius: ac(188px, 78px);
			z-index: -1;
		}

		@mixin media 1439 {
			width: calc(23.6% - ac(10px, 6px));
		}

		@mixin desk-sm {
			width: 22%;
		}

		@mixin media 1100 {
			width: 21.5%;
		}

		@mixin tab {
			width: 29%;
		}

		@mixin mob-xl {
			width: 47%;
		}
		@mixin mob {
			border-radius: 40px;

			&:before {
				border-radius: 40px;
			}

			&:after {
				border-radius: 38px;
			}
		}

		@mixin mob-sm {
			padding-top: 20px;
			padding-bottom: 20px;
			width: 80%;
		}
	}

	&__num {
		font-weight: 500;
		font-size: ac(56px, 24px);
		line-height: ac(72px, 36px);
		text-align: center;
	}

	&__name {
		margin-top: 2px;
		p {
			font-size: ac(20px, 14px);
			line-height: ac(34px, 22px);
			font-weight: 500;
			text-align: center;
		}
	}

	&__wave {
		position: absolute;
		z-index: 4;
		top: 507px;
		left: 0;
		width: 100%;
		height: 55.14vw;
		background-image: url("../images/decors/wave-statistics-white.svg");
		background-size: 100% 100%;
		background-repeat: no-repeat;
		&:before {
			background: var(--white);
			bottom: 10px;
			left: 0;
			right: 0;
			height: 200px;
			position: absolute;
			content: "";
			transform: translateY(100%);
		}

		@mixin mob-sm {
			top: 50%;
		}
	}

	&.hiring-solutions-page {
		min-height: ac(1341px, 800px);
		padding-top: ac(256px, 120px);
		padding-bottom: ac(700px, 420px);
		overflow: hidden;

		&::before {
			mix-blend-mode: color;
		}

		.statistics-section__image {
			bottom: 413px;
			top: 100px;

			&:before {
				background: linear-gradient(
					92.46deg,
					rgba(121, 204, 240, 0.7) 2.43%,
					rgba(77, 178, 161, 0.7) 98.48%
				);
			}
		}

		.statistics-section__item {
			border-radius: ac(190px, 80px);

			&:before {
				background: linear-gradient(308.94deg, #79ccf0 14.47%, #4db2a1 98.3%);
			}

			&:after {
				background: var(--dark);
			}
		}

		+ .text-photo-section.company-information-type {
			margin-top: ac(-546px, -220px);
			overflow: visible;

			.btn {
				transform: translateY(ac(-3px, 0px));
			}

			@mixin min-media 2200 {
				padding-top: 110px;
			}

			@mixin tab {
				margin-top: ac(-800px, -300px);
			}

			@mixin mob-xl {
				margin-top: -400px;

				&:after {
					bottom: 0;
					left: 0;
					right: 0;
					height: 80%;
					background: var(--white);
					position: absolute;
					z-index: -1;
					content: "";
				}
			}

			@mixin mob-lg {
				margin-top: -450px;
			}
		}
	}

	+ .text-photo-section.our-values-type {
		margin-top: ac(-666px, -400px);
		padding-top: ac(118px, 50px);
		position: relative;
		z-index: 20;
		clip-path: inset(-300px 0 -500px 0);
		overflow: visible;

		@mixin min-media 2200 {
			padding-top: 140px;
		}

		&:before {
			content: "";
			background-image: url("../images/decors/big-wave-to-right.svg");
			top: 0;
			left: 0;
			background-size: 100% 100%;
			background-repeat: no-repeat;
			position: absolute;
			width: 100%;
			height: 177.21vw;
			z-index: -1;
			pointer-events: none;
		}

		.text-photo-section__wrap {
			position: relative;
			z-index: 4;

			@mixin tab-md {
				margin-top: 30px;
			}
		}

		.text-photo-section__subtitle {
			color: var(--dark);

			@mixin tab-md {
				max-width: 80%;
			}
		}
	}
}
