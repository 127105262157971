.sectors-section {
	padding-top: ac(94px, 134px);
	padding-bottom: ac(0px, 70px);
	overflow: hidden;
	z-index: 9;

	&__wrap {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
	}

	&__top {
		position: relative;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-bottom: ac(30px, 24px);

		.small-title {
			max-width: calc(100% - ac(180px, 114px));
		}

		.slider-buttons {
			transform: translateX(ac(145px, 0px));
		}
	}

	.buttons {
		margin-top: ac(52px, 34px);
		margin-left: auto;
		margin-right: 0;
		transform: translateX(ac(142px, 0px));
	}

	&.hiring-solutions-page {
		background: var(--white);
		padding-top: ac(88px, 40px);
		padding-bottom: 4px;

		.sector-card__content {
			align-items: center;
		}

		.sector-card__name {
			text-align: left;
		}

		.sectors-section__top {
			margin-bottom: ac(18px, 0px);

			.small-title {
				transform: translateX(ac(-40px, 0px));
			}
		}

		.sectors-slider {
			transform: translateX(ac(-40px, 0px));
		}

		.buttons {
			margin-top: ac(68px, 34px);
		}
	}
}

.all-sectors-section {
	padding-top: ac(178px, 60px);
	padding-bottom: ac(32px, 24px);
	overflow: hidden;

	.big-cont {
		position: relative;
	}
}

.all-sectors-container {
	width: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;

	.all-sectors-content {
		width: 55.47%;
		position: relative;

		@mixin desk-sm {
			width: 52.5%;
		}
	}

	.all-sectors-tab {
		width: 40.78%;

		@mixin desk-sm {
			width: 44.5%;
		}

		@mixin mob-xl {
			width: 100%;
		}
	}
}

.all-sectors-content {
	position: relative;
	&__item {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
		width: 100%;
		transform: translateX(-104%);
		transition: transform 0.4s ease, opacity 0.4s ease;
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		pointer-events: none;

		&.active {
			transform: translateX(0);
			position: relative;
			pointer-events: auto;
			opacity: 1;
		}
	}

	&__image {
		width: 100%;
		margin-bottom: ac(-160px, -80px);
		box-shadow: inset 0px -32px 80px rgba(255, 255, 255, 0.5);
		filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.15))
			drop-shadow(4px 4px 8px rgba(0, 0, 0, 0.07))
			drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.08));
		border-radius: 20px;

		.image {
			width: 100%;
			@mixin aspect-ratio 710, 440;
			clip-path: inset(0 0 0 0 round 20px);
			position: relative;

			&:after {
				content: "";
				position: absolute;
				inset: 0;
				z-index: 2;
				box-shadow: inset 0px -52px 80px rgba(255, 255, 255, 0.6);
			}
		}
	}

	&__item-content {
		width: calc(100% - ac(64px, 32px));
		border: 1px solid var(--blue);
		padding: ac(40px, 20px) ac(48px, 22px) ac(44px, 22px);
		box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15), 4px 4px 8px rgba(0, 0, 0, 0.07),
			0px 2px 4px rgba(0, 0, 0, 0.08);
		border-radius: 20px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		position: relative;
		z-index: 2;
		background: var(--white);
	}

	&__title-content {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		margin-bottom: ac(16px, 14px);
	}

	&__title {
		font-size: ac(40px, 28px);
		font-weight: 500;
		line-height: ac(56px, 32px);
		max-width: calc(100% - 60px);
	}

	&__icon {
		@mixin aspect-ratio 1, 1;
		width: ac(60px, 56px);
		min-width: ac(60px, 56px);
		margin-right: ac(12px, 10px);
	}

	&__text {
		width: 100%;
		/*max-height: calc(ac(28px, 24px) * 12);*/

		h3 {
			font-size: ac(20px, 18px);
			font-weight: 500;
			line-height: ac(32px, 24px);
			color: var(--dark-blue);
		}

		/*.simplebar-track.simplebar-vertical {
			width: 4px;
			background: transparent;
			border-radius: 2px;
			transform: translateX(6px);

			.simplebar-scrollbar {
				background: var(--snow);
				border-radius: 2px;

				&:before {
					content: none;
				}
			}
		}*/
	}

	.btn {
		margin-top: ac(24px, 24px);
	}

	@mixin mob-xl {
		display: none;
	}
}

.all-sectors-tab {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;

	&__item {
		width: 100%;
		overflow: hidden;
		transition: max-height 0.3s ease, margin-bottom 0.3s ease,
			background-image 0.3s ease;
		transition-delay: 0.35s;
		max-height: 200px;
		background: linear-gradient(92.46deg, #79ccf0 2.43%, #4db2a1 98.48%);
		border-radius: 15px;
		padding-left: ac(32px, 18px);
		padding-right: ac(32px, 18px);
		padding-top: ac(24px, 16px);
		padding-bottom: ac(24px, 16px);
		position: relative;

		&:before {
			content: "";
			position: absolute;
			inset: 2px;
			background: var(--white);
			border-radius: 13px;
			opacity: 0;
			transition: opacity 0.3s ease;
			z-index: 1;
		}

		&:not(:last-child) {
			margin-bottom: ac(16px, 14px);
		}

		&.active {
			&:before {
				opacity: 1;
			}

			.all-sectors-tab__title {
				color: var(--dark);
			}

			@mixin mob-xl {
				.all-sectors-tab__text-content {
					margin-top: 18px;
					margin-bottom: 10px;
					max-height: 1000px;
					opacity: 1;
				}

				.all-sectors-tab__icon {
					width: 48px;
					min-width: 48px;
					margin-right: 8px;
				}

				.all-sectors-tab__image {
					max-height: 600px;
					margin-bottom: 14px;
				}
			}
		}

		@mixin mob-xl {
			max-height: none;
		}

		&:not(.active) {
			cursor: pointer;
			&:hover {
				&:before {
					opacity: 0.2;
				}
			}
		}
	}

	&__item-content {
		position: relative;
		z-index: 2;
		width: 100%;
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		transition: transform 0.3s ease, opacity 0.3s ease;
		transform: translateX(0);
		opacity: 1;
	}

	&__image {
		display: none;
		@mixin aspect-ratio 710, 440;
		z-index: 5;
		width: 100%;
		clip-path: inset(0 0 0 0 round 15px);
		transition: max-height 0.3s ease, margin 0.3s ease;
		max-height: 0;

		@mixin mob-xl {
			display: flex;
		}
	}

	&__icon {
		display: none;
		justify-content: center;
		align-items: center;
		width: 0;
		min-width: 0;
		height: 48px;
		transition: width 0.3s ease, min-width 0.3s ease, opacity 0.35s ease,
			margin 0.3s ease;

		img {
			object-fit: contain;
			width: 100%;
			height: 100%;
		}

		@mixin mob-xl {
			display: flex;
		}
	}

	&__title-btn-container {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	&__title {
		font-size: ac(22px, 20px);
		font-weight: 500;
		line-height: ac(36px, 22px);
		display: flex;
		align-items: center;
		justify-content: flex-start;
		color: var(--white);
		transition: color 0.3s ease;
	}

	&__btn {
		width: ac(28px, 24px);
		min-width: ac(28px, 24px);
		height: ac(28px, 24px);
		color: var(--white);
		display: flex;
		justify-content: center;
		align-items: center;
		transition: all 0.3s ease;
		cursor: pointer;
		position: relative;
		margin-left: auto;

		i {
			position: absolute;
			font-size: ac(28px, 24px);
			transition: all 0.3s ease;
			top: 50%;
			left: 50%;
			transform: translateX(-50%) translateY(-50%);

			&.icon-minus-btn {
				opacity: 0;
			}
			&.icon-plus-btn {
				opacity: 1;
			}
		}

		&:hover {
			color: var(--dark-blue);
		}

		&.active {
			color: var(--dark);
			opacity: 0;
			pointer-events: none;
			/*			i {
				&.icon-minus {
					opacity: 1;
				}
				&.icon-plus {
					opacity: 0;
				}
			}*/
		}
	}

	&__text-content {
		display: none;

		@mixin mob-xl {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
			width: 100%;
			max-height: 0;
			transition: max-height 0.4s ease, margin 0.4s ease, opacity 0.3s ease;
			opacity: 0;
			margin-top: 0;
			position: relative;
			z-index: 2;

			.btn {
				margin-top: 20px;
			}
		}

		@mixin mob-sm {
			padding-left: 0;
		}
	}

	&__text {
		width: 100%;
		/*max-height: calc(ac(28px, 22px) * 10);

		.simplebar-track.simplebar-vertical {
			width: 2px;
			background: transparent;
			border-radius: 2px;
			transform: translateX(5px);

			.simplebar-scrollbar {
				background: var(--snow);
				border-radius: 2px;

				&:before {
					content: none;
				}
			}
		}*/

		h3 {
			font-size: ac(20px, 18px);
			font-weight: 500;
			line-height: ac(32px, 24px);
			color: var(--dark-blue);
		}
	}
}

.our-sectors-section {
	margin-top: ac(-315px, -120px);
	padding-top: ac(84px, 50px);
	padding-bottom: ac(40px, 24px);
	position: relative;
	z-index: 20;
	&__wrap {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
	}

	&__title {
		margin-bottom: ac(48px, 28px);
		padding-left: ac(108px, 0px);
	}

	&__container {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		align-items: stretch;
		gap: ac(40px, 20px);

		.our-sector-card {
			width: calc(50% - (ac(41px, 21px) / 2));

			@mixin media 951 {
				width: 80%;
			}

			@mixin mob-xl {
				width: 100%;
			}
		}
	}

	@mixin mob-xl {
		margin-top: -60px;
	}

	+ .opportunities-sections.candidate-page {
		margin-top: ac(-220px, -120px);
	}
}
