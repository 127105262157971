.gallery-image-slider {
	overflow: visible;
	width: 100%;
	max-width: ac(458px, 390px);
	margin-left: 0;

	&__item {
		width: ac(305px, 260px);
		height: ac(305px, 260px);
		filter: drop-shadow(4px 4px 8px rgba(0, 0, 0, 0.07))
			drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.08));
		border-radius: 20px;

		.image {
			width: 100%;
			height: 100%;
			clip-path: inset(0 0 0 0 round 20px);

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				transition: transform 0.25s ease;
			}
		}

		&.horizontal-rectangular-type {
			width: ac(458px, 390px);
			height: ac(305px, 260px);

			@mixin mob {
				width: 91.73vw;
			}
		}

		&.vertical-rectangular-type {
			width: ac(244px, 208px);
			height: ac(305px, 260px);
		}

		&:hover {
			img {
				transform: scale(1.04);
			}
		}
	}

	@mixin mob {
		max-width: 91.73vw;
	}
}

.gallery-slider-buttons {
	.slider-btn {
	}
}
