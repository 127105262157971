.questions-section {
	padding-top: ac(120px, 60px);
	padding-bottom: 20px;
	overflow: hidden;
	background: var(--dark);

	.big-cont {
		position: relative;
	}

	&__wrap {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
	}

	&__top {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
		gap: 18px;
		padding-left: ac(80px, 0px);
		padding-right: ac(140px, 40px);
		margin-bottom: ac(48px, 28px);
	}
}

.questions-tab {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;

	&__item {
		width: 100%;
		overflow: hidden;
		transition: max-height 0.3s ease 0.35s, margin-bottom 0.3s ease 0.35s,
			padding 0.3s ease 0.35s, border-color 0.3s ease;
		/*transition-delay: 0.35s;*/
		border: 1px solid #ffffff;
		filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.16));
		border-radius: 20px;
		padding-left: ac(40px, 20px);
		padding-right: ac(140px, 80px);
		padding-top: ac(40px, 20px);
		padding-bottom: ac(36px, 20px);
		position: relative;

		&:not(:last-child) {
			margin-bottom: ac(20px, 16px);
		}

		&.active {
			padding-top: ac(50px, 20px);
			padding-bottom: ac(48px, 20px);
			.questions-tab__title {
				color: var(--white);
			}

			.questions-tab__icon {
				color: var(--white);
				transform: translateY(-50%) translateX(ac(-40px, 20px));
			}

			.questions-tab__text-content {
				margin-top: 16px;
				max-height: 400px;
				opacity: 1;
			}
		}

		&:not(.active) {
			cursor: pointer;

			&:hover {
				border-color: var(--blue);
			}
		}

		@mixin mob-xl {
			max-height: none;
		}
	}

	&__title-btn-container {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	&__title {
		font-size: ac(28px, 22px);
		font-weight: 400;
		line-height: ac(44px, 28px);
		display: flex;
		align-items: center;
		justify-content: flex-start;
		color: var(--dark-grey);
		transition: color 0.3s ease;
		flex: 1;
	}

	&__btn {
		width: ac(28px, 24px);
		min-width: ac(28px, 24px);
		height: ac(28px, 24px);
		color: var(--blue);
		display: flex;
		justify-content: center;
		align-items: center;
		transition: all 0.3s ease;
		cursor: pointer;
		position: relative;
		margin-left: 0;
		margin-right: ac(20px, 18px);

		i {
			position: absolute;
			font-size: ac(28px, 24px);
			transition: all 0.3s ease;
			top: 50%;
			left: 50%;
			transform: translateX(-50%) translateY(-50%);

			&.icon-minus-btn {
				opacity: 0;
			}
			&.icon-plus-btn {
				opacity: 1;
			}
		}

		&:hover {
			color: var(--green);
		}

		&.active {
			color: var(--dark-grey);
			margin-right: ac(36px, 18px);
			margin-left: ac(7px, -0px);
			i {
				&.icon-minus {
					opacity: 1;
				}
				&.icon-plus {
					opacity: 0;
				}
			}
		}
	}

	&__icon {
		position: absolute;
		top: 50%;
		right: 26px;
		transform: translateY(-50%);
		font-size: ac(66px, 42px);
		transition: all 0.3s ease;
		transition-delay: 0.3s;
		color: var(--dark-grey);
		z-index: 3;
	}

	&__text-content {
		margin-left: calc(ac(36px, 18px) + ac(28px, 24px) + ac(7px, -0px));
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		width: 100%;
		max-width: ac(738px, 460px);
		max-height: 0;
		transition: max-height 0.4s ease, margin 0.4s ease, opacity 0.3s ease;
		opacity: 0;
		margin-top: 0;
		position: relative;
		z-index: 2;
	}

	&__text {
		width: 100%;
		/*max-height: calc(ac(28px, 22px) * 10);

        .simplebar-track.simplebar-vertical {
            width: 2px;
            background: transparent;
            border-radius: 2px;
            transform: translateX(5px);

            .simplebar-scrollbar {
                background: var(--snow);
                border-radius: 2px;

                &:before {
                    content: none;
                }
            }
        }*/

		&.small-content-element {
			p:not(:last-child) {
				margin-bottom: 10px;
			}
		}
	}
}
