.clients-slider {
	width: 100%;
	max-width: 100%;
	overflow: visible;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	position: relative;

	&:not(.swiper-initialized) {
		.swiper-wrapper {
			display: flex;
			justify-content: center;
			align-items: flex-start;
			flex-wrap: wrap;
			grid-row-gap: ac(40px, 32px);
			grid-column-gap: ac(20px, 16px);

			.client-logo-card {
				width: ac(240px, 200px);

				@mixin media 1439 {
					width: ac(220px, 200px);
				}
			}

			@mixin tab-sm {
				flex-wrap: nowrap;
			}
		}
	}

	&.swiper-initialized {
		/*display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
		max-width: 100%;
		width: 100%;*/

		&.swiper-grid-column > .swiper-wrapper {
			max-height: ac(400px, 300px);

			@mixin tab-md {
				max-height: 400px;
			}

			@mixin tab-sm {
				max-height: 320px;
			}

			@mixin mob-xl {
				max-height: 62vw;
			}
		}

		/*@mixin media 420 {
			max-width: 80%;
		}*/
	}
}

.clients-slider-buttons {
	justify-content: center;
	.slider-btn {
		margin-top: ac(32px, 24px);

		&.swiper-button-lock {
			display: none;
		}
	}

	/*@mixin min-media 902 {
		display: none;
	}*/
}

.line-clients-slider {
	width: 100%;
	max-width: 100%;
	overflow: visible;

	.client-logo-card {
		opacity: 0.5;

		&:hover {
			opacity: 1;
		}
	}

	@mixin mob {
		max-width: 80%;
	}
}
