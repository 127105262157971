.our-values-slider {
    width: 100%;
    max-width: 100%;
    overflow: visible;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;

    &:not(.swiper-initialized) {
        .swiper-wrapper {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-wrap: wrap;
            grid-row-gap: ac(20px, 16px);
            grid-column-gap: ac(20px, 16px);

            .our-values-card {
                width: ac(220px, 200px);
                min-height: ac(220px, 200px);
            }

            @mixin tab-md {
                flex-wrap: nowrap;
            }
        }
    }

    &.work-for-type {
        max-width: 856px;

        &:not(.swiper-initialized) {
            .swiper-wrapper {
                display: flex;
                justify-content: center;
                align-items: flex-start;
                flex-wrap: wrap;
                grid-row-gap: ac(20px, 16px);
                grid-column-gap: 2.34%;

                .our-values-card {
                    width: 31.75%;
                    min-height: 272px;
                }

                @mixin tab-md {
                    flex-wrap: nowrap;
                }
            }
        }

        .our-values-card {
            height: auto;
            overflow: hidden;

            @media (max-width: 640px) {
                padding-top: 20px;
                padding-bottom: 20px;
                padding-left: 14px;
                padding-right: 14px;
            }

            &:hover {
                .our-values-card__hidden {
                    opacity: 1;
                    pointer-events: auto;
                    max-height: 180px;
                }
            }

            h3 {
                font-size: 22px;
                line-height: 32px;
                font-weight: 500;

                &:not(:last-child) {
                    margin-bottom: 8px;
                }
            }
        }

        .our-values-card__hidden {
            max-height: 0;
            overflow: hidden;
            z-index: 1;
            opacity: 0;
            pointer-events: none;
            transition: 0.3s;
            background: white;
            border-radius: inherit;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
        }

        .our-values-card__text {
            max-height: 180px;
            width: calc(100% + ac(10px, 5px));
            padding-right: ac(10px, 5px);
            margin-right: ac(-10px, -5px);

            &.content-element {
                p,
                li {
                    font-weight: 300;
                    font-size: 16px;
                    line-height: 1.4;
                }
            }
        }
    }

    &.swiper-initialized {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        max-width: 100%;

        @mixin media 420 {
            max-width: 86%;
        }

        .our-values-card {
            min-height: ac(220px, 200px);
        }
    }

    &.with-text-left-type {
        &:not(.swiper-initialized) {
            .swiper-wrapper {
                .our-values-card {
                    width: ac(197px, 160px);
                    min-height: ac(196px, 160px);
                }

                @mixin media 1439 {
                    .our-values-card {
                        width: ac(190px, 160px);
                        min-height: ac(190px, 160px);
                    }
                }

                @mixin tab-md {
                    flex-wrap: nowrap;
                }
            }
        }

        .our-values-card {
            &__name {
                font-size: ac(16px, 14px);
                font-weight: 500;
                line-height: ac(28px, 22px);
                text-align: center;
            }
        }
    }
}

.our-values-slider-buttons {
    justify-content: center;
    position: relative;
    z-index: 10;
    .slider-btn {
        margin-top: 24px;

        &.swiper-button-lock {
            display: none;
        }
    }

    @mixin min-media 902 {
        display: none;
    }
}
