.our-locations-section {
    padding-top: ac(480px, 140px);
    padding-bottom: ac(900px, 800px);
    position: relative;
    overflow: visible;

    &__bg {
        position: absolute;
        inset: 0;
        bottom: -900px;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
        }
    }

    &__wrap {
        padding-top: ac(80px, 40px);
        padding-bottom: ac(80px, 40px);
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        grid-row-gap: 28px;
        position: relative;
        z-index: 1;

        @media (max-width: 768px) {
            flex-direction: column-reverse;
            align-items: flex-start;
            justify-content: flex-start;
        }
    }

    &__images {
        width: 46.88%;
        display: flex;
        grid-column-gap: 20px;
        max-width: 600px;

        @media (max-width: 768px) {
            width: 100%;
        }
    }

    &__image {
        @mixin aspect-ratio 290, 450;
        flex: 1;
        width: 100%;
        border-radius: 16px;
        overflow: hidden;
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 48.13%;

        @media (max-width: 768px) {
            width: 100%;
        }
    }

    &__title {
        &:not(:last-child) {
            margin-bottom: ac(24px, 20px);
        }
    }

    &__text {
        &.content-element {
            font-size: ac(28px, 20px);
            line-height: 157.14%;
            font-weight: 400;

            p,
            li {
                font-size: ac(28px, 20px);
                line-height: 157.14%;
                font-weight: 400;
            }
        }
    }

    + .our-team-section {
        margin-top: ac(-900px, -800px);
    }
}
