.our-values-card {
	background: #ffffff;
	box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.14);
	border-radius: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	transition: border-radius 0.25s ease;
	padding: ac(20px, 10px);
	cursor: default;

	&__icon {
		@mixin aspect-ratio 1, 1;
		width: ac(60px, 48px);
		margin-bottom: 8px;

		img {
			object-fit: contain;
		}
	}

	&__name {
		font-size: ac(22px, 18px);
		font-weight: 500;
		line-height: ac(32px, 24px);
		text-align: center;
	}

	&__text {
		width: 100%;
		max-height: calc(ac(32px, 24px) * 2.01);

		h3 {
			font-size: ac(22px, 18px);
			font-weight: 500;
			line-height: ac(32px, 24px);
		}

		.simplebar-track.simplebar-vertical {
			width: 2px;
			background: transparent;
			border-radius: 2px;
			transform: translateX(4px);

			.simplebar-scrollbar {
				background: var(--blue);
				border-radius: 2px;

				&:before {
					content: none;
				}
			}
		}
	}

	&:hover {
		border-radius: 20px 0 20px 0;
	}
}
