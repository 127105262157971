.approach-slider {
	width: 100%;
	max-width: 100%;
	overflow: visible;
	clip-path: inset(-1px -1px -1000px 0 round 20px 20px 0 0);
	margin-bottom: ac(111px, 50px);

	.approach-card {
		&:nth-child(2) {
			z-index: 1;

			.approach-card__title {
				position: relative;

				&:before,
				&:after {
					position: absolute;
					top: 0;
					bottom: 0;
					content: "";
					width: 40px;
					background: var(--blue);
				}

				&:before {
					transform: translateX(-99%);
					left: 0;
				}

				&:after {
					transform: translateX(99%);
					right: 0;
				}
			}
		}
	}

	@mixin tab-sm {
		max-width: 300px;
		margin-left: 0;
		clip-path: none;

		.approach-card {
			&:first-child,
			&:last-child {
				.approach-card__title {
					border-radius: 20px 20px 0 0;
				}
			}

			&.swiper-slide-active {
				.approach-card__icon {
					margin-top: ac(24px, 14px);
					max-height: 88px;
				}
			}
		}
	}
}

.approach-slider-buttons {
	.slider-btn {
		margin-top: ac(48px, 14px);
	}
}
