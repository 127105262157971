.default-input {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    label {
        color: var(--white);
        margin-bottom: ac(15px, 20px);
    }

    textarea {
        resize: vertical;
        min-height: 40px;
        height: 40px;
        padding-top: 3px;
    }

    select {
        opacity: 0;
    }

    input,
    select,
    textarea {
        background: transparent;
        border-bottom: 1px solid var(--white);
        width: 100%;
        color: var(--white);
        font-family: var(--font-main);
        font-weight: 300;
        font-size: ac(18px, 16px);
        line-height: ac(32px, 20px);
        /*padding-bottom: 7px;*/
        padding-left: 18px;
        border-radius: 0;
        -webkit-appearance: none;
        transition: border-color 0.3s ease;
        min-height: 40px;
        @mixin transparent-bg-input var(--white);

        &::placeholder {
            color: var(--grey);
        }

        &:focus {
            border-color: var(--blue);
        }
    }

    .choices {
        width: 100%;

        &__inner {
            border: none;
            border-bottom: 1px solid var(--white);
            padding: ac(0px, 7px) 24px 7px 18px;
            border-radius: 0;
            background: transparent;
            min-height: 40px;
            height: 40px;
            font-family: var(--font-main);
            font-weight: 300;
            font-size: ac(18px, 16px);
            line-height: ac(32px, 20px);
        }

        &__placeholder {
            color: var(--grey);
            opacity: 1;
            @mixin max-line-length-one;
        }

        &__list {
            padding: 0;
            z-index: 10;
        }

        &__item--selectable {
            @mixin max-line-length-one;
            color: var(--white);
        }

        &__item--choice {
            width: 100%;
            @mixin max-line-length-one;
            padding-right: 10px !important;
        }

        &[data-type*="select-one"] {
            .choices__button {
                display: none;
            }

            &:after {
                border: none;
                border-bottom: 1px solid var(--white);
                border-right: 1px solid var(--white);
                content: "";
                display: block;
                height: 5px;
                margin-top: -4px;
                pointer-events: none;
                position: absolute;
                right: 16px;
                top: 50%;
                transform-origin: 66% 66%;
                transform: rotate(45deg) scale(2.5);
                transition: all 0.15s ease-in-out;
                width: 5px;
            }
        }

        &[data-type*="select-multiple"] {
            &:after {
                border: none;
                border-bottom: 1px solid var(--white);
                border-right: 1px solid var(--white);
                content: "";
                display: block;
                height: 5px;
                margin-top: -4px;
                pointer-events: none;
                position: absolute;
                right: 16px;
                top: 50%;
                transform-origin: 66% 66%;
                transform: rotate(45deg) scale(2.5);
                transition: all 0.15s ease-in-out;
                width: 5px;
            }
        }

        &.is-open {
            &:after {
                transform: rotate(-135deg) scale(2.5);
            }
        }

        &__list--dropdown,
        .choices__list[aria-expanded] {
            border: 2px solid var(--blue);
            background: var(--dark);
            box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.15);

            .choices__item {
                color: var(--white);
                font-size: ac(18px, 16px);
                font-weight: 500;
            }

            .choices__item--selectable {
                &.is-highlighted {
                    color: var(--green);
                }
            }

            scrollbar-width: thin;
            scrollbar-color: var(--green) var(--grey);
        }

        .choices__list {
            &::-webkit-scrollbar {
                width: 4px;
            }

            &::-webkit-scrollbar-track {
                background: var(--grey);
                border-radius: 0;
            }

            &::-webkit-scrollbar-thumb {
                background: var(--green);
                border-radius: 0;
            }
        }
    }
    .choices[data-type*="select-one"] .choices__input {
        margin: 2px;
        max-width: calc(100% - 4px);
        border-radius: 4px;
        border: 2px solid var(--blue);
    }

    .choices__list--dropdown .choices__item--selectable.is-highlighted,
    .choices__list[aria-expanded] .choices__item--selectable.is-highlighted {
        background: var(--dark);
        color: var(--blue);
    }

    .choices__input--cloned {
        padding-left: 0 !important;
        padding-right: 0 !important;
        border: none !important;
        margin: 0;
        @mixin max-line-length-one;
    }

    &.dark-color {
        label {
            color: var(--dark);
        }
        input,
        select,
        textarea {
            background: transparent;
            border-color: var(--dark);
            color: var(--dark);
            @mixin transparent-bg-input var(--dark);
            &::placeholder {
                color: var(--dark);
            }

            &:focus {
                border-color: var(--green);
            }
        }

        .choices {
            width: 100%;

            &__inner {
                border-color: var(--dark);
            }

            &__placeholder {
                color: var(--dark);
            }

            &__item--selectable {
                color: var(--dark);
            }

            &[data-type*="select-one"] {
                &:after {
                    border-color: var(--dark);
                    right: ac(28px, 16px);
                }
            }

            &[data-type*="select-multiple"] {
                &:after {
                    border-color: var(--dark);
                    right: ac(28px, 16px);
                }
            }

            &__list--dropdown,
            .choices__list[aria-expanded] {
                border-color: var(--dark);
                background: var(--white);
                box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.15);
                z-index: 100;

                .choices__item {
                    color: var(--dark);
                }

                .choices__item--selectable {
                    &.is-highlighted {
                        color: var(--green);
                    }
                }
            }

            .choices__list--multiple {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                max-width: 90%;
                padding-bottom: 5px;
                transform: translateY(2px);
                overflow-x: auto;

                &::-webkit-scrollbar {
                    width: 3px;
                }

                &::-webkit-scrollbar-track {
                    background: var(--transparent);
                    border-radius: 0;
                }

                &::-webkit-scrollbar-thumb {
                    background: var(--dark-blue);
                    border-radius: 0;
                }
            }

            .choices__list--multiple .choices__item {
                border-radius: 4px;
                padding: 4px 6px;
                background-color: var(--green);
                line-height: 1;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                color: var(--white);
                margin-bottom: 0;
                white-space: nowrap;
                width: fit-content;

                .choices__button {
                    display: block;
                    padding: 0;
                    width: 10px;
                    height: 10px;
                    background: var(--dark-blue);
                    clip-path: polygon(
                        20% 0%,
                        0% 20%,
                        30% 50%,
                        0% 80%,
                        20% 100%,
                        50% 70%,
                        80% 100%,
                        100% 80%,
                        70% 50%,
                        100% 20%,
                        80% 0%,
                        50% 30%
                    );
                }
            }
        }
        .choices[data-type*="select-one"] .choices__input {
            border: 2px solid var(--dark);
        }

        .choices__list--dropdown .choices__item--selectable.is-highlighted,
        .choices__list[aria-expanded]
            .choices__item--selectable.is-highlighted {
            background: var(--white);
            color: var(--dark);
        }
    }

    &.border-type {
        input,
        select,
        textarea {
            padding-left: ac(23px, 18px);
            padding-right: ac(23px, 18px);
            min-height: ac(64px, 44px);
            border: 1.5px solid var(--white);
            border-radius: 12px;

            &:focus {
                border-color: var(--blue);
            }
        }

        .choices {
            &__inner {
                display: inline-flex;
                align-items: center;
                min-height: ac(64px, 44px);
                border: 1.5px solid var(--white);
                border-radius: 12px;
                padding: ac(4px, 6px) ac(34px, 22px) ac(4px, 6px) ac(23px, 18px);
                min-height: ac(64px, 44px);
                height: ac(64px, 44px);
                transition: all 0.25s ease;
            }

            &__list--dropdown,
            .choices__list[aria-expanded] {
                border-width: 1.5px;
            }

            &.is-open {
                .choices__inner {
                    border-radius: 12px 12px 0 0;
                }
            }
        }

        &.dark-color {
            input,
            select,
            textarea,
            .choices__inner {
                border-color: var(--dark);

                &:focus {
                    border-color: var(--dark-blue);
                }
            }
        }
    }
}

.default-custom-select {
    display: inline-flex;
    align-items: center;
    min-height: ac(64px, 44px);
    border: 1.5px solid var(--dark);
    border-radius: 12px;
    padding: ac(4px, 6px) ac(34px, 22px) ac(4px, 6px) ac(23px, 18px);
    min-height: ac(64px, 44px);
    height: ac(64px, 44px);
    transition: all 0.25s ease;
    position: relative;
    cursor: pointer;

    &__placeholder {
        font-family: var(--font-main);
        font-weight: 300;
        font-size: ac(18px, 16px);
        line-height: ac(32px, 20px);
    }

    &__list {
        position: absolute;
        bottom: 0;
        left: -1px;
        right: -1px;
        transform: translateY(100%);
        border: 1.5px solid var(--dark);
        background: var(--white);
        box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.15);
        z-index: 100;
        display: none;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        cursor: default;

        li {
            padding: 2px 5px;
            min-height: 40px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }
    }

    &.active {
        border-radius: 12px 12px 0 0;
        .default-custom-select__list {
            display: flex;
        }
    }

    &:after {
        border: none;
        border-bottom: 1px solid var(--dark);
        border-right: 1px solid var(--dark);
        content: "";
        display: block;
        height: 5px;
        margin-top: -4px;
        pointer-events: none;
        position: absolute;
        right: 16px;
        top: 50%;
        transform-origin: 66% 66%;
        transform: rotate(45deg) scale(2.5);
        transition: all 0.15s ease-in-out;
        width: 5px;
    }

    &.active {
        &:after {
            transform: rotate(-135deg) scale(2.5);
        }
    }
}

.default-upload-file {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    width: 100%;
    min-height: ac(48px, 40px);
    border: 1.5px dashed var(--dark-grey);
    border-radius: 4px;
    transition: border-color 0.25s ease;
    cursor: pointer;
    overflow: hidden;

    input {
        position: absolute;
        inset: 0;
        z-index: 2;
        cursor: pointer;
        opacity: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
    }

    &__name {
        font-size: ac(16px, 14px);
        line-height: ac(28px, 22px);
        font-weight: 400;
        color: var(--white);
        cursor: pointer;
    }

    &:hover {
        border-color: var(--blue);
    }

    &.dark-color {
        border-color: var(--dark);

        .default-upload-file__name {
            color: var(--dark);
        }

        &:hover {
            border-color: var(--green);
        }
    }
}

.upload-file-container {
    display: flex;
    justify-content: stretch;
    align-items: flex-end;
}

.sorting-select {
    .choices {
        .choices__list {
            width: max-content;
        }

        .choices__list--single {
            padding-right: 24px;
        }

        .choices__inner {
            background: transparent;
            border: none;
        }

        .choices__item {
            opacity: 1;

            color: var(--dark);

            font-size: 16px;
            font-weight: 500;
        }

        &[data-type*="select-one"] {
            &:after {
                border: none;
                border-bottom: 1px solid var(--dark);
                border-right: 1px solid var(--dark);
                content: "";
                display: block;
                height: 5px;
                /* margin-top: 2px; */
                pointer-events: none;
                position: absolute;
                right: 16px;
                top: 50%;
                transform-origin: 66% 66%;
                transform: rotate(45deg) scale(2);
                transition: all 0.15s ease-in-out;
                width: 5px;
            }
        }

        &.is-open {
            &:after {
                margin-top: -4px;
                transform: rotate(-135deg) scale(2);
            }
        }
    }
}

.checkbox {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;

    .checkbox-box {
        display: flex;
        justify-content: center;
        align-items: center;
        width: ac(24px, 22px);
        height: ac(24px, 22px);
        border: 1.5px solid var(--dark-grey);
        border-radius: 4px;
        margin-right: ac(12px, 4px);
        margin-left: 4px;
        transition: border-color 0.3s ease, background-color 0.3s ease;
        background: transparent;

        i {
            font-size: ac(12px, 11px);
            color: var(--dark-grey);
            opacity: 0;
            transition: opacity 0.25s ease, color 0.3s ease;
        }
    }

    input {
        display: none;
    }

    .checkbox-text {
        font-weight: 300;
        font-size: ac(16px, 14px);
        line-height: ac(28px, 24px);
        font-family: var(--font-main);
        color: var(--dark);

        a {
            font: inherit;
            text-decoration: underline;
            color: var(--blue);
            transition: all 0.25s ease;

            &:hover {
                text-decoration-color: transparent;
            }
        }
    }

    input:checked ~ .checkbox-box {
        border-color: var(--green);
        background: linear-gradient(308.94deg, #79ccf0 14.47%, #4db2a1 98.3%);

        i {
            color: var(--white);
            opacity: 1;
        }
    }

    &.dark-bg {
        .checkbox-box {
            border-color: var(--snow);

            i {
                color: var(--snow);
            }
        }

        input:checked ~ .checkbox-box {
            i {
                color: var(--dark);
            }
        }

        .checkbox-text {
            color: var(--dark-grey);
        }
    }
}

.double-range-container {
    height: 72px;
}

.double-range {
    padding-right: 15px;
    margin-top: 27px;
    margin-bottom: 9px;
    height: 2px;
    border-radius: 0;
    border: none;
    box-shadow: none;
    background: var(--dark);

    .noUi-connect {
        background: linear-gradient(308.94deg, #79ccf0 14.47%, #4db2a1 98.3%);
        margin-right: -4px;
    }

    .noUi-handle {
        background: var(--white);
        width: 16px;
        height: 16px;
        cursor: pointer;
        border-radius: 50%;
        border: 2px solid var(--dark-grey);
        box-shadow: none;
        top: -7px;
        transition: all 0.25s ease;

        &:before,
        &:after {
            content: none;
        }

        &:hover {
            border-color: var(--blue);
        }
    }
    .noUi-horizontal .noUi-handle {
        right: -16px;
    }
}

.double-range-inputs {
    &__container {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .double-range-input {
        background: linear-gradient(308.94deg, #79ccf0 14.47%, #4db2a1 98.3%);
        padding: 1.5px 10px;
        width: 40%;
        height: auto;
        color: var(--white);
        text-align: center;
        font-size: ac(16px, 15px);
        line-height: 28px;
        font-family: var(--font-main);
        font-weight: 400;
        outline: 2px solid transparent;
        transition: border-color 0.25s ease, background-color 0.3s ease,
            color 0.3s ease, outline-color 0.25s ease;
        border-radius: 10px;
        min-height: ac(40px, 36px);
        letter-spacing: -0.25px;

        &:focus {
            border-color: var(--blue);
            outline-color: var(--green);
            color: var(--dark);
            background: var(--white);
        }

        @mixin tab-md {
            width: 44%;
        }
    }

    span {
        flex: 1;
        height: 1px;
        margin-left: ac(16px, 14px);
        margin-right: ac(16px, 14px);
        background: var(--green);
        display: flex;
    }
}

.single-range {
    padding-right: 15px;
    margin-bottom: 0;
    height: 2px;
    border-radius: 0;
    border: none;
    box-shadow: none;
    background: var(--dark);

    .noUi-connect {
        background: linear-gradient(308.94deg, #79ccf0 14.47%, #4db2a1 98.3%);
        margin-right: -2px;
    }

    .noUi-handle {
        background: var(--white);
        width: 16px;
        height: 16px;
        cursor: pointer;
        border-radius: 50%;
        border: 2px solid var(--dark-grey);
        box-shadow: none;
        top: -7px;
        transition: all 0.25s ease;

        &:before,
        &:after {
            content: none;
        }

        &:hover {
            border-color: var(--blue);
        }
    }
}
.single-range-info {
    font-family: var(--font-main);
    font-size: ac(18px, 14px);
    font-weight: 300;
    color: var(--black);
    margin-right: ac(8px, 6px);
    min-width: 80px;
}

.single-range-container {
    display: flex;
    align-items: center;

    .single-range {
        flex: 1;
        order: 2;
    }

    .single-range-info {
        order: 1;
    }
}
