.text-video-section {
    padding-top: 40px;
    padding-bottom: ac(40px, 32px);
    position: relative;
    z-index: 20;

    &__wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;

        @mixin tab-md {
            flex-direction: column;
            justify-content: flex-start;
        }
    }

    &__content {
        width: 40.78%;

        @mixin tab-md {
            width: 100%;
            &:not(:last-child) {
                margin-bottom: ac(32px, 20px);
            }
        }
    }

    &__title {
        &:not(:last-child) {
            margin-bottom: ac(24px, 18px);
        }
    }

    &__subtitle {
        color: var(--dark-blue);
        &:not(:last-child) {
            margin-bottom: ac(32px, 18px);
        }
    }

    &__text {
        &:not(:last-child) {
            margin-bottom: ac(32px, 20px);
        }
    }

    &__video {
        width: 55.47%;
        border-radius: 20px;
        box-shadow: inset 0px -32px 80px rgba(255, 255, 255, 0.5);
        filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.15))
            drop-shadow(4px 4px 8px rgba(0, 0, 0, 0.07))
            drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.08));

        .plyr {
            width: 100%;
            clip-path: inset(0 0 0 0 round 20px);
        }

        .plyr__video-wrapper {
            z-index: 0;
        }

        @mixin tab-md {
            width: 100%;
            &:not(:last-child) {
                margin-bottom: ac(32px, 20px);
            }
        }

        .image {
            clip-path: inset(0 0 0 0 round 20px);
            @mixin aspect-ratio 2, 1.2;
        }
    }

    &.who-we-are-type {
        margin-top: ac(-280px, -120px);

        .text-video-section__content {
            margin-top: ac(52px, 24px);
        }

        @mixin mob-xl {
            margin-top: -20px;

            .text-video-section__content {
                margin-top: 0;
            }
        }
    }

    &.why-us-type {
        margin-top: ac(-280px, -160px);

        .text-video-section__wrap {
            flex-direction: row-reverse;
        }

        .text-video-section__content {
            margin-top: ac(0px, 24px);
        }

        @mixin tab-md {
            margin-top: -40px;
        }

        @mixin mob-xl {
            margin-top: -20px;

            .text-video-section__content {
                margin-top: 0;
            }
        }
    }
}
