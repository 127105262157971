.latest-news-section {
	padding-top: ac(220px, 140px);
	padding-bottom: 0;
	overflow: visible;

	&:before {
		position: absolute;
		content: "";
		z-index: 3;
		top: -2px;
		left: 0;
		width: 100%;
		height: 19.44vw;
		background-image: url("../images/decors/wave-white-news-cleints.svg");
		background-size: 100% 100%;
		background-repeat: no-repeat;
		transform: scale(1.02);
		pointer-events: none;
	}

	&:after {
		position: absolute;
		content: "";
		z-index: 2;
		inset: 0;
		background: linear-gradient(92.46deg, #79ccf0 2.43%, #4db2a1 98.48%);
		mix-blend-mode: multiply;
		pointer-events: none;
	}

	&__decor {
		@mixin aspect-ratio 2126, 2360;
		overflow: visible;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translateX(-43%) translateY(-59%) rotate(166deg);
		width: 147.64%;
		z-index: 4;
		pointer-events: none;
		clip-path: inset(0 0 60% 0);
	}

	&__image {
		pointer-events: none;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		height: ac(330px, 200px);
		opacity: 0.3;
		box-shadow: 0 -10px 10px 0 rgba(0, 207, 255, 0.1);
		mask-image: linear-gradient(
			to top,
			transparent,
			black 0%,
			black 50%,
			transparent
		);

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			filter: grayscale(100%) brightness(110%);
		}

		&:before {
			content: "";
			position: absolute;
			inset: 0;
			background: linear-gradient(
				92.46deg,
				rgba(121, 204, 240, 0.7) 2.43%,
				rgba(77, 178, 161, 0.7) 98.48%
			);
			z-index: 1;
			mix-blend-mode: color;
			pointer-events: none;
		}
	}

	&__wrap {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
		position: relative;
		z-index: 5;
	}

	&__top {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-bottom: ac(48px, 24px);
		position: relative;
		z-index: 1;
		max-width: ac(1034px, 880px);

		.title {
			max-width: ac(380px, 300px);
		}

		@mixin tab-md {
			max-width: 100%;
		}

		@mixin mob-xl {
			display: flex;
			flex-direction: column;
			align-items: flex-start;

			.buttons {
				margin-top: 22px;
				margin-left: auto;
				flex-wrap: wrap;

				.btn:not(:last-child) {
					margin-bottom: 8px;
				}
			}
		}
	}

	.news-slider {
		width: 100%;
	}

	@mixin mob-xl {
		padding-top: 90px;
	}

	&.clients-page {
		padding-top: ac(215px, 140px);

		@mixin min-media 2200 {
			padding-top: 260px;
		}
	}

	&.hiring-solutions-page {
		padding-top: ac(64px, 40px);
		background: transparent;
		&:before {
			content: none;
		}

		&:after {
			content: none;
		}
	}

	&.candidate-page {
		padding-top: ac(88px, 40px);
		background: transparent;
		&:before {
			content: none;
		}

		&:after {
			content: none;
		}
	}
}

.clients-news-container {
	position: relative;
	z-index: 10;
	&:before {
		position: absolute;
		left: 0;
		top: ac(-10px, 0px);
		width: 100%;
		content: "";
		background-image: url("../images/decors/wave-white-cleints-hiring.svg");
		background-repeat: no-repeat;
		background-size: 100% 100%;
		height: 12.08vw;
		z-index: 3;
		transform: scale(1.02);
		pointer-events: none;
	}

	&:after {
		position: absolute;
		content: "";
		z-index: 2;
		inset: 0;
		background: linear-gradient(308.94deg, #79ccf0 14.47%, #4db2a1 98.3%);
		mix-blend-mode: multiply;
		pointer-events: none;
	}

	&__image {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		height: ac(330px, 200px);
		opacity: 0.3;
		box-shadow: 0 -10px 10px 0 rgba(0, 207, 255, 0.1);
		pointer-events: none;
		mask-image: linear-gradient(
			to top,
			transparent,
			black 0%,
			black 50%,
			transparent
		);

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			filter: grayscale(100%) brightness(110%);
		}

		&:before {
			content: "";
			position: absolute;
			inset: 0;
			background: linear-gradient(
				92.46deg,
				rgba(121, 204, 240, 0.7) 2.43%,
				rgba(77, 178, 161, 0.7) 98.48%
			);
			z-index: 1;
			mix-blend-mode: color;
			pointer-events: none;
		}
	}
}

.cv-news-container {
	position: relative;
	z-index: 10;
	&:after {
		position: absolute;
		content: "";
		z-index: 2;
		inset: 0;
		background: linear-gradient(92.46deg, #79ccf0 2.43%, #4db2a1 98.48%);
		clip-path: url("#cv-news-clip-path");
		pointer-events: none;

		@mixin tab {
			clip-path: url("#cv-news-clip-path-tab");
		}

		@mixin mob-xl {
			clip-path: url("#cv-news-clip-path-mob");
		}
	}
}
