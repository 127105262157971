.main-news {
	overflow: visible;

	padding-bottom: ac(118px, 40px);

	&__card {
		overflow: hidden;
		z-index: 10;
		position: relative;
		display: flex;

		min-height: 480px;

		margin-top: -309px;

		border-radius: 20px;
		background: var(--white);
		box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15), 4px 4px 8px rgba(0, 0, 0, 0.07),
			0px 2px 4px rgba(0, 0, 0, 0.08);

		@mixin media 769 {
			flex-direction: column;
			min-height: auto;
		}
	}

	&__photo-wrap {
		overflow: hidden;

		width: 50%;
		height: auto;

		border-radius: 20px;

		filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.15))
			drop-shadow(4px 4px 8px rgba(0, 0, 0, 0.07))
			drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.08));
		box-shadow: inset 0px -32px 80px rgba(255, 255, 255, 0.5);

		@mixin media 769 {
			width: 100%;
			height: 250px;
		}
	}

	&__photo {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	&__text-wrap {
		width: 50%;
		padding: ac(68px, 30px) ac(52px, 30px) ac(50px, 30px) ac(48px, 30px);

		@mixin media 769 {
			width: 100%;
		}
	}

	h2&__title {
		margin-bottom: ac(42px, 20px);

		color: var(--dark);

		font-size: ac(32px, 25px);
		font-weight: 500;
		line-height: 1;
	}

	&__subtitle {
		margin-bottom: ac(12px, 8px);

		color: var(--dark-blue);

		font-weight: 500;
		font-size: ac(20px, 18px);
	}

	&__short-text {
		margin-bottom: ac(32px, 16px);

		font-size: ac(16px, 14px);
		font-weight: 300;
		line-height: 1.75;
	}
}
