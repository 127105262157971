.sectors-slider {
	width: 100%;
	max-width: 980px;
	overflow: visible;
	margin-left: 0;
	opacity: 0;
	transition: opacity 0.1s ease;
	user-select: none;

	&.swiper-initialized {
		opacity: 1;
	}
}
