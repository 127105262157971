.testimonials-slider {
	width: 100%;
	max-width: ac(740px, 400px);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	overflow: visible;
	opacity: 0;
	transition: opacity 0.1s ease;
	transform: translateX(ac(10px, 0px));
	user-select: none;

	&.swiper-initialized {
		opacity: 1;
	}

	@mixin mob {
		max-width: 91.73%;
	}
}

.testimonials-slider-buttons {
	display: flex;
	justify-content: center;
	align-items: center;
	transform: translateY(ac(20px, 22px));
	position: relative;
	z-index: 4;

	.slider-btn {
		margin-top: ac(30px, 14px);
	}
}
