@font-face {
	font-family: "icomoon";
	src: url("../fonts/icomoon.eot?9drm7p");
	src: url("../fonts/icomoon.eot?9drm7p#iefix") format("embedded-opentype"),
		url("../fonts/icomoon.ttf?9drm7p") format("truetype"),
		url("../fonts/icomoon.woff?9drm7p") format("woff"),
		url("../fonts/icomoon.svg?9drm7p#icomoon") format("svg");
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: "icomoon" !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-question:before {
	content: "\e910";
}
.icon-twitter:before {
	content: "\e905";
}
.icon-facebook:before {
	content: "\e907";
}
.icon-arrow-pagination-next:before {
	content: "\e906";
}
.icon-arrow-pagination-prev:before {
	content: "\e90f";
}
.icon-arrow-crumbs:before {
	content: "\e903";
}
.icon-check-2:before {
	content: "\e908";
}
.icon-check:before {
	content: "\e909";
}
.icon-minus:before {
	content: "\e90a";
}
.icon-plus:before {
	content: "\e90b";
}
.icon-phone:before {
	content: "\e90c";
}
.icon-linkedin:before {
	content: "\e90d";
}
.icon-email:before {
	content: "\e90e";
}
.icon-arrow-next:before {
	content: "\e901";
}
.icon-arrow-prev:before {
	content: "\e902";
}
.icon-arrow-btn:before {
	content: "\e900";
}
.icon-close:before {
	content: "\e904";
}
