.clients-section {
	padding-top: ac(354px, 180px);
	padding-bottom: ac(78px, 50px);
	overflow: visible;
	position: relative;
	z-index: 8;
	clip-path: inset(-400px 0 -1000px 0);

	&__bg {
		position: absolute;
		inset: 0;
		z-index: -2;
		background: var(--gradient-internal-main);

		img {
			width: 100%;
			height: 100%;
			filter: grayscale(100%);
			mask-image: linear-gradient(
				to top,
				transparent,
				black 0%,
				black 50%,
				transparent
			);
		}

		&:after {
			content: "";
			position: absolute;
			inset: 0;
			z-index: 1;
			background: linear-gradient(
				92.46deg,
				rgba(121, 204, 240, 0.7) 2.43%,
				rgba(77, 178, 161, 0.7) 98.48%
			);
		}
	}

	&__decor {
		@mixin aspect-ratio 2126, 2360;
		overflow: visible;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translateX(-43%) translateY(-59%) rotate(166deg);
		width: 147.64%;
		z-index: -1;
		pointer-events: none;
	}

	&__wrap {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		position: relative;
		z-index: 2;
	}

	&__title {
		color: var(--white);
		&:not(:last-child) {
			margin-bottom: ac(48px, 24px);
		}
	}

	@mixin media 380 {
		padding-bottom: 90px;
	}

	&.about-us-page {
	}

	&.company-information-page {
		margin-top: ac(-34px, 0px);
		padding-top: ac(174px, 120px);
		padding-bottom: ac(80px, 50px);
		clip-path: url("#our-clients-clip-path");
		background: var(--gradient-internal-main);
		max-width: 100vw;

		@mixin tab-md {
			clip-path: url("#our-clients-clip-path-tab-md");
		}
	}

	&.line-clients-type {
		padding-top: ac(42px, 28px);
		padding-bottom: ac(80px, 40px);
	}

	&.hiring-solutions-page {
		padding-top: ac(96px, 40px);
		padding-bottom: ac(52px, 24px);
		clip-path: none;
		overflow: hidden;

		.clients-section__wrap {
			z-index: 4;
		}

		.clients-section__title {
			color: var(--dark);
		}
	}
}
