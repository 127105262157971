/* ----------------------- Common desktop header ----------------------- */
.header {
	position: absolute;
	transition: background ease-in-out 0.25s, height ease-in-out 0.25s;
	width: 100%;
	top: 0;
	left: 0;
	right: 0;
	z-index: 999;
	/*height: ac(120px, 75px);*/
	padding: ac(40px, 16px) 0;
	display: flex;
	align-items: center;

	&.absolute {
		position: absolute;
	}

	&.fixed {
		position: fixed;
	}

	&.scrolled {
		background: rgba(var(--black-rgb), 0.8);
		backdrop-filter: blur(10px);
		height: ac(100px, 60px);

		.logo {
			width: ac(130px, 60px);
		}
	}

	.logo {
		height: 100%;
		width: ac(230px, 160px);
		flex-shrink: 0;
		transition: all 0.25s ease;
		position: relative;
		z-index: 9999;

		&::before {
			display: none;
		}

		&:hover {
			transform: scale(1.06);
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
			object-position: left;
		}
	}

	.navbar {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.burger {
		display: none;
	}

	.navbar-nav {
		display: flex;
		flex-grow: 1;
	}

	.menu {
		width: 100%;
		display: flex;
		justify-content: flex-end;
	}

	.menu-item {
		margin-right: 20px;
		position: relative;

		&.dropdown {
			& > .menu-link {
				display: inline-flex;
				justify-content: space-between;
				align-items: center;

				&::after {
					content: "\e999";
					font-family: "icomoon";
					font-size: ac(10px, 8px);
					padding-left: 10px;
				}
			}
		}
	}

	&:not(.header-mobile) {
		.menu-item {
			@media (--mobile-menu-end-point) {
				padding: 5px 0;
			}

			&:hover {
				@media (--mobile-menu-end-point) {
					.dropdown-menu {
						display: block;
					}
				}
			}
		}
	}

	.menu-link {
		position: relative;
		font-size: ac(16px, 14px);
		@mixin transition-all;
		color: var(--white);

		/*&::before {
			content: "";
			position: absolute;
			bottom: 0;
			right: 0;
			width: 0;
			height: 2px;
			background: var(--white);
			@mixin transition-all;
		}*/

		&:hover {
			color: color-mod(var(--white) a(40%));

			/*&::before {
				width: 100%;
				left: 0;
				background: var(--primary);
			}*/
		}
	}

	.dropdown-menu {
		position: absolute;
		top: 100%;
		left: 0;
		min-width: 100%;
		padding: 10px 10px 10px 15px;
		border-radius: 10px;
		background: var(--bg-second);
		box-sizing: content-box;
		display: none;

		li {
			.menu-link {
				padding: 5px 0;
				text-transform: capitalize;
			}
		}
	}

	.burger-text {
		margin-left: auto;
		margin-right: ac(10px, 12px);
		font-family: var(--font-main);
		color: var(--dark);
		font-size: 16px;
		font-weight: 500;
		line-height: 1;
		transition: all 0.25s ease;
		cursor: pointer;
		position: relative;
		z-index: 10000;

		span {
			&.with-open {
				display: none;
			}
		}

		&.active {
			color: var(--asphalt);

			span {
				display: none;
				&.with-open {
					display: block;
				}
			}
		}
	}
}

/* end of Common desktop header */

/* ----------------------- Common desktop header Mobile HEADER ----------------------- */

@media (--mobile-menu-start-point) {
	.header:not(.header-mobile) {
		.burger,
		.menu-toggle {
			display: flex;
		}

		.navbar-nav {
			position: fixed;
			right: 0;
			top: 0;
			width: 50%;
			height: 100vh;
			height: calc(var(--vh, 1vh) * 100);
			min-width: 320px;
			max-width: 520px;
			padding-top: 100px;
			background: var(--dark);
			padding-right: ac(80px, 40px);
			padding-left: ac(80px, 40px);
			padding-bottom: 40px;
			transition: transform 0.3s ease-out, opacity 0.1s ease;
			flex-direction: column;
			align-items: flex-start;
			justify-content: center;
			box-shadow: -25px 0px 35px 0px rgba(0, 0, 0, 0.25);
			opacity: 0;

			@mixin mob-lg {
				padding-top: 160px;
				padding-bottom: 48px;
				justify-content: flex-start;
				width: 100%;
				padding-left: 16px;
				padding-right: 16px;

				.navbar-nav__links {
					margin-top: auto;
				}
			}

			&.active {
				opacity: 1;
			}

			&.nav-slide-left {
				right: auto;
				left: 0;
				transform: translateX(-100%);

				&.active {
					transform: translateX(0);
				}
			}

			&.nav-slide-right {
				right: 0;
				left: auto;
				transform: translateX(100%);

				&.active {
					transform: translateX(0);
				}
			}

			&.nav-slide-down {
				right: auto;
				left: 0;
				transform: translateY(-100%);

				&.active {
					transform: translateY(0);
				}
			}

			&.nav-slide-up {
				right: auto;
				left: 0;
				transform: translateY(100%);

				&.active {
					transform: translateY(0);
				}
			}

			.menu {
				width: 100%;
				max-height: 100%;
				overflow-y: auto;
				flex-direction: column;
				justify-content: flex-start;
				align-items: flex-start;
				align-self: center;
				margin-bottom: ac(94px, 24px);
				overflow-x: hidden;

				.simplebar-track.simplebar-vertical {
					width: 2px;
					background: transparent;
					border-radius: 2px;
					transform: translateX(0px);

					.simplebar-scrollbar {
						background: var(--green);
						border-radius: 2px;

						&:before {
							content: none;
						}
					}
				}

				&::-webkit-scrollbar {
					width: 3px;
					height: 2px;
					background: rgba(255, 255, 255, 0.1);
					border-radius: 3px;
				}

				&::-webkit-scrollbar-thumb {
					background: rgba(255, 255, 255, 0.5);
					border-radius: 3px;
					cursor: pointer;
				}
			}

			.menu-link {
				padding: ac(9px, 12px) 0;
				font-size: ac(32px, 28px);
				line-height: ac(44px, 28px);
			}

			&__links {
				width: 100%;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: flex-start;
				padding-top: ac(63px, 24px);
				border-top: 1px solid color-mod(var(--white) a(15%));
			}

			&__link {
				font-size: ac(18px, 16px);
				line-height: ac(32px, 16px);
				transition: all 0.25s ease;
				color: var(--white);

				&:not(:last-child) {
					margin-bottom: ac(0px, 12px);
				}

				&:hover {
					color: color-mod(var(--white) a(40%));
				}
			}

			&__socials {
				margin-top: ac(40px, 20px);
				margin-left: ac(-14px, 0px);
			}
		}

		.dropdown-menu {
			position: relative;
			top: 0;
		}
	}
}

/* end of Common desktop header Mobile HEADER */

/* ----------------------- ONLY Mobile HEADER ----------------------- */
.header.header-mobile {
	.burger {
		display: flex;
	}

	.navbar-nav {
		position: fixed;
		right: 0;
		top: 0;
		width: 50%;
		height: 100vh;
		min-width: 320px;
		padding-top: 100px;
		background: var(--bg-second);
		padding-right: 5px;
		padding-bottom: 40px;
		transition: transform 0.3s ease-out;

		&.nav-slide-left {
			right: auto;
			left: 0;
			transform: translateX(-100%);

			&.active {
				transform: translateX(0);
			}
		}

		&.nav-slide-right {
			right: 0;
			left: auto;
			transform: translateX(100%);

			&.active {
				transform: translateX(0);
			}
		}

		&.nav-slide-down {
			right: auto;
			left: 0;
			transform: translateY(-100%);

			&.active {
				transform: translateY(0);
			}
		}

		&.nav-slide-up {
			right: auto;
			left: 0;
			transform: translateY(100%);

			&.active {
				transform: translateY(0);
			}
		}

		.menu {
			width: 100%;
			max-height: 100%;
			overflow-y: auto;
			flex-direction: column;
			justify-content: flex-start;
			padding-left: 40px;
			align-items: flex-start;
			align-self: center;

			&::-webkit-scrollbar {
				width: 3px;
				height: 2px;
				background: rgba(255, 255, 255, 0.1);
				border-radius: 3px;
			}

			&::-webkit-scrollbar-thumb {
				background: rgba(255, 255, 255, 0.5);
				border-radius: 3px;
				cursor: pointer;
			}
		}

		.menu-link {
			padding: 10px 0;
			font-size: ac(25px, 18px);
		}
	}

	.dropdown-menu {
		position: relative;
		top: 0;
	}
}

/* end of ONLY Mobile HEADER */

/* ----------------------- HEADER COMPONENTS ----------------------- */
.burger,
.menu-toggle {
	position: relative;
	flex-direction: column;
	justify-content: center;
	cursor: pointer;
	width: ac(55px, 48px, 375, 1025);
	height: 16px;
	display: flex;

	span {
		width: 100%;
		height: 0;
		background: transparent;
		transition: all 0.1s, width 0.25s ease;
		position: relative;

		&::before,
		&::after {
			position: absolute;
			content: "";
			left: 0;
			width: 100%;
			height: 2px;
			background: var(--dark);

			transition: all 0.1s, width 0.25s ease;
		}

		&::before {
			top: -6px;
			width: 63.64%;
		}

		&::after {
			bottom: -6px;
		}
	}

	@mixin transition-all;

	@mixin min-media 1025 {
		&:hover {
			span {
				&:before {
					width: 100%;
				}

				&:after {
					width: 63.64%;
				}
			}
		}
	}

	&.active {
		height: 40px;

		span {
			background: transparent;

			&::before {
				width: 100%;
				top: 0;
				transform: rotate(-45deg);
				background: var(--asphalt);
			}

			&::after {
				top: 0;
				transform: rotate(45deg);
				background: var(--asphalt);
			}
		}

		&:hover {
			height: 40px;

			span {
				&:before {
					width: 100%;
				}

				&:after {
					width: 100%;
				}
			}
		}
	}
}

.header-close-wrapper {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 77;
	background: rgba(0, 0, 0, 0.5);
	pointer-events: none;
	visibility: hidden;
	opacity: 0;

	@mixin transition-all;

	&.active {
		visibility: visible;
		pointer-events: auto;
		opacity: 1;
		cursor: pointer;
	}
}

/* end of HEADER COMPONENTS */
