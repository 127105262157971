.our-team-section {
    padding-top: ac(330px, 110px);
    position: relative;
    z-index: 10;
    overflow: visible;
    margin-bottom: ac(-220px, -120px);

    &__bg {
        position: absolute;
        z-index: -2;
        left: 0;
        top: 0;
        height: calc(100% - ac(210px, 110px));
        width: 100%;
        background: var(--gradient-internal-main);
        clip-path: url("#our-team-clip-path");

        @mixin media 1300 {
            clip-path: url("#our-team-clip-path-tab");
        }

        @mixin tab-md {
            clip-path: url("#our-team-clip-path-tab-md");
        }
    }

    &__wrap {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
    }

    &__top {
        width: 100%;
        max-width: ac(1034px, 900px);
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: ac(20px, 22px);
        position: relative;
        z-index: 1;
        transform: translateX(ac(-14px, 0px));

        &:not(:last-child) {
            margin-bottom: ac(44px, 32px);
        }

        .buttons {
            margin-top: ac(-10px, 0px);
        }

        @mixin tab {
            max-width: 100%;
        }

        @mixin mob-xl {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .buttons {
                margin-top: 22px;
                flex-wrap: wrap;
                gap: 8px;
            }
        }
    }

    &.join-the-team-page {
        padding-top: ac(80px, 40px);
        margin-bottom: ac(-250px, -160px);

        .our-team-section__top {
            transform: translateX(-15px) translateY(-4px);
            @mixin tab {
                transform: none;
            }
        }

        + .contact-section {
            padding-top: ac(350px, 200px);
        }
    }
}

.meet-the-team-section {
    padding-top: 60px;
    padding-bottom: 35px;
    position: relative;
    margin-top: -232px;
    z-index: 20;
    overflow: visible;

    @mixin tab-md {
        margin-top: -160px;
    }

    @mixin mob-xl {
        margin-top: -60px;
    }

    &:before {
        bottom: 0;
        left: 0;
        width: 100%;
        height: 16.67vw;
        position: absolute;
        content: "";
        transform: translateY(94%) scale(1.02);
        z-index: -1;
        background-image: url("../images/decors/wave-meet-the-team.svg");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        pointer-events: none;
    }

    &__wrap {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }

    &__title {
        padding-left: ac(108px, 20px);

        &:not(:last-child) {
            margin-bottom: ac(48px, 32px);
        }
    }

    &__grid {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-column-gap: ac(20px, 16px);
        grid-row-gap: ac(22px, 16px);
        width: 100%;

        @mixin media 720 {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }
    }

    .pagination {
        margin-left: auto;
        margin-right: auto;
    }

    + .contact-section {
        padding-top: ac(274px, 120px);

        @mixin mob-lg {
            padding-top: 80px;
        }
    }
}
