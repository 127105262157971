.btn {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: var(--white);
    transition: opacity 0.25s ease;

    &__text {
        font-family: var(--font-main);
        font-size: ac(22px, 18px);
        line-height: 181.82%;
        font-weight: 500;

        position: relative;

        &:before {
            position: absolute;
            content: "";
            bottom: ac(-2px, 0px);
            left: 0;
            width: 100%;
            background: var(--white);
            height: 2px;
            transition: width 0.3s ease, transform 0.3s ease;
        }
    }

    &__icon {
        margin-left: ac(11px, 8px);
        font-size: ac(11.2px, 10px);
        transition: transform 0.25s ease;
        /*margin-top: ac(0px, 2px);*/
    }

    &:hover {
        opacity: 0.7;
        .btn__icon {
            transform: translateX(6px);
        }

        .btn__text {
            &:before {
                width: calc(100% + ac(16px, 14px) + ac(33px, 30px));
                /*transform: translateX(calc(ac(16px, 14px) + ac(30px, 24px)));*/
            }
        }
    }

    &.dark-btn {
        color: var(--dark);

        .btn__text {
            &:before {
                background: var(--dark);
            }
        }
    }

    &.small-btn {
        .btn__text {
            font-size: 18px;
        }
    }

    &.back-btn {
        .btn__icon {
            transform: scale(-1, 1);
            margin-right: ac(10px, 8px);
            margin-left: 0;
        }
        &:hover {
            .btn__icon {
                transform: scale(-1, 1) translateX(6px);
            }
            .btn__text {
                &:before {
                    width: calc(100% + ac(16px, 14px) + ac(30px, 24px));
                    transform: translateX(
                        calc(ac(-16px, -14px) + ac(-30px, -24px))
                    );
                }
            }
        }
    }
}

.buttons {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .btn {
        &:not(:last-child) {
            margin-right: ac(50px, 48px);

            @mixin mob-sm {
                margin-right: 20px;
            }
        }
    }
}

.slider-btn {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: ac(60px, 48px);
    height: ac(60px, 48px);
    border-radius: 50%;
    border: 2px solid var(--dark);
    color: var(--dark);
    transition: all 0.25s ease;

    i {
        font-size: ac(20px, 14px);
        transition: all 0.25s ease;
    }

    &:hover {
        i {
            transform: scale(1.2);
        }
    }

    &.white-btn {
        border-color: var(--white);
        color: var(--white);

        &:hover {
            opacity: 0.7;
        }
    }

    &.swiper-button-disabled {
        opacity: 0.5;
        cursor: not-allowed;

        &:hover {
            opacity: 0.5;
            i {
                transform: scale(1);
            }
        }
    }

    &.swiper-button-lock {
        opacity: 0;
        pointer-events: none;
    }
}

.slider-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .slider-btn {
        &:not(:last-child) {
            margin-right: ac(15px, 8px);
        }
    }
}

.socials {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: ac(9px, 8px);

    &__link {
        transition: all 0.25s ease;
        cursor: pointer;
        width: 52px;
        height: 32px;
        position: relative;
        z-index: 2;
        border-radius: 1000px;
        display: flex;
        justify-content: center;
        align-items: center;

        &:before {
            position: absolute;
            inset: 0;
            content: "";
            background: linear-gradient(
                316.58deg,
                #5acdf3 12.17%,
                #073267 81.12%
            );
            z-index: -2;
            border-radius: 1000px;
            transition: all 0.25s ease;
        }

        &:after {
            position: absolute;
            inset: 2px;
            content: "";
            background: var(--white);
            z-index: -1;
            border-radius: 1000px;
            transition: all 0.25s ease;
        }

        i {
            background: linear-gradient(
                316.58deg,
                #5acdf3 12.17%,
                #073267 81.12%
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
            transition: all 0.25s ease;
            font-size: 16px;

            &.icon-phone {
                font-size: 14px;
            }

            &.icon-email {
                font-size: 20px;
            }
        }

        &:hover {
            i {
                opacity: 0.5;
            }
        }

        /*&:not(:last-child) {
			margin-right: ac(16px, 12px);
		}*/
    }

    &.dark-bg {
        .socials__link {
            i {
                background: var(--white);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
            }

            &:before {
                background: linear-gradient(
                    308.94deg,
                    #79ccf0 14.47%,
                    #4db2a1 98.3%
                );
            }
            &:after {
                background: var(--dark);
            }

            &:hover {
                i {
                    opacity: 1;
                    background: var(--blue);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-clip: text;
                    text-fill-color: transparent;
                }
            }
        }
    }
}
