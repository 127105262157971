.open-news-main-img-section {
	position: relative;
	overflow: visible;
	z-index: 11;

	padding: 48px 0;
}

.open-news-main-img {
	position: relative;
	overflow: hidden;

	display: block;
	margin-top: -262px;

	height: ac(500px, 350px);

	border-radius: 20px;

	filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.15))
		drop-shadow(4px 4px 8px rgba(0, 0, 0, 0.07))
		drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.08));

	@mixin media 651 {
		height: 275px;
	}

	&::before {
		content: "";
		position: absolute;
		inset: 0;
		z-index: 1;

		box-shadow: inset 0px -32px 80px rgba(255, 255, 255, 0.5);
	}

	img {
		width: 100%;
		height: 100%;

		object-fit: cover;
	}
}

.open-news-main-content {
	padding-top: ac(43px, 25px);
	padding-bottom: ac(80px, 40px);

	&__inner-wrap {
		display: flex;
		gap: ac(111px, 20px);
		margin-bottom: ac(32px, 20px);
	}

	.content-element.article {
		flex-grow: 1;
		width: 100%;
	}

	&__contents {
		flex-grow: 1;
		width: 100%;
		max-width: ac(369px, 279px);

		@mixin media 769 {
			display: none;
		}

		&-title {
			margin-bottom: ac(40px, 15px);

			font-size: ac(32px, 24px);
			font-weight: 500;
			line-height: 1.44;
		}
	}

	&__contents-inner-wrap {
		position: sticky;
		top: 20px;
		margin-bottom: 20px;

		padding: ac(40px, 20px) 0 ac(64px, 30px) ac(40px, 20px);
		border-left: 1px solid var(--main);
	}

	&__contents-anchors {
		a {
			display: block;

			transition: setTransition(color);

			&:hover {
				color: var(--main);
			}
		}

		.lvl1 {
			&:not(:first-child) {
				margin-top: ac(24px, 16px);
			}
			&:not(:last-child) {
				margin-bottom: ac(24px, 16px);
			}

			font-size: ac(20px, 18px);
			font-weight: 500;
			line-height: 1.6;
		}

		.lvl2 {
			&:not(:last-child) {
				margin-bottom: ac(16px, 10px);
			}

			padding-left: ac(16px, 10px);

			font-size: ac(16px, 14px);
			font-weight: 500;
			line-height: 1.75;
		}

		.lvl3 {
			&:not(:last-child) {
				margin-bottom: ac(16px, 10px);
			}

			padding-left: ac(32px, 20px);

			font-size: ac(14px, 12px);
			font-weight: 500;
			line-height: 1.75;
		}
	}

	&__back-link {
		width: fit-content;
		margin-bottom: ac(72px, 32px);
	}
}
