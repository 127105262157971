.news-card {
	background: var(--white);
	overflow: hidden;
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.33),
		inset 0px 0px 2px 0px rgba(0, 0, 0, 0.02);
	border-radius: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	height: auto;
	transition: border-radius 0.3s ease;

	&__image {
		width: 101%;
		@mixin aspect-ratio 413, 275;
		clip-path: url("#news-card-clip-path");
		transform: scale(1.01);

		img {
			transition: transform 0.3s ease;
		}
	}

	&__content {
		width: 100%;
		padding-top: ac(12px, 16px);
		padding-bottom: ac(42px, 32px);
		padding-left: ac(32px, 20px);
		padding-right: ac(40px, 20px);
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		flex: 1;
	}

	&__text {
		width: 100%;
		@mixin max-line-length 6;
		margin-bottom: ac(32px, 24px);

		+ .btn {
			margin-top: auto;
		}

		h3 {
			font-size: ac(22px, 18px);
			font-weight: 500;
			line-height: ac(36px, 24px);

			&:not(:last-child) {
				margin-bottom: 10px;
			}
		}
	}

	&:hover {
		border-radius: 0 20px 0 20px;

		img {
			transform: scale(1.05) rotate(2deg);
		}
	}
}
