.project-popup {
	padding: 0;
	max-width: 760px;
	width: 100%;
	max-height: 92vh;
	overflow: hidden;
	background: var(--dark);
	box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.14);
	border-radius: 20px;

	.popup__title {
		font-family: var(--font-main);
		font-size: ac(34px, 24px);
		font-weight: 700;
		line-height: ac(44px, 30px);
		color: var(--white);
		padding-right: ac(78px, 60px);
		padding-bottom: ac(24px, 20px);
		margin-bottom: 0;
		width: calc(100% - 30px);
		z-index: 2;
		display: flex;
		align-items: center;
		padding-top: ac(24px, 20px);
		padding-left: ac(40px, 24px);
		text-transform: none;

		@mixin mob {
			padding-right: 40px;
		}
	}

	.popup__btn-close {
		top: ac(30px, 20px);
		right: ac(30px, 20px);
		z-index: 10;
		width: ac(22px, 18px);
		height: ac(22px, 18px);
		border-radius: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		transition: all 0.25s ease;

		&:before {
			font-family: "icomoon" !important;
			speak: never;
			font-style: normal;
			font-weight: normal;
			font-variant: normal;
			text-transform: none;
			line-height: 1;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			content: "\e904";
			font-size: ac(22px, 18px);
			color: var(--white);
			transition: all 0.25s ease;
		}

		.cross-clip {
			display: none;
		}

		&:hover {
			&:before {
				color: var(--blue);
			}
		}
	}

	.popup__container {
		position: relative;
		background: var(--dark);
	}

	.popup__content {
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;
		padding: ac(32px, 20px) ac(40px, 24px) ac(56px, 40px);
	}

	&__content {
		padding-right: 10px;
		width: 100%;
		/*scrollbar-width: thin;
        scrollbar-color: var(--blue) var(--light-grey);*/
		max-height: 66vh;
		max-height: 66vh;
		max-height: calc(var(--vh, 1vh) * 66);
		/*overflow-y: auto;*/
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		position: relative;
		.simplebar-track.simplebar-vertical {
			width: ac(16px, 12px);
			background: #575756;
			border-radius: 72px;
			transform: translateX(ac(20px, 16px));
			.simplebar-scrollbar {
				background: var(--dark-grey);
				border-radius: 80px;
				left: 2px;
				right: 2px;
				/*box-shadow: inset 0 4px 0 0 var(--grey), inset 0 -4px 0 0 var(--grey);*/

				&:before {
					content: none;
				}
			}
		}

		.form {
			&__grid {
				display: grid;
				grid-template-columns: repeat(2, minmax(0, 1fr));
				grid-column-gap: ac(24px, 12px);
				grid-row-gap: ac(24px, 12px);

				@mixin mob-xl {
					grid-template-columns: repeat(1, minmax(0, 1fr));
				}
			}

			&__bottom {
				margin-top: ac(20px, 16px);
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: flex-start;
				padding-bottom: 10px;

				.checkbox {
					width: 100%;

					/*.checkbox-text a {
						color: var(--dark-grey);

						&:hover {
							color: var(--blue);
						}
					}*/
				}

				.checkbox .checkbox-text {
					flex: 1;
				}

				.btn {
					margin-top: 20px;
					margin-left: auto;
					margin-right: auto;
				}
			}

			.default-input {
				&.with-full {
					grid-column: 1/-1;
				}

				.default-popup-file-input {
					padding-bottom: 0;
				}
			}
		}
	}

	@mixin tab-sm {
		width: 100%;
		max-width: 91vw;
	}
}
