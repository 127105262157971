.job-card {
    background: var(--dark);
    padding-top: ac(42px, 30px);
    padding-left: ac(50px, 32px);
    padding-right: ac(50px, 32px);
    padding-bottom: ac(44px, 30px);
    border-radius: 20px;
    transition: border-radius 0.25s ease, box-shadow 0.25s ease;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    &__title {
        max-width: 100%;
        overflow: hidden;
        @mixin max-line-length 4;

        span {
            white-space: nowrap;
            width: 100%;
            display: flex;
            line-height: 1.2;
        }

        &:not(:last-child) {
            margin-bottom: ac(12px, 14px);
        }
    }

    &__parameters {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        &:not(:last-child) {
            margin-bottom: ac(18px, 10px);
        }

        li {
            color: var(--blue);
            font-size: 16px;
            font-weight: 500;
            line-height: ac(30px, 18px);
            margin-bottom: ac(0px, 8px);

            &:not(:last-child) {
                margin-right: ac(20px, 12px);
            }
        }
    }

    &__content {
        position: relative;
        padding-left: ac(55px, 44px);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        flex: 1;

        .content-element {
            @mixin max-line-length 6;
            &:not(:last-child) {
                margin-bottom: ac(20px, 16px);
            }
        }

        .btn {
            margin-top: auto;
        }
    }

    &__icon {
        position: absolute;
        top: ac(6px, 2px);
        left: 0;
        width: ac(40px, 32px);
        height: ac(40px, 32px);
        clip-path: inset(-4px);

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    &:hover {
        border-radius: 30px 0 30px 0;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.14),
            0px 6px 10px rgba(0, 0, 0, 0.09);
    }
}
