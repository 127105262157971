.privacy-section {
    padding-top: 0;
    padding-bottom: ac(80px, 64px);
    &__top {
        padding-top: ac(210px, 140px);
        padding-bottom: ac(90px, 64px);
        background: var(--dark);
        margin-bottom: ac(40px, 32px);
        clip-path: url("#first-clip-path-our-sectors");
        @mixin mob-xl {
            clip-path: url("#first-clip-path-mob-our-sectors");
        }
    }

    &__title {
        color: var(--white);
    }
}
