.gallery-section {
	padding-top: ac(40px, 24px);
	padding-bottom: ac(80px, 40px);

	&__wrap {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
	}

	&__bottom-container {
		width: 100%;
		margin-top: ac(48px, 28px);
		display: flex;
		align-items: center;
		justify-content: space-between;

		@mixin mob-lg {
			flex-direction: column-reverse;
			align-items: flex-start;
			margin-top: 24px;

			.gallery-slider-buttons {
				margin-bottom: 24px;
				margin-left: auto;
				margin-right: auto;
			}
		}
	}

	&__content {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		max-width: ac(414px, 320px);
		margin-left: ac(108px, 20px);

		@mixin mob-lg {
			margin-left: 0;
			max-width: 100%;
		}
	}

	&__title {
		&:not(:last-child) {
			margin-bottom: ac(16px, 14px);
		}
	}
}
