.not-found-section {
	width: 100%;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
	background: var(--dark);
	overflow: hidden;

	&__bg {
		position: absolute;
		inset: 0;

		img {
			position: absolute;
			inset: 0;
			width: 100%;
			height: 100%;
			object-fit: contain;
			transition: transform 0.01s linear;
		}

		.decor-mob {
			display: none;
		}

		@mixin tab {
			img {
				display: none;
			}

			.decor-mob {
				display: block;
				position: absolute;
				inset: 0;

				img {
					display: block;
					transform: translateY(0) translateX(10%) scale(1.5);
					opacity: 0.9;
				}
			}
		}
	}

	&__content {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
		z-index: 20;

		.title,
		.subtitle {
			text-align: center;
			margin-bottom: 24px;
			pointer-events: none;
		}
	}
}
