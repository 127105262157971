.job-details-main {
	padding-bottom: 4px;
	z-index: 10;

	margin-top: -189px;

	@mixin media 901 {
		margin-top: 0;
		padding-bottom: 20px;
	}

	.big-cont {
		display: flex;
		justify-content: space-between;
		gap: ac(123px, 30px);

		@mixin media 901 {
			flex-direction: column;
		}
	}

	&__content {
		width: 100%;
	}

	&__content-title {
		margin-bottom: ac(24px, 16px);
	}

	.content-element {
		margin-bottom: ac(22px, 18px);
	}

	&__btns {
		display: flex;
		align-items: center;
		gap: ac(50px, 25px);

		margin-bottom: ac(70px, 30px);
	}

	&__consultant-wrap {
		position: relative;

		width: 100%;
		max-width: ac(357px, 200px);
		flex-shrink: 0;

		@mixin media 769 {
			max-width: unset;
		}
	}
}

.consultant-card {
	position: sticky;
	top: 20px;
	margin-bottom: 80px;

	display: flex;
	flex-direction: column;
	align-items: center;

	border-radius: 20px;
	padding: ac(40px, 20px) ac(48px, 24px);

	box-shadow: 0px 0px 8px 0px #00000024;
	background: var(--white);

	@mixin media 769 {
		position: static;
		margin: 0 auto;
		max-width: 400px;
	}

	&___text-img-wrap {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 43px;

		margin-bottom: ac(44px, 25px);

		@mixin media 769 {
			flex-direction: row;
			gap: ac(32px, 16px, 375, 651);
		}

		@mixin media 374 {
			flex-direction: column;
		}
	}

	&__img-wrap {
		position: relative;
		/* overflow: hidden; */

		width: ac(142px, 100px);
		height: ac(142px, 100px);

		/* margin: 0 auto 32px; */

		&::before {
			content: "";
			position: absolute;

			inset: -4px;
			border-radius: 999px;

			box-shadow: 0px 0px 16px 0px #79ccf080;
			background: linear-gradient(308.94deg, #79ccf0 14.47%, #4db2a1 98.3%);
		}
	}

	&__img {
		position: relative;
		z-index: 1;

		width: 100%;
		height: 100%;
		object-fit: cover;

		border-radius: 999px;
	}

	&___text-wrap {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	&__name {
		margin-bottom: 9px;

		font-size: ac(28px, 20px);
		line-height: 1.33;
	}

	&__position {
		margin-bottom: ac(22px, 12px);

		font-size: ac(16px, 14px);
		font-weight: 500;
		line-height: 1.48;

		background: linear-gradient(316.58deg, #5acdf3 -7.83%, #073267 61.12%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}
}
