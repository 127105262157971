.our-team-slider {
	width: 100%;
	max-width: ac(1064px, 900px);
	margin-left: 0;
	overflow: visible;

	@mixin tab-md {
		max-width: 100%;
	}

	@mixin media 719 {
		max-width: 340px;
	}
}

.our-team-slider-buttons {
	/*display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(ac(20px, 22px));
    position: relative;
    z-index: 4;*/

	.slider-btn {
		margin-top: ac(64px, 24px);
	}
}
