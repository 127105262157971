.history-section {
	padding-top: ac(80px, 40px);
	padding-bottom: ac(80px, 40px);

	&__wrap {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		margin: 0 auto;
		max-width: ac(630px, 400px);

		@mixin mob {
			max-width: 100%;
		}
	}

	&__history-time-slider-container {
		width: 100%;
		padding-top: ac(64px, 32px);
		padding-bottom: ac(64px, 32px);
		position: relative;
		margin-bottom: ac(64px, 32px);

		&:before,
		&:after {
			content: "";
			position: absolute;
			background: var(--dark-grey);
			left: ac(-217px, -100px);
			right: ac(-217px, -100px);
			height: 1px;

			@mixin mob-xl {
				left: -5px;
				right: -5px;
			}
		}

		&:before {
			top: 0;
		}

		&:after {
			bottom: 0;
		}
	}
}
