.stay-in-the-know {
    padding-top: ac(104px, 50px);
    padding-bottom: ac(192px, 70px);
    clip-path: inset(1px 0 0 0);
    /* overflow: visible; */
    overflow: hidden;

    @mixin media 769 {
        padding-top: 16vw;
    }

    @mixin min-media 2200 {
        padding-top: 220px;
    }

    &:before {
        position: absolute;
        content: "";
        z-index: 3;
        top: -2px;
        left: 0;
        width: 100%;
        height: 24.68vw;

        background-image: url("../images/decors/stay-in-the-know-wave.svg");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        /* transform: scale(1.02); */

        @mixin media 769 {
            height: 16vw;
            background-position: right;
            background-size: contain;
        }
    }

    &:after {
        position: absolute;
        content: "";
        z-index: 2;
        inset: 0;
        background: linear-gradient(92.46deg, #79ccf0 2.43%, #4db2a1 98.48%);
        mix-blend-mode: multiply;
    }

    &__image {
        position: absolute;
        bottom: -39px;
        left: 0;
        right: 0;
        height: ac(230px, 160px);
        opacity: 0.3;
        box-shadow: 0 -10px 10px 0 rgba(0, 207, 255, 0.1);
        mask-image: linear-gradient(
            0deg,
            transparent,
            #000 5%,
            #000 15%,
            transparent
        );

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            filter: grayscale(100%) brightness(110%);
        }

        &:before {
            content: "";
            position: absolute;
            inset: 0;
            background: linear-gradient(
                92.46deg,
                rgba(121, 204, 240, 0.7) 2.43%,
                rgba(77, 178, 161, 0.7) 98.48%
            );
            z-index: 1;
            mix-blend-mode: color;
        }
    }

    .big-cont {
        position: relative;
        z-index: 3;
    }

    &__content-wrap {
        display: flex;
        align-items: center;
        gap: ac(48px, 20px);

        width: 100%;
        max-width: 1172px;
        margin-left: auto;

        @mixin media 769 {
            flex-direction: column;
        }
    }

    &__text-wrap {
        width: 44.5%;

        color: var(--white);
        font-weight: 500;

        @mixin media 769 {
            align-self: start;
        }

        @mixin media 769 {
            width: 100%;
        }
    }

    &__form-wrap {
        width: 51%;
        min-height: ac(315px, 225px);

        @mixin media 769 {
            width: 100%;
            max-width: 480px;
        }
    }

    &__form {
        padding: ac(80px, 40px) ac(64px, 35px) ac(64px, 35px);

        border-radius: 20px;

        color: var(--white);
        background: var(--dark);

        .default-input {
            label {
                margin-bottom: 30px;
            }

            .input {
                padding-bottom: 14px;
            }
        }
    }

    &__form-btn {
        margin-top: 20px;
        margin-left: auto;

        /* .btn {
			&.prev {
				&:hover {
					.btn__icon {
						transform: scaleX(-1) translateX(6px);
					}
				}

				.btn__icon {
					transform: scaleX(-1);
					margin-left: 0;
					margin-right: ac(10px, 8px);
				}

				.btn__text {
					&::before {
						left: unset;
						right: 0;
					}
				}
			}
		} */
    }

    &__title {
        font-size: ac(56px, 30px);
        line-height: 1.28;

        margin-bottom: 24px;
    }

    &__descr {
        font-size: ac(20px, 16px);
        line-height: ac(32px, 24px);
    }
}

.stay-in-the-know-with-news-slider {
    position: relative;

    .stay-in-the-know {
        z-index: 11;

        padding-top: ac(109px, 70px);
        padding-bottom: ac(84px, 30px);

        @mixin min-media 2200 {
            padding-top: 180px;
        }

        &:before,
        &::after {
            display: none;
        }
    }

    &:before {
        position: absolute;
        content: "";
        z-index: 3;
        top: -1px;
        left: 0;
        width: 100%;
        height: 24.68vw;

        background-image: url("../images/decors/stay-in-the-know-wave.svg");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        /* transform: scale(1.02); */

        @mixin media 769 {
            height: 16vw;
            background-position: right;
            background-size: contain;
        }
    }

    &:after {
        position: absolute;
        content: "";
        z-index: 2;
        inset: 0;
        background: linear-gradient(92.46deg, #79ccf0 2.43%, #4db2a1 98.48%);
        mix-blend-mode: multiply;
    }

    &__image {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: ac(879px, 160px);
        opacity: 0.3;
        box-shadow: 0 -10px 10px 0 rgba(0, 207, 255, 0.1);
        mask-image: linear-gradient(0deg, #000 0%, transparent);

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            filter: grayscale(100%) brightness(110%);
        }

        &:before {
            content: "";
            position: absolute;
            inset: 0;
            background: linear-gradient(
                92.46deg,
                rgba(121, 204, 240, 0.7) 2.43%,
                rgba(77, 178, 161, 0.7) 98.48%
            );
            z-index: 1;
            mix-blend-mode: color;
        }
    }

    &.not-latest-news {
        clip-path: inset(1px 0 0 0);
        .stay-in-the-know-with-news-slider__image {
            display: none;
        }
    }
}
