.approach-section {
	padding-top: ac(40px, 24px);
	position: relative;
	z-index: 12;
	margin-bottom: ac(-198px, -80px);
	overflow: visible;

	&__wrap {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
		margin: 0 auto;
		max-width: ac(1065px, 900px);
	}

	&__title {
		text-align: center;
		&:not(:last-child) {
			margin-bottom: ac(24px, 18px);
		}
	}

	&__text {
		max-width: ac(631px, 450px);
		&:not(:last-child) {
			margin-bottom: ac(48px, 24px);
		}

		@mixin mob-lg {
			max-width: 100%;
		}
	}
}
