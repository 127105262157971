html {
    font-size: 16px;
    scrollbar-width: thin;
    scrollbar-color: var(--green) var(--grey);
}

[data-aos="progress-anim"] {
    opacity: 0;
    transition: transform 0.3s ease-in-out, opacity 0.5s ease;

    &.aos-animate {
        opacity: 1;
        transform: translateX(0);
    }
}

.fp-watermark {
    display: none;
}

body {
    position: relative;
    min-width: 320px;
    margin: auto;
    background: var(--white);
    color: var(--dark);

    overflow-x: hidden;

    &.disabled {
        overflow-y: scroll;
        position: fixed;

        width: 100%;
    }

    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-track {
        background: var(--grey);
        border-radius: 0;
    }

    &::-webkit-scrollbar-thumb {
        background: var(--green);
        border-radius: 0;
    }
}

.blur-up {
    filter: blur(3px);
    transition: filter 400ms, -webkit-filter 400ms;
}

.blur-up.lazyloaded {
    filter: blur(0);
}

.swiper-wrapper {
    left: 0 !important;
}

#site {
    position: relative;
    //overflow: hidden; min-height: 100%; display: flex;
    flex-direction: column;

    &:not(.is-visible) {
        overflow: hidden;
    }
}

.main {
    flex-grow: 1;

    &.snow-bg {
        background: var(--snow);
    }

    &.bg-dark {
        background: var(--dark);
    }
}

% default-transition {
    transition: ease-in-out 0.25s;
}

.cont {
    margin: 0 auto;
    max-width: 988px;
    width: perc(988);

    @mixin tab-md {
        width: 89%;
    }

    @mixin mob-lg {
        width: 91.73%;
    }
}

.big-cont {
    margin: 0 auto;
    max-width: 1280px;
    width: perc(1280);

    @mixin tab-md {
        width: 89%;
    }

    @mixin mob-lg {
        width: 91.73%;
    }
}

section {
    position: relative;
    overflow-x: hidden;
}

#bold-credits {
    width: 150px;
    transition: ease-in-out 0.25s;

    &::before,
    &::after {
        display: none;
    }

    &:hover {
        transform: scale(1.1);
    }
}

.sticky:before,
.sticky:after {
    content: "";
    display: table;
}

#site.cookie-terms-page {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;

    .main {
        flex-grow: 1;
        padding: 0;
    }

    .header {
        background: var(--dark);
    }

    .burger span,
    .menu-toggle span {
        &:after,
        &:before {
            background: var(--white);
        }
    }

    .burger-text {
        color: var(--white);
    }
}

.cookie-terms-section {
    padding: ac(250px, 140px) 0 ac(150px, 50px);
    p {
        padding: 0;
        margin-bottom: 10px;
    }
    ul,
    ol {
        padding-left: 20px;
        li {
            p {
                margin-bottom: 0;
            }
        }
    }

    ul {
        list-style: circle;
    }

    ol {
        list-style: decimal;
    }

    a {
        display: inline;
        text-decoration: underline;
        transition: 0.2s;

        &:hover {
            opacity: 0.7;
        }
    }
}
.single-page-404 {
    min-height: 100vh;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1,
    p {
        margin-bottom: 15px;
        padding: 0;
    }
    a {
        display: inline-block;
        margin-top: 20px;
    }
}

/*#container-test {
	&:container(width < 1200px) {
		background: orange;
	}
}*/

.holding-select {
    --dark-blue: #003453;
    --medium-blue: #1d445f;
    --light-blue: #47798f;

    --font-main: "Proxima Nova", sans-serif;
    --font-second: "Inter", sans-serif;

    h3,
    h4,
    h5,
    h6,
    p,
    a {
        font-family: var(--font-main);
        color: var(--dark-blue);
    }

    h1 {
        font-family: var(--font-second);
        color: var(--dark-blue);
        font-size: ac(64px, 32px);
        letter-spacing: 0.02em;
        line-height: 150%;
    }

    h2 {
        color: var(--medium-blue);
        font-size: ac(24px, 18px);
        line-height: 166.67%;
    }

    .text-light-blue {
        color: var(--light-blue);
    }

    p,
    a {
        font-size: ac(20px, 14px);
        line-height: 120%;
    }

    a {
        position: relative;

        &:before {
            position: absolute;
            right: 0;
            bottom: 0;
            height: 2px;
            background: var(--dark-blue);
            width: 0;
            transition: all 0.25s ease;
        }

        &:hover {
            color: var(--light-blue);

            &:before {
                right: auto;
                left: 0;
                background: var(--light-blue);
                width: 100%;
                transition: all 0.25s ease;
            }
        }
    }

    .container {
        margin: 0 auto;
        max-width: 1280px;
        width: perc(1280);

        @mixin tab-md {
            width: 89%;
        }
    }

    .select-decor-big-bg {
        @mixin aspect-ratio 1263, 1752;
        position: absolute;
        right: 0;
        top: 50%;
        z-index: -10;
        transform: translateY(-52%) translateX(34.5%);
        width: 87.71%;
        max-width: 1263px;
        min-width: 660px;

        @mixin tab-sm {
            opacity: 0.5;
            transform: translateY(-60%) translateX(49%);
        }
    }

    .blur-text-block {
        font-size: ac(23px, 16px);
        line-height: 139.13%;

        &:after {
            content: "";
            background: rgba(var(--white), 0.8);
            backdrop-filter: blur(16px);
            position: absolute;
            left: ac(-109px, -52px);
            top: ac(-72px, -34px);
            bottom: ac(-72px, -34px);
            right: -100vw;
            z-index: -1;
            border: ac(6px, 4px) solid #b3c4c4;
            border-radius: 10000px;
        }
    }
}

.socials-list {
    display: flex;
    align-items: center;
    gap: 24px;

    &__title {
        font-size: 16px;
        font-weight: 500;
        line-height: 1.75;
    }

    &__link-list {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    &__link {
        position: relative;
        z-index: 2;

        display: flex;
        justify-content: center;
        align-items: center;

        width: 52px;
        height: 32px;

        border-radius: 1000px;

        cursor: pointer;
        transition: all 0.25s ease;

        &:hover {
            /*transform: scale(1.1);*/
        }

        &::before {
            content: "";
            position: absolute;
            inset: 0;
            z-index: -2;

            border-radius: 1000px;

            background: linear-gradient(
                316.58deg,
                #5acdf3 12.17%,
                #073267 81.12%
            );

            transition: all 0.25s ease;
        }

        &::after {
            content: "";
            position: absolute;
            inset: 2px;
            z-index: -1;

            border-radius: 1000px;

            background: var(--white);

            transition: all 0.25s ease;
        }

        i {
            font-size: 16px;

            background: linear-gradient(
                316.58deg,
                #5acdf3 12.17%,
                #073267 81.12%
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            transition: opacity 0.25s ease;

            &.icon-phone {
                font-size: 14px;
            }

            &.icon-email {
                font-size: 20px;
            }
        }

        &:hover {
            i {
                opacity: 0.5;
            }
        }
    }
}

.grecaptcha-badge {
    z-index: 100;

    @mixin mob-xl {
        transform: scale(0.5);
        transform-origin: right;
    }
}
