.text-photo-section {
    padding-top: 64px;
    padding-bottom: 24px;
    overflow: visible;

    &__wrap {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;
        position: relative;
        z-index: 2;

        @mixin tab-md {
            flex-direction: column;
            justify-content: flex-start;
        }
    }

    &__decor {
        width: ac(1347px, 600px);
        @mixin aspect-ratio 1357, 1475;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-38%) translateX(49%) rotate(-5deg);
        z-index: -1;
        pointer-events: none;

        img {
            object-fit: contain;
        }

        @mixin tab-md {
            transform: translateY(-40%) translateX(14%) rotate(10deg);
            opacity: 0.5;
        }
    }

    &__content {
        width: 45.16%;

        @mixin tab-md {
            width: 100%;
            &:not(:last-child) {
                margin-bottom: ac(32px, 20px);
            }
        }
    }

    &__sub-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        ul {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;

            li {
                font-size: ac(20px, 18px);
                font-weight: 500;
                line-height: ac(32px, 24px);
                width: 100%;

                &:not(:last-child) {
                    padding-bottom: ac(12px, 10px);
                    margin-bottom: ac(12px, 10px);
                    border-bottom: 1px solid var(--dark-grey);
                }
            }
        }
    }

    &__title {
        &:not(:last-child) {
            margin-bottom: ac(24px, 18px);
        }
    }

    &__subtitle {
        font-size: ac(20px, 16px);
        font-weight: 500;
        line-height: ac(32px, 24px);
        font-family: var(--font-main);

        h4 {
            color: inherit;
            font-size: ac(20px, 16px);
            font-weight: 500;
            line-height: ac(32px, 24px);
        }

        &:not(:last-child) {
            margin-bottom: ac(32px, 18px);
        }
    }

    &__text {
        &:not(:last-child) {
            margin-bottom: ac(36px, 20px);
        }
    }

    &__image {
        width: 51.09%;
        margin-top: ac(-64px, -24px);
        border-radius: 20px;
        filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.15))
            drop-shadow(4px 4px 8px rgba(0, 0, 0, 0.07))
            drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.08));

        .image {
            width: 100%;
            @mixin aspect-ratio 654, 440;
            clip-path: inset(0 0 0 0 round 20px);

            &:after {
                content: "";
                position: absolute;
                inset: 0;
                z-index: 2;
                box-shadow: inset 0px -32px 40px 0px rgba(255, 255, 255, 0.5);
            }
        }

        .plyr {
            clip-path: inset(0 0 0 0 round 20px);
        }

        @mixin tab-md {
            margin-top: 0;
            width: 100%;
            &:not(:last-child) {
                margin-bottom: ac(32px, 20px);
            }
        }
    }

    &.company-information-type {
        margin-top: -242px;
        z-index: 20;

        @mixin tab-md {
            margin-top: -160px;
        }

        @mixin mob-xl {
            margin-top: -60px;
        }
    }

    &.our-values-type {
        padding-top: ac(42px, 24px);
        padding-bottom: ac(40px, 20px);

        .text-photo-section {
            &__wrap {
                margin: 0 auto;
                max-width: ac(1065px, 900px);
            }
            &__title {
                width: 100%;
            }
            &__content {
                width: 59.85%;

                @mixin tab-md {
                    width: 100%;
                }
            }
            &__subtitle {
                color: var(--dark-blue);
                max-width: 90%;

                @mixin tab {
                    max-width: 100%;
                }
            }
            &__image {
                margin-top: 6px;
                width: 36.34%;

                .image {
                    @mixin aspect-ratio 387, 516;
                }

                @mixin tab-md {
                    width: 100%;
                    max-width: 340px;
                    margin-right: auto;
                    margin-left: auto;
                }
            }
        }
    }

    &.clients-hiring-solutions-type {
        margin-top: ac(-162px, -155px);
        padding-top: ac(80px, 40px);
        position: relative;
        z-index: 20;
        overflow: visible;

        .text-photo-section__image {
            width: 100%;
            margin-bottom: ac(56px, 32px);
            .image {
                width: 100%;
                @mixin aspect-ratio 1280, 500;
                clip-path: inset(0 0 0 0 round 20px);

                img {
                    object-position: 50% 20%;
                }

                @mixin mob-lg {
                    @mixin aspect-ratio 1280, 700;
                }

                &:after {
                    box-shadow: inset 0px -32px 80px rgba(255, 255, 255, 0.5);
                }
            }
        }

        .text-photo-section__wrap {
            margin: 0 auto;
            max-width: ac(1064px, 900px);
            align-items: center;

            @mixin tab {
                max-width: 92%;
            }

            @mixin mob-lg {
                max-width: 100%;
            }
        }

        .text-photo-section__content {
            width: 55.17%;

            @mixin tab-md {
                width: 100%;
            }
        }

        .text-photo-section__sub-content {
            width: 38.91%;

            @mixin tab-md {
                width: 100%;
            }
        }

        &.work-for-page {
            .text-photo-section__image {
                .image {
                    @mixin aspect-ratio 1280, 640;
                    width: 100%;
                }
            }

            .text-photo-section__content {
                width: 100%;
            }

            .text-photo-section__text {
                ul {
                    width: 100%;
                    column-count: 2;
                    row-gap: ac(16px, 12px);
                    column-gap: ac(46px, 20px);

                    li {
                        position: relative;
                        padding-left: ac(56px, 38px);
                        font-size: ac(22px, 18px);
                        font-weight: 500;
                        line-height: 1.4;
                        color: var(--black);
                        min-height: ac(40px, 28px);
                        break-inside: avoid;

                        &:before {
                            top: 0;
                            left: 0;
                            --size: ac(40px, 28px);
                            transform: none;
                            width: var(--size);
                            height: var(--size);
                            position: absolute;
                            background-image: url("../../public/images/list-work-for.svg");
                            background-size: 100% 100%;
                            background-repeat: no-repeat;
                            content: "";
                            background-color: transparent;
                        }

                        &:not(:last-child) {
                            margin-bottom: ac(16px, 12px);
                        }
                    }

                    @media (max-width: 768px) {
                        column-count: 1;
                    }
                }
            }

            + .text-video-section.why-us-type {
                margin-top: 0;
            }
        }
    }
}
