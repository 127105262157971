.registration-section {
	padding-top: ac(239px, 60px);
	padding-bottom: ac(170px, 80px);
	position: relative;
	z-index: 4;
	overflow: hidden;

	.border-for-footer {
		position: absolute;
		height: 1px;
		background: color-mod(var(--white) a(15%));
		margin: 0 auto;
		max-width: 988px;
		width: perc(988);
		bottom: 0;
		left: 50%;
		z-index: 5;
		transform: translateX(-50%);

		@mixin tab-md {
			width: 89%;
		}

		@mixin mob-lg {
			width: 91.73%;
		}
	}

	&:before {
		clip-path: url("#registration-clip-path");
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		height: 68.3%;
		z-index: 2;
		content: "";
		background: linear-gradient(307.29deg, #79ccf0 19.92%, #4db2a1 105.96%);

		@mixin mob-xl {
			clip-path: url("#registration-clip-path-mob");
		}
	}

	&:after {
		bottom: -2px;
		right: 0;
		left: 0;
		height: 40%;
		position: absolute;
		content: "";
		background: var(--dark);
	}

	&__wrap {
		width: 100%;
		position: relative;
		z-index: 5;
		display: flex;
		align-items: center;
		justify-content: space-between;
		min-height: ac(720px, 640px);

		@mixin tab-md {
			flex-direction: column;
			align-items: stretch;
			justify-content: flex-start;
		}
	}

	&__bg-image {
		bottom: -2px;
		right: 0;
		left: 0;
		height: 76%;
		position: absolute;
		z-index: 1;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			filter: grayscale(100%);
			opacity: 1;
		}

		&:before {
			content: "";
			position: absolute;
			z-index: 1;
			inset: 0;
			background: linear-gradient(91.85deg, #7acdf1 0%, #4eb3a2 96.87%);
			opacity: 0.7;
		}
	}

	&.contact-us-page {
		padding-top: 0;
		padding-bottom: ac(78px, 64px);
		background: var(--dark);
		margin-bottom: ac(80px, 40px);
		overflow: visible;
		&:after {
			bottom: 1px;
			right: 0;
			left: 0;
			height: ac(82px, 42px);
			position: absolute;
			content: "";
			background: var(--dark);
			transform: translateY(100%);
		}

		&:before {
			content: none;
		}

		.border-for-footer {
			bottom: ac(-80px, -40px);
		}

		@mixin min-media 1460 {
			padding-top: 100px;
		}
	}

	&__image {
		position: absolute;
		z-index: -1;
		top: 0;
		left: 0;
		right: 0;
		height: calc(100% - ac(80px, 60px));
		box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15), 4px 4px 8px rgba(0, 0, 0, 0.07),
			0px 2px 4px rgba(0, 0, 0, 0.08);
		border-radius: ac(40px, 32px);
		background: var(--white);
		overflow: hidden;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			filter: grayscale(100%);
			border-radius: ac(40px, 32px);
		}

		&:before {
			position: absolute;
			inset: 0;
			content: "";
			background: var(--white);
			border-radius: ac(40px, 32px);
			z-index: 1;
			opacity: 0.8;
		}
	}

	&__content {
		width: 32.27%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		margin-left: ac(108px, 24px);
		margin-bottom: ac(72px, 24px);
		transform: translateY(ac(-4px, 0px));

		@mixin tab-md {
			width: auto;
			margin-right: ac(108px, 24px);
			margin-top: 32px;
		}

		@mixin mob {
			margin-left: 18px;
			margin-right: 18px;
		}
	}

	&__title {
		margin-bottom: ac(32px, 20px);
	}

	&__text {
		&.subtitle {
			p {
				font-size: ac(20px, 18px);
				line-height: ac(32px, 28px);
				font-weight: 500;
			}
		}
	}

	&__form {
		width: 55.16%;
		margin-top: ac(32px, 24px);
		margin-right: ac(32px, 24px);
		position: relative;
		z-index: 2;
		border-radius: ac(40px, 32px);

		padding-top: ac(78px, 40px);
		padding-left: ac(78px, 24px);
		padding-right: ac(78px, 24px);
		padding-bottom: ac(84px, 44px);

		@mixin tab-md {
			width: auto;
			margin-left: ac(32px, 24px);
		}

		@mixin mob {
			margin-left: 16px;
			margin-right: 16px;
		}

		@mixin mob-sm {
			margin-left: 8px;
			margin-right: 8px;
		}

		&:before {
			position: absolute;
			inset: 0;
			content: "";
			border-radius: ac(40px, 32px);
			background: linear-gradient(92.46deg, #79ccf0 2.43%, #4db2a1 98.48%);
			z-index: -2;
		}

		&:after {
			position: absolute;
			inset: 2px;
			content: "";
			border-radius: ac(38px, 30px);
			background: var(--dark);
			z-index: -1;
			box-shadow: inset 0px -8px 32px rgba(255, 255, 255, 0.08);
		}

		label {
			font-size: 16px;
			line-height: 28px;
			font-weight: 500;
		}
	}

	&__form-grid {
		display: grid;
		grid-template-columns: repeat(2, minmax(0, 1fr));
		grid-column-gap: ac(32px, 16px);
		grid-row-gap: ac(34px, 16px);

		@mixin mob-lg {
			grid-template-columns: repeat(1, minmax(0, 1fr));
		}
	}

	&__form-subgrid {
		grid-column: 1/-1;
		display: grid;
		grid-template-columns: repeat(3, minmax(0, 1fr));
		grid-column-gap: ac(12px, 10px);
		grid-row-gap: ac(16px, 14px);

		.default-input {
			select {
				margin-top: auto;
			}
			.choices {
				margin-top: auto;
			}
		}

		@mixin mob-lg {
			grid-template-columns: repeat(2, minmax(0, 1fr));

			.default-input:nth-child(3) {
				grid-column: 1/-1;
			}
		}
	}

	&__form-bottom {
		margin-top: ac(52px, 24px);
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
		gap: 18px;

		.checkbox .checkbox-text {
			font-weight: 400;
			font-size: 14px;

			@mixin mob-sm {
				font-size: 13px;
			}
		}

		.btn {
			transform: translateY(ac(-4px, 0px));
		}
	}
}
