.contact-slider {
	width: 100%;
	max-width: 100%;
	overflow: visible;
	clip-path: inset(-1000px 0 -1000px 0);
}

.contact-slider-nav {
	margin-top: 16px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: ac(20px, 12px);

	.contact-slider-submit {
		display: none;
		&.active {
			display: flex;
		}
	}

	.contact-slider-prev {
		&.swiper-button-disabled {
			pointer-events: none;
			opacity: 0.5;
		}
	}
}

.cv-slider {
	width: 100%;
	max-width: 100%;
	overflow: visible;
	clip-path: inset(-1000px 0 -1000px 0);

	&__item {
		display: flex;
		height: auto;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;

		& > * {
			width: 100%;
		}
	}
}

.cv-slider-nav {
	margin-top: 22px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: ac(20px, 12px);

	.cv-slider-submit {
		display: none;

		&.active {
			display: flex;
		}
	}

	.cv-slider-prev {
		&.swiper-button-disabled {
			pointer-events: none;
			opacity: 0.7;
		}
	}
}
