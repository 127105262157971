.our-values-section {
    padding-top: ac(40px, 32px);
    padding-bottom: ac(80px, 40px);

    &__wrap {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        max-width: 940px;
        margin: 0 auto;

        .our-values-slider {
            width: 100%;
        }
    }

    &__title {
        margin-bottom: ac(48px, 24px);
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }

    &__text {
        &:not(:last-child) {
            margin-bottom: ac(32px, 24px);
        }
    }

    &.with-text-left-type {
        .our-values-section__wrap {
            flex-direction: row;
            max-width: 100%;
            width: 100%;
            justify-content: space-between;

            .our-values-slider {
                margin-left: 0;
                margin-right: 0;
                width: 49.32%;

                @mixin media 1380 {
                    width: 52%;
                }

                @mixin tab-md {
                    width: 100%;
                }
            }

            @mixin tab-md {
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
            }
        }

        .our-values-section__content {
            width: 47.03%;

            @mixin media 1380 {
                width: 45%;
            }

            @mixin tab-md {
                width: 100%;
                margin-bottom: 32px;
            }
        }

        .our-values-section__title {
            margin-bottom: ac(24px, 20px);
        }
    }

    + .our-locations-section {
        margin-top: ac(-480px, -140px);
    }
}
