.contact-section {
	background: var(--dark);
	padding-top: ac(150px, 68px);
	overflow: visible;

	&__wrap {
		padding-bottom: ac(82px, 44px);
		border-bottom: 1px solid color-mod(var(--white) a(15%));
		display: flex;
		justify-content: space-between;
		align-items: flex-start;

		@mixin mob-xl {
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;

			.title {
				margin-bottom: 34px;
			}
		}
	}

	.title {
		max-width: ac(412px, 300px);
	}

	&__form {
		width: 45.55%;

		@mixin mob-xl {
			width: 100%;
		}

		.default-input .choices__inner {
			min-height: 42px;
		}
	}

	&__subtitle {
		padding: ac(20px, 16px) ac(44px, 28px) ac(28px, 16px);
		border-radius: 30px;
		border: 1px solid var(--dark-grey);
		position: relative;
		margin-bottom: 33px;

		&:before {
			content: "";
			position: absolute;
			width: 33px;
			height: 22px;
			background-image: url("../images/decors/triangle.svg");
			background-size: 100% 100%;
			bottom: 0;
			left: ac(44px, 32px);
			transform: translateY(86%);

			@mixin min-media 1400 {
				transform: translateY(98%) scale(1.3);
				transform-origin: left;
			}
		}
	}
}

.cv-drop-section {
	padding-top: ac(98px, 80px);
	padding-bottom: ac(81px, 40px);
	position: relative;
	z-index: 4;
	overflow: visible;

	&__wrap {
		display: flex;
		align-items: center;
		justify-content: space-between;

		@mixin mob-xl {
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
		}
	}

	&__content {
		width: 49.22%;
		padding-left: ac(108px, 0px);
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;

		@mixin mob-xl {
			width: 100%;
			margin-bottom: 32px;
		}
	}

	&__title {
		font-size: ac(90px, 42px);
		font-weight: 500;
		line-height: ac(112px, 56px);
		&:not(:last-child) {
			margin-bottom: ac(24px, 18px);
		}
	}

	&__text {
		&.subtitle p {
			font-weight: 500;
			line-height: ac(36px, 28px);
		}
	}

	&__form {
		padding-top: ac(80px, 32px);
		padding-left: ac(64px, 24px);
		padding-right: ac(64px, 24px);
		padding-bottom: ac(66px, 32px);
		width: 47.03%;
		background: var(--white);
		box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15), 4px 4px 8px rgba(0, 0, 0, 0.07),
			0px 2px 4px rgba(0, 0, 0, 0.08),
			inset 0px -32px 80px rgba(255, 255, 255, 0.5);
		border-radius: 20px;

		.default-upload-file {
			margin-top: auto;
		}

		.default-input label {
			margin-bottom: ac(34px, 16px);
			font-weight: 500;
		}

		@mixin mob-xl {
			width: 100%;
		}
	}
}

.title-submit {
	min-height: ac(144px, 88px);
	display: flex;
	align-items: center;
	justify-content: flex-start;
}
