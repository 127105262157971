.jobs-top-search-section {
    margin-top: ac(-160px, -90px);
    padding-top: ac(80px, 40px);
    padding-bottom: ac(50px, 20px);
    z-index: 30;
    overflow: visible;

    @mixin mob-xl {
        margin-top: -20px;
    }

    &__wrap {
        width: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }

    &__title {
        margin-bottom: ac(64px, 34px);
    }

    &__form {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }

    &__form-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        grid-column-gap: ac(18px, 16px);
        grid-row-gap: ac(32px, 20px);
        flex-wrap: wrap;

        &:not(:last-child) {
            margin-bottom: ac(34px, 28px);
        }

        .default-input,
        .default-custom-select {
            width: calc(27.18% - (ac(18px, 16px) / 2));

            &.with-bigger {
                width: calc(41.33% - (ac(18px, 16px) / 2));
            }

            @mixin media 800 {
                width: calc(40% - (ac(18px, 16px) / 2));

                &.with-bigger {
                    width: calc(48% - (ac(18px, 16px) / 2));
                }
            }

            @mixin mob-lg {
                width: calc(45% - (ac(18px, 16px) / 2));

                &.with-bigger {
                    width: calc(48% - (ac(18px, 16px) / 2));
                }
            }

            @mixin mob {
                width: 100%;

                &.with-bigger {
                    width: 100%;
                }
            }
        }

        .single-range-container {
            width: calc(38.87% - (ac(18px, 16px) / 2));

            @mixin tab-sm {
                width: calc(48% - (ac(18px, 16px) / 2));
            }

            @mixin mob-lg {
                width: 80%;
            }

            @mixin mob {
                width: 100%;
            }
        }
    }
}

.jobs-search-section {
    padding-top: ac(56px, 20px);
    padding-bottom: ac(80px, 42px);
    overflow: visible;
    position: relative;
    z-index: 10;
    clip-path: inset(0 0 0 0);

    .big-cont {
        @mixin tab-sm {
            position: relative;
        }
    }

    &__wrap {
        display: flex;
        justify-content: space-between;

        @mixin tab-sm {
            position: relative;
            width: 100%;
        }
    }

    .sidebar {
        margin-left: ac(40px, 0px);
        width: 23.13%;
        max-width: 306px;
        flex-shrink: 0;

        &__buffer {
            display: block;
        }

        &__inner {
            position: sticky;
            padding-bottom: 60px;
            padding-top: ac(62px, 0px);

            @mixin tab-sm {
                padding-top: 0;
            }
        }

        &__title {
            font-size: ac(34px, 24px);
            font-weight: 500;
            line-height: ac(48px, 30px);
            padding-bottom: ac(28px, 20px);
            border-bottom: 1px solid var(--dark-grey);

            &:not(:last-child) {
                margin-bottom: ac(48px, 24px);
            }
        }

        &__item {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            padding-bottom: ac(32px, 24px);
            width: 100%;
            max-width: 251px;

            &:not(:first-child) {
                .sidebar {
                    &__item-top {
                        margin-top: ac(30px, 20px);
                    }
                }
            }

            &:not(:last-child) {
                border-bottom: 1px solid var(--dark-grey);
                margin-bottom: ac(32px, 20px);
            }

            .double-range-container {
                max-width: 251px;
            }

            h3.sidebar__item-title {
                font-size: ac(22px, 20px);
                font-weight: 400;
                line-height: ac(36px, 30px);

                &:not(:last-child) {
                    margin-bottom: ac(26px, 16px);
                }
            }

            .btn-show-hide {
                margin-top: ac(32px, 24px);
                display: flex;
                align-items: center;
                justify-content: flex-start;
                cursor: pointer;
                padding-right: 24px;
                &__show {
                    display: flex;
                }

                &__hide {
                    display: none;
                }

                &:not(.is-visible) {
                    opacity: 0;
                    display: none;
                }

                &.active {
                    .btn-show-hide__show {
                        display: none;
                    }

                    .btn-show-hide__hide {
                        display: flex;
                    }
                }
            }
        }

        &__item-top {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            position: relative;
        }

        &__item-content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            width: 100%;

            ul {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                width: 100%;

                li {
                    &:not(:first-child) {
                        margin-top: ac(12px, 8px);
                    }

                    &.is-hidden {
                        display: none;
                    }
                }
            }
        }

        &__item-title {
            &.with-select {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                position: relative;
                z-index: 10;
                flex-wrap: wrap;
                grid-column-gap: 5px;

                .choices__inner {
                    padding-left: 0;
                }

                .sorting-select .choices .choices__list--single {
                    padding-left: 0;
                }

                .sorting-select .choices .choices__item {
                    font-size: ac(22px, 20px);
                    font-weight: 400;
                    line-height: ac(36px, 30px);
                    font-family: var(--font-main);
                }
            }
        }

        @mixin tab-sm {
            position: absolute;
            width: 300px;
            top: 0;
            left: 0;
            z-index: 1;
            max-width: 300px;
            transition: transform 0.3s ease;
            transform: translateX(-120%);

            &.active {
                transform: translateX(0);
            }

            .sidebar {
                &__buffer {
                    display: none;
                }

                &__inner {
                    position: static;
                }
            }

            @mixin mob {
                width: 270px;
                max-width: 270px;
            }

            @mixin mob-sm {
                width: 100%;
                max-width: 100%;
            }
        }
    }

    .jobs-list {
        width: 66.17%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        position: relative;
        z-index: 1;

        @mixin media 1100 {
            width: 72%;
        }

        &__sorting {
            display: flex;
            flex-shrink: 0;

            font-size: 16px;
            align-items: center;

            @mixin media 651 {
                margin-left: auto;
            }
        }

        &__sorting-sortby-text {
            font-weight: 300;
        }

        &__result {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            grid-column-gap: ac(20px, 16px);
            grid-row-gap: ac(16px, 14px);

            @mixin mob-lg {
                grid-template-columns: repeat(1, minmax(0, 1fr));
            }

            .job-card__title {
                h3 {
                    line-height: ac(46px, 36px);
                }

                &:not(:last-child) {
                    margin-bottom: ac(20px, 16px);
                }
            }

            .job-card__parameters {
                gap: ac(12px, 10px);
                li {
                    margin-right: 0 !important;
                }
            }
        }

        &__top {
            padding-bottom: ac(50px, 34px);
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            @mixin tab-sm {
                align-items: flex-start;
            }

            @mixin mob-lg {
                flex-direction: column;

                .jobs-list__title {
                    margin-bottom: 16px;
                }

                .default-select {
                    width: 100%;
                }
            }
        }

        .pagination {
            margin-top: ac(64px, 32px);

            .pagination__item::after {
                background-color: var(--white);
            }
        }

        @mixin tab-sm {
            width: 100%;
            transition: transform 0.3s ease;
            transform: translateX(0);

            &:before {
                position: absolute;
                content: "";
                left: -20px;
                bottom: 0;
                right: 0;
                height: 70%;
                background: linear-gradient(
                    135deg,
                    color-mod(var(--white) a(0%)) 0%,
                    var(--white) 35%,
                    var(--white) 100%
                );
                opacity: 0;
                pointer-events: none;
                z-index: 1;
                transition: opacity 0.3s ease;
            }

            .job-card {
                position: relative;

                &:before {
                    position: absolute;
                    content: "";
                    inset: 0;
                    background: linear-gradient(
                        90deg,
                        color-mod(var(--white) a(10%)) 0%,
                        var(--white) 100%
                    );
                    opacity: 0;
                    pointer-events: none;
                    z-index: 1;
                    transition: opacity 0.3s ease;

                    @mixin mob-lg {
                        background: linear-gradient(
                            90deg,
                            color-mod(var(--white) a(10%)) 0%,
                            var(--white) 50%,
                            var(--white) 100%
                        );
                    }
                }
            }

            &.active {
                transform: translateX(330px);

                &:before {
                    opacity: 1;
                }

                .job-card {
                    pointer-events: none;
                    cursor: initial;
                    &:before {
                        pointer-events: auto;
                        opacity: 1;
                    }
                }

                @mixin mob {
                    transform: translateX(290px);
                }

                @mixin mob-sm {
                    transform: translateX(330px);
                }
            }
        }
    }

    &__wrap {
        @mixin tab-sm {
            &.active {
                /*clip-path: inset(0 -50px 50px -50px);*/
                .jobs-list {
                    position: absolute;
                    top: 0;
                    left: 0;
                }

                .sidebar {
                    position: static;
                }
            }
        }
    }

    .btn-icon-filter {
        position: sticky;
        bottom: 32px;
        right: 24px;
        margin-top: -56px;
        margin-left: auto;
        filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.2));
        width: 56px;
        height: 56px;
        max-width: 56px;
        z-index: 50;
        display: none;
        cursor: pointer;
        svg {
            width: 100%;
            height: 100%;
        }

        @mixin tab-sm {
            display: flex;
        }

        @mixin mob-lg {
            width: 100%;
            transform: none;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .btn-text-filter {
        display: none;

        @mixin tab-sm {
            padding: 10px 26px;
            border: 2px solid var(--green);
            border-radius: 18px;
            display: flex;
            position: sticky;
            bottom: 32px;
            right: 24px;
            margin-top: 32px;
            margin-left: auto;
            margin-right: auto;
            z-index: 50;
            background: var(--snow);
            max-width: 170px;
            justify-content: center;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.14);
            transition: all 0.3s ease;
            user-select: none;

            .btn__text {
                &:before {
                    content: none;
                }
            }

            &:hover {
                opacity: 1;
            }

            &.active {
                background: var(--dark);
                border-color: var(--dark);

                .btn__text {
                    color: var(--white);
                }

                .btn__icon {
                    color: var(--white);
                }
            }
        }
    }

    + .registration-section {
        margin-top: ac(-120px, 0px);
    }
}
