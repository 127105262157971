.jobs-slider {
	width: 100%;
	max-width: ac(820px, 640px);
	margin-left: 0;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	overflow: visible;
	opacity: 0;
	transition: opacity 0.1s ease;
	user-select: none;

	&.swiper-initialized {
		opacity: 1;
	}

	.slider-btn {
		margin-top: ac(30px, 24px);
	}

	.slider-buttons {
		transform: translateX(ac(84px, 0px));
	}
}
