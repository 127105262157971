.testimonial-card {
	background: var(--white);
	border-radius: 20px;
	padding: ac(55px, 32px) ac(50px, 24px) ac(50px, 32px);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	cursor: default;
	transition: border-radius 0.25s ease, box-shadow 0.25s ease;
	height: auto;

	&.dark-bg {
		background: var(--dark);

		.testimonial-card__info li {
			font-size: 16px;
		}
	}

	&__content {
		width: 100%;
		text-align: center;
		max-height: calc(ac(36px, 24px) * 7.01);

		&:not(:last-child) {
			margin-bottom: ac(30px, 16px);
		}

		.simplebar-track.simplebar-vertical {
			width: 2px;
			background: transparent;
			border-radius: 2px;
			transform: translateX(6px);

			.simplebar-scrollbar {
				background: var(--blue);
				border-radius: 2px;

				&:before {
					content: none;
				}
			}
		}
	}

	&__info {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;

		li {
			font-weight: 300;
			font-size: ac(18px, 16px);
			line-height: ac(32px, 20px);
			text-align: center;

			&:not(:last-child) {
				margin-bottom: ac(0px, 8px);
			}
		}
	}

	&:hover {
		border-radius: 30px 0 30px 0;
		box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.14),
			0px 6px 10px rgba(0, 0, 0, 0.08);
	}
}
