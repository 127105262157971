.swoosh-video {
	clip-path: url("#swoosh-video-clip-path");

	.plyr__poster {
		background-size: cover;
	}

	> .video-player {
		opacity: 0;
	}
}

.svg-for-clip-path {
	position: absolute;
	width: 0;
	height: 0;
}

.plyr__control--overlaid {
	background: var(--white);
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.24);
	border: 2px solid transparent;

	&:before {
		width: ac(45px, 24px);
		height: ac(45px, 24px);
		position: absolute;
		content: "";
		top: 50%;
		left: 50%;
		transform: translateY(-50%) translateX(-48%);
		background-image: url("../images/icons/play.svg");
		background-size: 100% 100%;
		background-repeat: no-repeat;
		z-index: 2;
		transition: opacity 0.25s ease;
	}

	svg {
		fill: transparent;
	}

	&:hover {
		background: var(--white) !important;
		border-color: var(--blue);

		&:before {
			opacity: 0.8;
		}
	}

	--plyr-control-icon-size: ac(40px, 24px);
}

.plyr-hide-controls {
	.plyr__controls {
		opacity: 0;
	}
}

.plyr__poster {
	background-size: cover;
}
