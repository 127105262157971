.history-time-slider {
	width: 100%;
	max-width: ac(200px, 137px);
	margin-left: 0;
	overflow: visible;

	&__item {
		position: relative;
		z-index: 1;
		cursor: pointer;
		width: auto;
		height: ac(112px, 68px);
		display: flex;
		flex-direction: column-reverse;
		align-items: center;
		justify-content: flex-start;
		&:not(:last-child) {
			margin-right: ac(186px, 88px);
		}

		&.swiper-slide-active {
			.history-time-slider__time {
				color: var(--dark);
				font-size: ac(104px, 60px);
				transition: color 0.3s ease, transform 0.2s ease 0s;
			}

			.history-time-slider__icon {
				transform: translateX(-100%) translateY(-50%) scale(1);
				opacity: 1;
			}
		}

		&.swiper-slide-prev {
			.history-time-slider__time {
				transform: translateX(ac(-130px, -20px));
			}

			.history-time-slider__icon {
				transform: translateX(-100%) translateY(-50%) scale(0);
			}
		}
	}

	&__time {
		font-family: var(--font-main);
		font-weight: 300;
		font-size: ac(90px, 48px);
		line-height: ac(112px, 68px);
		transition: color 0.3s ease, transform 0.2s ease 0.1s;
		color: var(--dark-grey);
	}

	&__icon {
		@mixin aspect-ratio 1, 1;
		position: absolute;
		top: 50%;
		left: ac(-24px, -8px);
		transform: translateX(0) translateY(-50%) scale(0);
		opacity: 0;
		transform-origin: right;
		width: ac(100px, 64px);
		z-index: -1;
		transition: transform 0.3s ease, opacity 0.35s ease;
	}

	@mixin mob-xl {
		margin-left: 94px;
	}
}

.history-content-slider {
	width: 100%;
	max-width: 100%;
	overflow: visible;

	&__item {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		cursor: default;
	}

	&__title {
		&:not(:last-child) {
			margin-bottom: ac(16px, 14px);
		}
	}
}

.history-slider-buttons {
	margin-top: ac(32px, 28px);
	margin-left: auto;
	margin-right: auto;
}
