.client-logo-card {
	box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
	border-radius: 20px;
	transition: opacity 0.25s ease;

	&__icon {
		width: 100%;
		@mixin aspect-ratio 240, 150;
		clip-path: inset(0 0 0 0 round 20px);

		img {
			filter: grayscale(100%);
			transition: all 0.3s ease;
		}
	}

	&:hover {
		@mixin min-media 652 {
			.client-logo-card__icon img {
				filter: grayscale(0);
			}
		}
	}

	@mixin tab {
		&.swiper-slide-active {
			.client-logo-card__icon img {
				filter: grayscale(0);
			}
		}
	}
}
