.sector-card {
    transition: all 0.3s ease;
    &__image {
        @mixin aspect-ratio 180, 140;
        width: 100%;
        clip-path: inset(0 0 0 0 round 10px);

        /*&:after {
            content: "";
            background: linear-gradient(
                135deg,
                rgba(9, 53, 106, 1) 0%,
                rgba(90, 205, 243, 1) 100%
            );
            position: absolute;
            inset: 0;
            z-index: 10;
            mix-blend-mode: color;
        }
*/
        &:not(:last-child) {
            margin-bottom: ac(23px, 24px);
        }

        img {
            transition: transform 0.25s ease;
            /*filter: grayscale(100%);*/
        }
    }

    &__content {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        min-height: calc(ac(30px, 18px) * 2);
        padding-left: 5px;
        padding-right: 5px;
        padding-bottom: 8px;

        @mixin tab-sm {
            align-items: center;
        }
    }

    &__icon {
        /*margin-top: ac(4px, 2px);*/
        width: ac(22px, 24px);

        &:not(:last-child) {
            margin-right: ac(10px, 12px);
        }

        @mixin mob-lg {
            margin-top: 0;
        }
    }

    &__name {
        max-width: 130px;
        font-weight: 500;
        font-size: ac(20px, 16px);
        line-height: ac(30px, 18px);
        @mixin max-line-length 3;
        text-align: center;
    }

    /*&:hover {
		img {
			&:not(.sector-card__icon) {
				transform: scale(1.05);
			}
		}
	}*/

    &:hover {
        background: var(--white);
        border-radius: 10px 10px 8px 8px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
    }
}

.old-safari {
    .sector-card {
        &:hover {
            img {
                &:not(.sector-card__icon) {
                    transform: none;
                }
            }
        }
    }
}

.our-sector-card {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    min-height: ac(406px, 360px);
    overflow: hidden;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
    border-radius: ac(20px, 16px);
    background: var(--white);
    transition: box-shadow 0.3s ease;

    &__content {
        width: 66.61%;
        padding: ac(40px, 18px);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }

    &__title {
        margin-bottom: ac(18px, 14px);
        line-height: ac(44px, 36px);
    }

    &__text {
        width: 100%;
        max-height: calc(ac(28px, 20px) * 12);

        &.small-content-element {
            p,
            ul,
            li {
                &:not(:last-child) {
                    margin-bottom: ac(10px, 8px);
                }
            }
        }

        .simplebar-track.simplebar-vertical {
            width: 2px;
            background: transparent;
            border-radius: 2px;
            transform: translateX(4px);

            .simplebar-scrollbar {
                background: var(--blue);
                border-radius: 2px;

                &:before {
                    content: none;
                }
            }
        }

        &:not(:last-child) {
            margin-bottom: ac(28px, 20px);
        }
    }

    .btn {
        margin-top: auto;
        margin-bottom: ac(3px, 0px);

        &.small-btn .btn__text {
            font-size: ac(18px, 14px);
        }
    }

    &__image {
        width: 33.39%;
        position: relative;
        box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.1);
        overflow: hidden;
        /*background: linear-gradient(316.58deg, #5acdf3 12.17%, #073267 81.12%);*/

        img {
            position: relative;
            z-index: 1;
            width: 100%;
            height: 100%;
            object-fit: cover;
            /*filter: grayscale(100%);*/
            transition: transform 0.3s ease;
        }

        &:before {
            position: absolute;
            inset: 0;
            /*content: "";*/
            background: linear-gradient(
                316.58deg,
                #5acdf3 12.17%,
                #073267 81.12%
            );
            mix-blend-mode: color;
            z-index: 2;
        }
    }

    &:hover {
        box-shadow: 0px 0px 8px color-mod(var(--dark-blue) a(30%));
        /*img {
			transform: scale(1.05) rotate(-1deg);
		}*/
    }

    &.with-left-image {
        flex-direction: row-reverse;

        &:hover {
            /*img {
				transform: scale(1.05) rotate(1deg);
			}*/
        }
    }
}
