.approach-card {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	height: 328px;
	position: relative;
	z-index: 2;
	cursor: default;

	&__title {
		width: 100%;
		background: var(--blue);
		height: 96px;
		padding-top: 10px;
		padding-bottom: 10px;
		padding-left: 20px;
		padding-right: 20px;
		display: flex;
		justify-content: center;
		align-items: center;

		h3 {
			color: var(--white);
			@mixin max-line-length 2;
			text-align: center;
			font-size: ac(32px, 20px);
			font-weight: 400;
			line-height: ac(36px, 24px);
		}
	}

	&__content {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
		position: absolute;
		top: 96px;
		left: 0;
		right: 0;
		border: 2px solid var(--blue);
		border-radius: 0 0 20px 20px;
		border-top: none;
		padding-top: 23px;
		padding-left: ac(38px, 20px);
		padding-right: ac(38px, 20px);
		padding-bottom: ac(40px, 24px);
		background: var(--white);

		.text-content {
			width: 100%;
			max-height: calc(ac(28px, 20px) * 7.01);
			min-height: calc(ac(28px, 20px) * 7.01);

			.simplebar-track.simplebar-vertical {
				width: 2px;
				background: transparent;
				border-radius: 2px;
				transform: translateX(4px);

				.simplebar-scrollbar {
					background: var(--blue);
					border-radius: 2px;

					&:before {
						content: none;
					}
				}
			}

			&.small-content-element {
				p {
					font-weight: 500;
				}

				li {
					font-weight: 500;
				}
			}
		}
	}

	&__icon {
		@mixin aspect-ratio 144, 88;
		width: 144px;
		transition: max-height 0.25s ease, margin-top 0.3s ease;
		max-height: 0;

		img {
			object-fit: contain;
		}
	}

	&:hover {
		@mixin min-media 769 {
			.approach-card__icon {
				margin-top: ac(24px, 14px);
				max-height: 88px;
			}
		}
	}

	&.swiper-slider-active {
		@mixin tab-sm {
			.approach-card__icon {
				margin-top: ac(24px, 14px);
				max-height: 88px;
			}
		}
	}
}
