@import "_sectors.pcss";
@import "_jobs.pcss";
@import "_testimonials.pcss";
@import "_contact.pcss";
@import "_benefits.pcss";
@import "_approach.pcss";
@import "_our-team.pcss";
@import "_clients.pcss";
@import "_gallery.pcss";
@import "_history.pcss";
@import "_our-values.pcss";
@import "_latest-news.pcss";

.slider-cont {
	padding: 10px 0;
	max-width: 100%;
	margin: 0 auto;
	overflow: hidden;
}
