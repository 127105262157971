.testimonials-section {
	padding-top: ac(294px, 242px);
	position: relative;
	z-index: 7;
	overflow: visible;
	margin-top: -200px;
	clip-path: inset(-200px 0 -1000px 0);

	.decor {
		width: 188.64%;
		@mixin aspect-ratio 1982, 1573;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateX(-40%) translateY(-60%);
		z-index: 2;
		pointer-events: none;
		max-width: 3000px;

		img {
			&.with-mob {
				display: none;
			}
		}

		@mixin tab-sm {
			width: 776px;
			@mixin aspect-ratio 776, 843;
			position: absolute;
			top: -236px;
			transform: translateX(-40%) translateY(0);

			img {
				&.with-desk {
					display: none;
				}

				&.with-mob {
					display: block;
				}
			}
		}
	}

	&__bg {
		position: absolute;
		inset: 0;

		.image {
			width: 100%;
			height: 100%;
			position: relative;

			&:after {
				content: "";
				background: var(--gradient-blue-green);
				position: absolute;
				inset: 0;
				z-index: 1;
				opacity: 0.7;
				pointer-events: none;
			}

			&:before {
				content: "";
				background: var(--dark);
				position: absolute;
				inset: 0;
				z-index: 1;
				opacity: 0.2;
				pointer-events: none;
			}

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				filter: grayscale(100%);
			}
		}
	}

	&__wrap {
		position: relative;
		z-index: 2;
		display: flex;
		justify-content: center;
		align-items: center;

		.small-title {
			text-align: center;
			margin-bottom: ac(20px, 14px);
		}
	}

	&__top {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-bottom: ac(20px, 22px);
		position: relative;
		z-index: 1;
		max-width: ac(1034px, 880px);

		.title {
			max-width: ac(380px, 300px);
		}

		@mixin tab-md {
			max-width: 100%;
		}

		@mixin mob-xl {
			display: flex;
			flex-direction: column;
			align-items: flex-start;

			.buttons {
				margin-top: 22px;
				margin-left: auto;
				flex-wrap: wrap;

				.btn:not(:last-child) {
					margin-bottom: 8px;
				}
			}
		}
	}

	&__slider {
		position: relative;
		z-index: 2;
		margin-bottom: ac(-32px, -20px);
	}

	&.join-the-team-page {
		padding-top: ac(340px, 242px);

		.testimonials-section__wrap .small-title {
			margin-bottom: ac(42px, 28px);
		}

		.testimonials-slider-buttons {
			transform: translateY(ac(29px, 22px));

			.slider-btn {
				margin-top: ac(52px, 40px);
			}
		}
	}

	&.our-sectors-page {
		padding-top: ac(160px, 100px);
		position: relative;
		margin-top: ac(-30px, 0px);

		&:before {
			content: "";
			position: absolute;
			z-index: 2;
			top: 0;
			left: 0;
			right: 0;
			height: ac(494px, 300px);
			background: var(--gradient-internal-main);
			pointer-events: none;
		}

		&:after {
			content: "";
			position: absolute;
			z-index: 3;
			top: 0;
			left: 0;
			right: 0;
			height: 16.25vw;
			transform: scale(1.02);
			background-image: url("../images/decors/wave-white-testiomonial-sectors.svg");
			background-size: 100% 100%;
			background-repeat: no-repeat;
			width: 100%;
			pointer-events: none;
		}

		.cont {
			position: relative;
			z-index: 4;
		}

		.slider-cont {
			position: relative;
			z-index: 4;
		}

		.testimonials-section__bg {
			top: ac(80px, 0px);
			.image img {
				object-position: top;
			}
		}

		.testimonials-section__top {
			margin-top: ac(0px, 40px);
			margin-bottom: ac(22px, 10px);

			@mixin min-media 1500 {
				margin-top: 5vw;
			}

			@mixin mob-xl {
				margin-top: 0;
			}
		}

		.testimonials-section__wrap .small-title {
			margin-bottom: ac(42px, 28px);
		}

		.testimonials-slider-buttons {
			transform: translateY(ac(29px, 22px));

			.slider-btn {
				margin-top: ac(52px, 40px);
			}
		}

		.testimonials-slider {
			transform: none;
		}

		.testimonial-card {
			padding: ac(55px, 32px) ac(56px, 24px) ac(50px, 32px);
		}

		.testimonial-card__info li {
			font-size: 16px;
		}
	}

	&.clients-page {
		padding-top: ac(64px, 50px);
		padding-bottom: ac(48px, 32px);
		position: relative;
		margin-top: ac(88px, 20px);
		z-index: 25;

		.testimonials-section__top {
			margin-bottom: ac(20px, 10px);
		}

		.testimonials-slider-buttons {
			.slider-btn {
				margin-top: ac(58px, 40px);
			}
		}

		+ .latest-news-section {
			margin-top: ac(-104px, -20px);
		}
	}
}
